import React, { useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";

import {
    getNotificationHistory,
    setNotificationHistory,
} from "../../actions/dashboardAction";

export default function NotificationHistory(props) {
    const [transData, setTransData] = useState([]);
    const [loader, setLoader] = useState(true);

    // function
    const fetchNotificationHistory = async () => {
        try {
            var arr = [];
            const { status, loading, result } = await getNotificationHistory();
            setLoader(loading);
            if (status == "success") {
                result.map((item, key) => {
                    if (item.type == "Trade") arr.push(item);
                });
                setTransData(arr);
            }
        } catch (err) {}
    };

    const changereadcount = async () => {
        try {
            const { status, loading, result } = await setNotificationHistory();
            setLoader(loading);
            if (status == "success") {
                fetchNotificationHistory();
            }
        } catch (err) {}
    };

    useEffect(() => {
        changereadcount();
    }, []);

    const configdata = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: "No user found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: true,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };

    const cryptoHistoryColumns = [
        {
            text: "Date",
            key: "created_date",
            className: "Date",
            sortable: false,
            width: "200px",
            cell: (record) => {
                return (
                    <div data-tag="allowRowEvents">
                        {moment(record.created_date).format("DD-MM-YYYY hh:mm")}
                    </div>
                );
            },
        },
        {
            text: "Category",
            className: "Category",
            key: "category",
            sortable: false,
            width: "150px",
            cell: (record) => {
                return <div data-tag="allowRowEvents">{record.category}</div>;
            },
        },

        {
            text: "Description",
            className: "Description",
            key: "description",
            sortable: false,
            width: "150px",
        },
    ];

    return (
        <>
            <div className=" stakingHistoryTable boxSpace box_bg">
                <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
                    <h3 class="dash_title mb-3 notigy_color">
                        All Notifications
                    </h3>
                </div>
                {transData.length > 0 && (
                    <ReactDatatable
                        className="historyCryptoTable tableBorder"
                        records={transData}
                        columns={cryptoHistoryColumns}
                        config={configdata}
                    />
                )}
            </div>
        </>
    );
}
