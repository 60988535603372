import React, { useEffect } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Checkbox from 'rc-checkbox';
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Fee(props) {
  const { ...rest } = props;

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages">
            <div className="container">
              <GridContainer>
                <GridItem md={12} className="text-center">
                  <h2 className="main_title">Fees & Conditions</h2>
                </GridItem>
              </GridContainer>


<div class="FeeContent">
   <p>In pricing,Tradax follows clear principles - prices should be competitive and transparent, and never burdened with false incentives. In most assets, we offer volume-based fees that allow you to benefit from more attractive prices as your trading activity increases. Please contact support@BharatX.io for individual trading conditions.</p>
   <div class="table-responsive">
      <table class="table table-striped feeTable">
         <tbody>
            <tr>
               <td>Deposit Currency</td>
               <td>BTC</td>
            </tr>
            <tr>
               <td>Minimum Deposit</td>
               <td>0.001</td>
            </tr>
            <tr>
               <td>Commission</td>
               <td>per trade</td>
            </tr>
            <tr>
               <td>Margin financing</td>
               <td>charged daily</td>
            </tr>
            <tr>
               <td>Execution Type</td>
               <td>At Market rates</td>
            </tr>
            <tr>
               <td>Deposit Fee</td>
               <td>–</td>
            </tr>
            <tr>
               <td>Withdrawal Fee</td>
               <td>0.0005 BTC</td>
            </tr>
            <tr>
               <td>Trading Fee</td>
               <td>0.1%</td>
            </tr>
         </tbody>
      </table>
   </div>
   <div class="aboutContent mt-4 mb-4">
      <h3>Trading conditions at Tradax</h3>
   </div>
   <div class="table-responsive">
      <table class="table table-striped feeTable">
         <thead>
            <tr>
               <th>Symbol</th>
               <th>Instrument name</th>
               <th>Trading hours (UTC time)</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>BTC/ETH</td>
               <td>Bitcoin / Ethereum</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>BTC/LTC</td>
               <td>Bitcoin / Litecoin</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>BTC/XRP</td>
               <td>Bitcoin / Ripple</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>BTC/BCH</td>
               <td>Bitcoin / Bitcoin Cash</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>ETH/LTC</td>
               <td>Ethereum / Litecoin</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>ETH/XRP</td>
               <td>Ethereum / Ripple</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>ETH/BCH</td>
               <td>Ethereum / Bitcoin Cash</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>USDT/ETH</td>
               <td>Ethereum / USDT</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>USDT/BTC</td>
               <td>USDT / Bitcoin</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>USDT/XRP</td>
               <td>USDT / Ripple</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>USDT/BCH</td>
               <td>USDT/Bitcoin Cash</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>INR/BCH</td>
               <td>Rupees / Bitcoin Cash</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>INR/ETH</td>
               <td>Rupees / Ethereum</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>INR/XRP</td>
               <td>Rupees / Ripple</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>INR/BTC</td>
               <td>Rupees / Bitcoin</td>
               <td>24/7</td>
            </tr>
            <tr>
               <td>INR/LTC</td>
               <td>Rupees / Litcoin</td>
               <td>24/7</td>
            </tr>
         </tbody>
      </table>
   </div>
</div>


            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
