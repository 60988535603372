// import package
import React from 'react';
import { useSelector } from 'react-redux'
import clsx from 'classnames';
import { Link } from 'react-router-dom';

// import lib
import { capitalize, emailFormat } from '../../lib/stringCase'

const UserDetail = () => {

    // redux-state
    const accountData = useSelector(state => state.account);
    const { userId, emailStatus, phoneStatus, phonenumber, type, email } = accountData;
    return (
        <div className="dashboard_box">
            <h5 className="dash_subtitle">User Details</h5>
            <div className="dashUserDetails boxSpace">
                {/*                 <div className="dudLeft">
                    <img src={require("../../assets/images/dashUserPic.jpg")} alt="" className="img-fluid" />
                    <a href="#"><i class="fas fa-pencil-alt"></i></a>
                </div> */}
                <div className="dudRight">
                    <ul>
                        <li>
                            <label>User ID </label> <span>{userId}</span>
                        </li>
                        {/* <li>
                            <label>KYC Verification </label> <span>{capitalize(type)}</span>
                        </li> */}
                        <li>
                            <label>Email <span>
                                <i class={clsx({ "fas fa-check-circle enableGreen": emailStatus == 'verified' }, { "fas fa-times-circle disabledRed": emailStatus == 'unverified' })}></i> {emailStatus} {emailFormat(email)}
                            </span></label>
                        </li>

                        {/* <li>
                            <label>Mobile Phone

                                {
                                    phoneStatus == 'unverified' && <span><i class="fas fa-times-circle disabledRed"></i> Not Verified [ <Link to='/profile'>Verify Now</Link> ]</span>
                                }

                                {
                                    phoneStatus == 'verified' && <span>{phonenumber}</span>
                                }

                            </label>
                        </li> */}


                    </ul>
                </div>
            </div>
        </div>
    )
}

export default UserDetail;