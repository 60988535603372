// import config
import axios from '../config/axios'

// import constant
import {
    SET_USER_WALLET_LIST,
    SET_USER_FIRST_CURRENCY,
    SET_USER_SECOND_CURRENCY,
    UPDATE_USER_WALLET_STAKE,
    UPDATE_USER_WALLET_P2P
} from '../constant';

export const getAssetData = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/getAssetsDetails`,
        });
        console.log('respData.data.resultrespData.data.resultrespData.data.resultrespData.data.result',respData.data.result)
        dispatch(userWalletList(respData.data.result))
        return true
    }
    catch (err) {
        return false
    }
}

export const updateWallet = async (dispatch, data, callFrom) => {
    console.log('called');
    if (callFrom == 'stake') {
        dispatch({
            type: UPDATE_USER_WALLET_STAKE,
            data
        })
    } else if(callFrom == 'p2p') {
        dispatch({
            type: UPDATE_USER_WALLET_P2P,
            data
        });
    }
    return false

}

export const userWalletList = (data) => {
    return {
        type: SET_USER_WALLET_LIST,
        data
    }
}

export const withdrawRequestFiat = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/fiatWithdraw`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const fiatRequestVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/fiatWithdraw`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const withdrawRequestCoin = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/coinWithdraw`,
            'data': data
        });
        return {
            status:"success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const coinRequestVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/coinWithdraw`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const fiatDepositRequest = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/fiatDeposit`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const walletTransfer = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/walletTransfer`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const getAssetByCurrency = async (currencyId) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/getAsset/` + currencyId
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
        }
    }
}


export const getUserDeposit = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/get-user-deposit`
        });
        return {
            status: "success",
            loading: false,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const setUserFirstCurrency = (assetData, dispatch) => {
    dispatch({
        type: SET_USER_FIRST_CURRENCY,
        data: assetData
    })
}

export const setUserSecondCurrency = (assetData, dispatch) => {
    dispatch({
        type: SET_USER_SECOND_CURRENCY,
        data: assetData
    })
}
