import React, { createRef,useState,useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from 'react-redux'
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { Link,useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
// import config
import config from '../../config';
// import validation
import validation from './validation';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';
import { getLang } from '../../lib/localStorage';
// Import action
import { forgotPassword } from '../../actions/users';



const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  'email': '',
  'reCaptcha': '',
}

const dashboardRoutes = [];
// const ForgotPassword = () => {
  export default function ForgotPassword(props) {
  
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const recaptchaRef = createRef();
  const grecaptchaObject = window.grecaptcha;
  const { ...rest } = props;
  // State values
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [toched, setToched] = useState({});
  const [loader, setLoader] = useState();
  const { email, reCaptcha } = formValue;

  // Function for all onchange
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
    setValidateError(validation(formData))
  }
  // Handle captcha
  const handleRecaptcha = (value) => {
    let formData = { ...formValue, ...{ 'reCaptcha': value } }
    setFormValue(formData)
    setValidateError(validation(formData))
  }

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } })
  }

  const handleFormSubmit = async (e) => {

    e.preventDefault();
    setLoader(true)
    let reqData = {
        email,
        reCaptcha,
    }
    let { status, loading, message, error } = await forgotPassword(reqData, dispatch);
    setLoader(loading);
    if (status == 'success') {
        setFormValue(initialFormValue)
        toastAlert('success', message, 'login');
        history.push('/login')
    } else {
        if (error) {
            setValidateError(error);
        }
        toastAlert('error', message, 'login');
    }
  }

  useEffect(() => {
    setValidateError(validation(formValue))
  }, [])

  return (
      <form className="login_form p-4 mb-4" data-aos="fade-up">
        <h2 data-aos="fade-up">Forgot Password</h2>
        <p className="paraLabel text-center mb-3">Enter Your registered email address or username, we’ll send you reset password link to your email address within two minutes</p>
        <div className="form-group pl-3 pr-3">
            <label>Email Address</label>
            <input className="form-control" placeholder="Email address" 
              value={email}
              name="email"
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {
                toched.email && validateError.email && <p className="error-message">{validateError.email}</p>
            }
        </div>
        <div className="form-group pl-3 pr-3 text-center">
            <ReCAPTCHA
              className="recapta_form"
              ref={recaptchaRef}
              grecaptcha={grecaptchaObject}
              sitekey={config.RECAPTCHA_SITE_KEY}
              onChange={handleRecaptcha}
              />
        </div>
        <div className="form-group pl-3 pr-3">
            <Button onClick={handleFormSubmit} disabled={!isEmpty(validateError) || loader}>{loader && <i class="fas fa-spinner fa-spin"></i>}Submit</Button>
        </div>
        <div className="d-flex just_cent mt-4">
            <Link to="/home" className="class_link">
            Home</Link>
            <Link to="/login" className="ml-auto">
            Login</Link>
        </div>
      </form>
  );
}

// export default ForgotPassword;
