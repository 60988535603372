export default {
    "SIGN_IN_BUTTON": "Sign In",
    "EMAIL_PLACEHOLDER": "Email Address",
    "KEEP_SIGN_COMPUTER": "Keep me signed in on this computer",
    "DON'T_HAVE_ACCOUNT": "Don't have an account",
    "EMAIL_REQUIRED": "Email field is required",
    "EMAIL_INVALID": "Email is invalid",
    "FORGOT_PASSWORD": "Forgot password",
    "PASSWORD_PLACEHOLDER": "Password",
    "PASSWORD_REQUIRED": "Password field is required",
    "PASSWORD_INCORRECT": "Password incorrect",
    "PASSWORD_MIN_MAX": "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18",
    "CONFIRM_PASSWORD_MISMATCH": "Passwords must match",
    "PASSWORD_CHANGE_SUCCESS": "Password Reset Successfully",
    "CURRENT_PASSWORD_REQUIRED": "Current Password field is required",
    "CONFIRM_PASSWORD_REQUIRED": "Confirm password field is required",
    "UPDATE_PASSWORD": "Update Login Password",
    "CURRENT_PASSWORD": "Current Password",
    "NEW_PASSWORD": "New Password",
    "CONFIRM_PASSWORD": "Confirm New Password",
    "REQUIRED": "Required",
    "INVALID_CODE": "Invalid Code",
    "TERMS_REQUIRED": "Terms field is required",
    "PERSONAL_DETAILS": "Personal Details",
    "FULL_NAME": "Full Name",
    "RESIDENTIAL_ADDRESS": "Residential Address",
    "BUILDING_BLOCK": "Building / Block Name",
    "ADDRESS": "Address",
    "STATE_PROVISION": "State / Province",
    "CITY": "City",
    "POSTAL_CODE": "Postal Code",
    "COUNTRY": "Country",
    "EDIT_PROFILE": "Edit my Profile",
    "SUBMIT": "Submit",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "NAME": "Name",
    "PROFILE_EDIT_SUCCESS": "Profile details updated successfully",
    "SOMETHING_WRONG": "Something went wrong",
    "NO_DATA": "There is no data",
    "NO_ORDER": "There is no order",
    "ORDER_CANCEL": "Your Order cancelled successfully",
    "ORDER_ALREADY_COMPLETED": "Your Order already completed",
    "ORDER_ALREADY_CANCEL": "Your Order already cancelled",
    "REGISTER_UPI_ACCOUNT": "You have not registered any UPI ID. Please provide an UPI ID in order to withdraw funds",
    "REGISTER_QR_ACCOUNT": "You have not registered any GPay QR code. Please provide QRCode in order to withdraw funds",
    "ADD_QR_ACCOUNT": "Add QR Code",
    "ACCOUNT": "Account",
    "QR_ACCOUNT": "GPay Account",
    "QR_NAME": "QR Code",
    "ADD_UPI_ACCOUNT": "Add UPI ID",
    "UPI_ACCOUNT": "UPI Account",
    "UPI_ID": "UPI ID",
    "REGISTER_BANK_ACCOUNT": "You have not registered any bank account. Please register a bank account in order to withdraw funds",
    "ADD_BANK_ACCOUNT": "Add Bank Account",
    "ADD_BANK": "Add Bank",
    "MY_BANK_ACCOUNT": "My Bank Accounts",
    "DEFAULT": "Default",
    "BANK_ACCOUNT": "Bank Account",
    "BANK_NAME": "Bank Name",
    "ACCOUNT_NO": "Account No",
    "ACCOUNT_NUMBER": "Account Number",
    "ACCOUNT_HOLDER_NAME": "Account Holder Name",
    "IBAN_CODE": "IFSC Code/IBAN Code",
    "BANK": "Bank",
    "STATUS": "Status",
    "TWO_FA": "2 Factor Authentication",
    "EDIT": "Edit",
    "DELETE": "Delete",
    "TWO_FA_MSG": "You have to enable 2FA in order to withdraw funds.",
    "BANK_EDIT_SUCCESS": "Bank detail updated successfully",
    "BANK_ADD_SUCCESS": "Bank detail added successfully",
    "BANK_DELETE_SUCCESS": "Bank detail deleted successfully",
    "BANK_SET_PRIMARY_SUCCESS": "Defaul bank detail updated successfully",
    "UPDATE": "Update",
    "NOTES": "Notes",
    "PASSWORD_TITLE": "For better security, your new password should be",
    "DOCUMENT_TITLE": "For Faster approval, your uploading Images should be:",
    "PASSWORD_DESCRIPTION1": "mimimuum 6 characters along 18 characters along.",
    "PASSWORD_DESCRIPTION2": "Atleast one UPPERCASE letter like ABC...XYZ and  atleast one lowercase (a,b,c..z) ",
    "PASSWORD_DESCRIPTION3": "Atleast one digit like 123...890",
    "PASSWORD_DESCRIPTION4": "Atleast special character like ~!@#$%^&*()",
    "TWO_FA_DESCRIPTION1": `"Enable" Two Factor Authentication as an additional security measure, to protect your funds and be able to withdraw. Please use Google Authenticator
    which you can`,
    "TWO_FA_DESCRIPTION2": "After download the app, open it. Scan the QR code. You’ll have 6 digit code Enter that 6 digit code into 2FA code box, then click Enable.",
    "TWO_FA_DESCRIPTION3": "Right down the 16 digit code in a white paper for your security if you forgot the 2FA code to withdraw funds",
    "TWO_FA_DESCRIPTION4": "The selfie or picture with your selected ID must show clear face holding the selected ID.",
    "HERE_TEXT":"Here",
    "DOWNLOAD_HERE": "Download Here",
    "HOW_ENABLE": "How to Enable",
    "ENABLE": "Enable",
    "DISABLE": "Disable",
    "ENTER_TWO_FA_CODE": "Enter 6 Digit 2FA Code",
    "TWO_FA_CODE": "Your 32 Digit Security Code",
    "SCAN_QR_CODE": "Scan the QR Code",
    "DISABLED": "Disabled",
    "ENABLED": "Enabled",
    "TWO_FA_ENABLE_SUCCESS": "TwoFA enabled successfully",
    "TWO_FA_DISABLE_SUCCESS": "TwoFA disabled successfully",
    "EDIT_SETTING_SUCCESS": "Setting updated successfully",
    "IDENTITY_DOCUMENT": "Identity Document",
    "IDENTIFICATION_DOCUMENT": "Identification Document",
    "SELECTED_ID_NUMBER": "Enter the Selected ID Number",
    "FRONT_SIDE": "Front Side",
    "MAX_1MB_IMG": "Max 1MB in jpg/png/jpeg/pdf",
    "BACK_SIDE": "Back Side",
    "SELFIE_SELECTED_ID": "Selfie with Selected ID",
    "IDENTITY_DOCUMENT_TITLE1": "Here you can verify your identity by uploading one of the approved identity documents",
    "IDENTITY_DOCUMENT_DESCRIPTION1": "All Images should be clearly visible and text also clearly visible",
    "IDENTITY_DOCUMENT_DESCRIPTION2": "If not readable text, unclear face, blur image should be disqualified and the status will be NOT verified.",
    "IDENTITY_DOCUMENT_DESCRIPTION3": "The selfie or picture with  your selected ID must show clear face holding the selected ID.",
    "IDENTITY_DOCUMENT_DESCRIPTION4": "If you want to change your identity document please contact our",
    "HERE": "Here",
    "IDENTITY_DOCUMENT_VERIFIED": "Your identity document has been verified",
    "IDENTITY_DOCUMENT_SUBMITTED": "Your identity document has been submitted",
    "SUPPORT_TEAM": "support team",
    "ADDRESS_DOCUMENT_TITLE1": "Proof of Residential Address",
    "ADDRESS_DOCUMENT_TITLE2": "Address Proof Document",
    "ADDRESS_DOCUMENT_TITLE3": "Name with Address Page",
    "ADDRESS_DOCUMENT_DESCRIPTION1": "You can verify your residential address by uploading one of the indicated documents approved as address proof.",
    "ADDRESS_DOCUMENT_DESCRIPTION2": "All document must be not older than 3 months.",
    "ADDRESS_DOCUMENT_DESCRIPTION3": "All documents must show your full address and full name.",
    "ADDRESS_DOCUMENT_DESCRIPTION4": "Utility Bill (Approved utility bills: electricity bill, gas bill, landline phone, internet bill). Mobile phone not accepted.",
    "ADDRESS_DOCUMENT_DESCRIPTION5": "If you want to change your Address document please contact our",
    "ADDRESS_DOCUMENT_VERIFIED": "Your residential address document has been verified",
    "ADDRESS_DOCUMENT_SUBMITTED": "Your residential address document has been submitted",

    "DRIVING_LICENSE": "Driving License",
    "PANCARD": "Pancard",
    "GAS_BILL": "Gas Bill",
    "AADHAR_CARD": "Aadhar Card",
    "TOO_LARGE": "Too large",
    "INVALID_IMAGE": "Please select valid image",
    "IDENTITY_DOC_UPLOAD_SUCCESS": "Identity document updated successfully",
    "ADDRESS_DOC_UPLOAD_SUCCESS": "Residential document updated successfully",
    "NOT_APPLY": "Not Apply",
    "VERIFIED": "Verified",
    "NOT_VERIFIED": "Not Verified",
    "REJECTED": "Rejected",
    "BASIC": "Basic",
    "ADVANCED": "Advanced",
    "PRO": "pro",
    "ID_ADDRESS_PROOF": "ID & Address Proof",
    "DEFAULT_BANK_ACCOUNT_DETAIL": "Bank Account Proof (only default withdraw bank)",
    "USER_ID": "User ID",
    "MEMBER_SINCE": "Member Since",
    "VERIFICATION_TYPE": "Verification Type",
    "EMAIL_PHONE": "Email Address & Phone",
    "UPGRADE": "Upgrade",
    "WITHDRAW_ACCOUNT": "Withdraw Account",
    "FIAT_WITHDRAW_DESCRIPTION1": "Minimal Withdraw",
    "FIAT_WITHDRAW_DESCRIPTION2": "Withdraw will take 24-48 hrs due to bank network / holidays",
    "FINAL_WITHDRAW_AMOUNT": "Final Withdraw Amount",
    "WITHDRAW_AMOUNT": "Withdraw Amount",
    "WITHDRAW_ADDRESS": "Withdraw Address",
    "ALLOW_NUMERIC": "Only allowed numeric",
    "BALANCE": "Balance",
    "INSUFFICIENT_BALANCE": "Insufficient balance",
    "KYC_SUBMIT_ALERT": "Please submit your kyc details",
    "INVALID_BANK_ACCOUNT": "Invalid bank account",
    "VERIFICATION_LINK": "Verification link sent to email address",
    "INVALID_TOKEN": "Invalid Token",
    "EXPIRY_TOKEN": "Expiry token",
    "DEPOSIT_QR_CODE": "To deposit use this QR",
    "COIN_ADDRESS": "Your Coin Address",
    "COPY_ADDRESS": "Copy Address",
    "COIN_DEPOSIT_DESCRIPTION1": "Send only LTC to this deposit address.",
    "COIN_DEPOSIT_DESCRIPTION2": "Minimal Deposit: 0.001 LTC",
    "COIN_DEPOSIT_DESCRIPTION3": "Sending coin or token other than LTC to this address may result in the loss of your deposit.",
    "WITHDRAW_TOO_LOW": "Final amount too low",
    "FIAT_DEPOSIT_DESCRIPTION1": "Minimal Deposit:",
    "FIAT_DEPOSIT_DESCRIPTION2": "Must upload proof of deposit in pdf / jpg and enter reference number / ID",
    "ADMIN_ACCOUNT": "Admin Account",
    "DEPOSIT": "Deposit",
    "DEPOSIT_AMOUNT": "Deposit Amount",
    "UPLOAD_PROOF": "Upload Proof",
    "DEPOSIT_REQUEST_SUCCESS": "Deposit request sent successfully",
    "DEPOSIT_TOO_LOW": "Deposit amount too low",
    "SELECT_AMOUNT": "Select Amount",
    "INVALID_WALLET_TYPE": "Invalid wallet type",
    "WALLET_MIS_MATCH": "From wallet and to wallet should be different",
    "WALLET_TRANSFER_SUCCESS": "Wallet conversion process completed successfully",
    "OPEN_ORDER": "Open Orders",
    "CANCEL": "Cancel",
    "PRICE": "Price"

}
