// import constant
import { SET_SITE_SETTING } from "../constant";

const initialState = {};

const siteSetting = (state = initialState, action) => {
    switch (action.type) {
        case SET_SITE_SETTING:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};

export default siteSetting;