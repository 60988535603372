/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { newsLetters } from "../../actions/users";
import { makeStyles } from "@material-ui/core/styles";

import emailValidation from "./emailValidation";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

// import action
import { getLanguage } from "../../actions/commonAction";

// import lib
import { capitalize } from "../../lib/stringCase";
import { setLang, getLang } from "../../lib/localStorage";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "lib/toastAlert";

const useStyles = makeStyles(styles);

const initialFormValue = {
  email: "",
};

export default function Footer(props) {
  const history = useHistory();

  const classes = useStyles();
  const { i18n } = useTranslation();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [settings, setSettings] = useState({});

  const dispatch = useDispatch();
  const siteSetting = useSelector(state => state.siteSetting);
  const { isAuth } = useSelector((state) => state.auth);

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  // state
  const [langOption, setLangOption] = useState([]);
  const [language, setLanguage] = useState("");

  // function
  const handleLanguage = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLanguage(value);
    setLang(value);
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    if(Object.keys(siteSetting).length && !Object.keys(settings).length)
      setSettings(siteSetting);
  },[siteSetting]);

  const fetchLanguage = async () => {
    try {
      const { status, loading, result } = await getLanguage(dispatch);
      if (status == "success") {
        setLangOption(result);
        let lang = getLang();
        if (isEmpty(lang)) {
          let primaryData = result && result.length > 0 && result.find((el) => el.isPrimary == true);
          if (primaryData) {
            setLanguage(primaryData.code);
            setLang(primaryData.code);
            i18n.changeLanguage(primaryData.code);
          }
        } else {
          setLanguage(lang);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchLanguage();
  }, []);

  const { email } = formValue;

  const onChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };

    setFormValue(formData);
  };

  const savenewsletter = async (e) => {
    e.preventDefault();
    const reqData = {
      email: email,
    };
    
    //let { status, loading, message, error } = await login(reqData, dispatch);
    let { loading, status, message, error } = await newsLetters(reqData);
    if (status == false) {
      let err = {
        email: error,
      };
      setValidateError(err);
    } else {
      setValidateError({});
      setFormValue(initialFormValue)
      toastAlert("success", "Your email submitted successfully", "login");
    }
  };
  return (
    // <footer className="pt-4">
    //   <div className="container">
    //     <div className="row pb-lg-3">
    //       <div className="col-sm-6 col-lg-3" data-aos="fade-up">
    //         <h5>Company</h5>
    //         <ul className="list-inline">
    //           <li><Link to="">About Us</Link></li>
    //           <li><Link to="">Press</Link></li>
    //           <li><Link to="">Investors</Link></li>
    //           <li><Link to="">Terms of Service</Link></li>
    //           <li><Link to="">Privacy Policy</Link></li>
    //         </ul>
    //       </div>
    //       <div className="col-sm-6 col-lg-3" data-aos="fade-up">
    //         <h5>Investing & Trading</h5>
    //         <ul className="list-inline">
    //           <li><Link to="">Business accounts</Link></li>
    //           <li><Link to="">Token listing</Link></li>
    //           <li><Link to="">API</Link></li>
    //           <li><Link to="">Fee Schedule</Link></li>
    //           <li><Link to="">OTC / P2P trading</Link></li>
    //           <li><Link to="">Security</Link></li>
    //           <li><Link to="">Staking</Link></li>
    //         </ul>
    //       </div>
    //       <div className="col-sm-6 col-lg-3" data-aos="fade-up">
    //         <h5>Support</h5>
    //         <ul className="list-inline">
    //           <li><Link to="">FAQ</Link></li>
    //           <li><Link to="">Help Center</Link></li>
    //           <li><Link to="">Contact us</Link></li>
    //         </ul>
    //       </div>
    //       <div className="col-sm-6 col-lg-3" data-aos="fade-up">
    //         <div className="footer_menu">
    //           <select
    //             className="custom-select"
    //             name="language"
    //             value={language}
    //             onChange={handleLanguage}
    //           >
    //             {
    //               langOption && langOption.length > 0 && langOption.map((item, key) => {
    //                 return (
    //                   <option key={key} value={item.code}>{capitalize(item.name)}</option>
    //                 )
    //               })
    //             }
    //           </select>
    //         </div>
    //         <h5>Social Media</h5>
    //         <ul className="list-inline d-flex social_media">
    //           <li className="pr-4"><Link to=""><i className="fab fa-telegram-plane"></i></Link></li>
    //           <li className="pr-4"><Link to=""><i className="fab fa-twitter"></i></Link></li>
    //           <li className="pr-4"><Link to=""><i className="fab fa-linkedin-in"></i></Link></li>
    //           <li className="pr-4"><Link to=""><i className="fab fa-reddit-alien"></i></Link></li>
    //           <li><Link to=""><i className="fab fa-youtube"></i></Link></li>
    //         </ul>
    //       </div>
    //     </div>
    //     <div className="footer_bottom text-center">
    //       <div className="row py-3">
    //         <div className="col-md-12 mb-3 mb-md-0">
    //           <label>Tradax is a fully licensed crypto currency exchange and trusted service provider</label>
    //           <p>© Copyright 2021 by Tradax. All rights reserved.</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
            <div className="footer_logo_part">
              <img
                src={require("../../assets/images/footer_logo.png")}
                alt="logo"
                className="img-fluid footer_logo"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <div className="footer_first_link">
              <h3>Platform</h3>
              <ul>
                <li>
                  <Link to="/spot">Exchange</Link>
                </li>

                <li>
                  <Link
                    onClick={() => {
                      if (isAuth) {
                        history.push("/p2p");
                      } else {
                        history.push("/login");
                      }
                    }}
                  >
                    P2P
                  </Link>
                </li>

                <li>
                  <Link to="/wallet">Crypto Wallets</Link>
                </li>

                <li>
                  <Link to="/blog">Crypto News</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <div className="footer_first_link">
              <h3>Resources</h3>
              <ul>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/contact">ContactUs</Link>
                </li>     
                {/* <li>
                  <Link to="/fees">Fees</Link>
                </li> */}
                {isAuth == true && (
                  <li>
                    <Link to="/support-ticket">Support</Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <div className="subscribe_box">
              <h3>Get the latest news and updates</h3>
              <div className="input_box_footer">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Email Address"
                ></input>

                <button onClick={savenewsletter}>SUBMIT</button>
              </div>
              {validateError.email && (
                <p className="error-message">{validateError.email}</p>
              )}
              <div className="social_link">
                <ul>
                {/* <li>
                    <Link to={{pathname:`${settings.linkedinLink}`}} target="_blank">
                    <i class="fab fa-telegram-plane"></i>
                    </Link>
                  </li> */}
                  <li>
                    <Link to={{pathname:`${settings.twitterUrl}`}} target="_blank">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  
                  <li>
                    <Link to={{pathname:`${settings.facebookLink}`}} target="_blank">
                    <i class="fab fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={{pathname:`${settings.linkedinLink}`}} target="_blank">
                      <i className="fab fa-linkedin"></i>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={{pathname:`${settings.linkedinLink}`}} target="_blank">
                    <i class="fab fa-whatsapp"></i>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="copy_contant">
            <p className="clas_pas_footer">
            © Copyright ©2022 All rights reserved
            </p>
          </div>
          <div className="copy_contant_right">
            <ul>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>

              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
