// import config
import axios from '../config/axios';

export const getRecentTransaction = async (reqData) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/recentTransaction`,
            params:reqData
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
            count:respData.data.count
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
        }
    }
}



export const cancelMyRequest= async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/cancelMyRequest`,
            data
        });
       
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
          
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const getLoginHistory = async (reqData) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/loginHistory`,
            params:reqData
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
            count:respData.data.count

        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getNotificationHistory = async () => {
    try {
        // console.log("dataaaaaaaaaaaaa",);
        let respData = await axios({
            'method': 'get',
            'url': `/api/notificationHistory`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        console.log(err,'errerrerrerr')
        return {
            status: 'failed',
            loading: false,
        }
    }
}


export const setNotificationHistory = async () => {
    try {
        // console.log("dataaaaaaaaaaaaa",);
        let respData = await axios({
            'method': 'post',
            'url': `/api/notificationHistory`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        console.log(err,'errerrerrerr')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

/** 
 * Get User Balance
*/
export const getDashBal = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/getDashBal`,
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
        }
    }
}