import React, { useEffect, useState } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Checkbox from 'rc-checkbox';
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { getBlogBySlug } from '../actions/users';
import {
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import Template from "react-dynamic-html";
import Moment from 'react-moment';
import 'moment-timezone';
import LeftBar from '../partials/leftBar'
import config from '../lib/config'
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function BlogDescription(props) {
  const { ...rest } = props;
  const [blogData, setBlogData] = useState({});
  const history = useHistory();
  let { slug } = useParams();
  useEffect(() => {
    getBlogDetails();
  }, [slug])

  const getBlogDetails = async () => {
    let passData = {
      slug: slug
    }
    const { status, data } = await getBlogBySlug(passData);
    setBlogData(data)
    // debugger;
  }

  const createMarkup = (a) => {
    return { __html: a };
  }
  // const redirect = async(item)=>{
  //   console.log(item,"itemitem")
  //   history.push('/blog_category/'+item._id)
  // }

  console.log(blogData,'blogDatablogData')
  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages">
            <div className="container">



              <GridContainer>
                <GridItem md={12} className="text-center">
                  <h2 className="main_title">Blog</h2>
                </GridItem>
              </GridContainer>


              <div className="blog_desc wow fadeIn">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
           
                    {/* <Link to={"/blog_category/" + blogData.slug}> */}
                      <div class="blogWhiteCard mt-3">
                        <div class="bloglistImg">
                          <img src={`${config.API_URL}/images/blogImages/${blogData.image}`} width="100%" height="auto" alt="" class="img-fluid" />
                          <span class="blogPostDate">
                            <Moment format="MMMM DD,YYYY">
                              {blogData && blogData.created_date}
                            </Moment>
                          </span>
                        </div>
                        <div class="blogListTitleBox blogDetailTitle">
                          <h1>{blogData && blogData.title}</h1>
                          <h6>Posted By <span>{blogData && blogData.author}</span> | 91 Views</h6>
                        </div>
                        <div class="blogListDescription blogFullDetail">
                          <div dangerouslySetInnerHTML={createMarkup(blogData.content)}></div>
                        </div>
                      </div>
{/* 
                    </Link> */}
                   
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <LeftBar />
                  </GridItem>
                </GridContainer>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
