// import lib
import isEmpty from '../../lib/isEmpty';

export const fiatValidation = value => {
    let errors = {};

    if (isEmpty(value.currencyId)) {
        errors.currencyId = "Select Currency"
    }

    if (isEmpty(value.bankId)) {
        errors.bankId = "Required Bank Details"
    }

    if (isEmpty(value.amount) || value.amount<0 || value.amount==0) {
        errors.amount = "Enter Valid Amount"
    } else if (isNaN(value.amount)) {
        errors.amount = "Numeric values only allowed"
    }

    if (!isEmpty(value.amount) && !isNaN(value.amount) && value.finalAmount > value.spotwallet) {
        errors.finalAmount = "Insufficient Balance"
    }

    if (isEmpty(value.twoFACode)) {
        errors.twoFACode = "Two FA Code Required"
    } else if (isNaN(value.twoFACode)) {
        errors.twoFACode = "Numeric values only allowed"
    }

    return errors;
}

export const fiatDepositValidation = value => {
    let errors = {};
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|docx|doc)$/;

    if (isEmpty(value.amount)) {
        errors.amount = "Amount Required"
    } else if (isNaN(value.amount)) {
        errors.amount = "Numeric values only allowed"
    } else if (parseFloat(value.minimumDeposit) > parseFloat(value.amount)) {
        errors.amount = "Deposit Amount is too Low"
    }



    if (value.image && value.image.size) {
        if (value.image.size > 1000000) {
            errors.image = "Image size is too large"
        } else if (!imageFormat.test(value.image.name)) {
            errors.image = "Invalid Image"
        }
    } else {
        errors.image = "Image Required";
    }

    return errors;
}


export const coinValidation = value => {
    let errors = {};

    if (isEmpty(value.currencyId)) {
        errors.currencyId = "Select Currency"
    }

    if (isEmpty(value.receiverAddress)) {
        errors.receiverAddress = "Receiver Address Required"
    }

    if (isEmpty(value.amount) || value.amount<0 || value.amount==0) {
        errors.amount = "Enter Valid Amount"
    } else if (isNaN(value.amount)) {
        errors.amount = "Numeric values only allowed"
    }

    if (!isEmpty(value.amount) && !isNaN(value.amount) && value.finalAmount > value.spotwallet) {
        errors.finalAmount = "Insufficient Balance"
    }

    if (isEmpty(value.twoFACode)) {
        errors.twoFACode = "Two FA Requred"
    } else if (isNaN(value.twoFACode)) {
        errors.twoFACode = "Onlu numeric values allowed"
    }

    return errors;
}

export const walletTransferValidation = value => {
    let errors = {};

    if (isEmpty(value.toType)) {
        errors.toType = "To Type Required"
    } else if (!['spot', 'P2P'].includes(value.toType)) {
        errors.toType = "Invalid Wallet Type"
    } else if (value.fromType == value.toType) {
        errors.toType = "Wallet Mismatch"
    } 

    if (value.amount == '' || value.amount==0 || value.amount<0){
        errors.amount= "Enter valid Amount"
    }

    if (isEmpty(value.userAssetId)) {
        errors.userAssetId = "Asset Details Required"
    }

    return errors;
}