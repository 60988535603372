// import package
import React, { createRef, useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from 'components/Header/HeaderLinks';
// import action
import { resetPassword } from '../../actions/users';

// import lib
import validation from './validation';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
    'password': '',
    'confirmPassword': '',
}

const ResetPassword = () => {
    const history = useHistory();
    const { authToken } = useParams();

    // states
    const [formValue, setFormValue] = useState(initialFormValue);
    const [toched, setToched] = useState({});
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();
    const [ShowPassword, setShowPassword] = useState(false);
    const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);

    const { password, confirmPassword } = formValue;

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        setValidateError(validation(formData))
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        setToched({ ...toched, ...{ [name]: true } })
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        let reqData = {
            password,
            confirmPassword,
            authToken
        }
        let { status, loading, error, message } = await resetPassword(reqData);
        setLoader(loading);

        if (status == "success") {
            setFormValue(initialFormValue)
            setToched({})
            setValidateError(validation(initialFormValue))
            history.push("/login");
            toastAlert('success', message, 'resetPassword');
        } else {
            if (error) {
                setValidateError(error);
            }
            toastAlert('error', error, 'resetPassword');

        }
    }

    useEffect(() => {
        setValidateError(validation(formValue))
    }, [])

    return (
        <>
           
           <form className="login_form p-4 mb-4" data-aos="fade-up">
              
                        <h2 className="text-center mb-md-4 pb-3" data-aos="fade-up">Reset Password</h2>
                            <p className="paraLabel text-center mb-3">Enter Your New Password and Confirm Password Here.</p>
                            <div className="form-group">
                            <div className='input-group'>
                                <input
                                    className="form-control"
                                    placeholder="Password"
                                    name="password"
                                   // type="password"
                                   type={`${ShowPassword ? "text" : "password"
                                }`}
                                    value={password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                 <div class="input-group-append">
                                <span
                                    className="input-group-text"
                                    onClick={() =>
                                        setShowPassword(!ShowPassword)
                                    }
                                >
                                    <i
                                        className={`fa fa-eye-slash ${ShowPassword ? "d-none" : ""
                                            }`}
                                    ></i>
                                    <i
                                        className={`far fa-eye ${ShowPassword ? "" : "d-none"
                                            }`}
                                    ></i>
                                </span>
                                </div>
                                </div>
                                {toched.password && validateError.password && <p className="error-message">{validateError.password}</p>}

                            </div>
                            <div className="form-group">
                            <div className='input-group'>
                                <input
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                   // type="password"
                                   type={`${ShowConfirmPassword ? "text" : "password"
                                }`}
                                    value={confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div class="input-group-append">
                                <span
                                    className="input-group-text"
                                    onClick={() =>
                                        setShowConfirmPassword(!ShowConfirmPassword)
                                    }
                                >
                                    <i
                                        className={`fa fa-eye-slash ${ShowConfirmPassword ? "d-none" : ""
                                            }`}
                                    ></i>
                                    <i
                                        className={`far fa-eye ${ShowConfirmPassword ? "" : "d-none"
                                            }`}
                                    ></i>
                                </span>
                                </div>
                                </div>
                                {toched.confirmPassword && validateError.confirmPassword && <p className="error-message">{validateError.confirmPassword}</p>}
                            </div>

                            <div className="form-group">
                                <Button
                                    onClick={handleFormSubmit}
                                    disabled={!isEmpty(validateError)}
                                >
                                    {loader && <i class="fas fa-spinner fa-spin"></i>}Submit
                                </Button>
                            </div>
                            <div className="d-flex">
                                <Link to="/login" className="ml-auto">Login</Link>
                            </div>
                            </form>
        </>
    )
}

export default ResetPassword;