// Authentication
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";

// Modal
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";
export const SETTING_MODAL = "SETTING_MODAL";

// User Register
export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

// Current User
export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";
export const SET_BANK_DETAIL = "SET_BANK_DETAIL";
export const SET_UPI_DETAIL = "SET_UPI_DETAIL";
export const SET_UPI_FORM = "SET_UPI_FORM";
export const SET_QR_DETAIL = "SET_QR_DETAIL";
export const SET_QR_FORM = "SET_QR_FORM";

export const SET_BANK_FORM = "SET_BANK_FORM";
export const LOGOUT = "LOGOUT";

// Common
export const SET_LANGUAGE_OPTION = "SET_LANGUAGE_OPTION";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_SITE_SETTING = "SET_SITE_SETTING";
export const SET_TIME_ZONE = "SET_TIME_ZONE";
export const SET_USER_KYC = "SET_USER_KYC";
export const SET_ID_PROOF_KYC = "SET_ID_PROOF_KYC";
export const SET_ADDRESS_PROOF_KYC = "SET_ADDRESS_PROOF_KYC";
export const SET_USER_WALLET_LIST = "SET_USER_WALLET_LIST";
export const UPDATE_USER_WALLET_STAKE = "UPDATE_USER_WALLET_STAKE";
export const UPDATE_USER_WALLET_P2P = "UPDATE_USER_WALLET_P2P";
export const SET_USER_SETTING = "SET_USER_SETTING";
export const SET_TRADE_THEME = "SET_TRADE_THEME";

// Spot Trade
export const SET_INSTANT_SPOT = "SET_INSTANT_SPOT";
export const SET_LAST_PRICE = "SET_LAST_PRICE";
export const SET_SPOT_PAIR_TRADE = "SET_SPOT_PAIR_TRADE";
export const SET_SPOT_TRADE_PAIR_LIST = "SET_SPOT_TRADE_PAIR_LIST";
export const SET_THEME = "SET_THEME";
export const SET_SPOT_MARKET_PRICE = "SET_SPOT_MARKET_PRICE";

// Perpetual Trade
export const SET_PERPETUAL_MARKET_PRICE = "SET_PERPETUAL_MARKET_PRICE";
export const SET_PERPETUAL_PAIR_TRADE = "SET_PERPETUAL_PAIR_TRADE";

// Currency
export const SET_USER_FIRST_CURRENCY = "SET_USER_FIRST_CURRENCY";
export const SET_USER_SECOND_CURRENCY = "SET_USER_SECOND_CURRENCY";
export const UPDATE_SPOT_ASSET = "UPDATE_SPOT_ASSET";
export const SET_SPOT_ORDER_BOOK = "SET_SPOT_ORDER_BOOK";

// P2P Trade
export const SET_P2P_SPOT_PAIR_TRADE = "SET_P2P_SPOT_PAIR_TRADE";
export const SET_PRICE_CONVERSION = "SET_PRICE_CONVERSION";

