// import package
import React, {useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import P2pUserWalletList from './P2pUserWalletList';
// import WalletTransfer from './WalletTransfer';
import LastLogin from '../Dashboard/LastLogin';
import {getP2PPairList} from '../../actions/p2pAction';

//import lib
import {toFixed} from '../../lib/roundOf'


const reqData = {
    'firstCurrencySymbol': "BTC",
    'secondCurrencySymmol': "USDT"
}
const P2pwallet = () => {
    const dispatch = useDispatch();
     // redux-state
     const walletData = useSelector(state => state.wallet);
     const priceConversion = useSelector(state => state.priceConversion);
     const [spotpair,setSpotpair]=useState([]);
     const [btcbalance,setBtcBalance]=useState(0);
     const [indexprice,setIndexprice]=useState(0);
     const [estvalue,setEstvalue]=useState();

      
    // state
  const [totalBals, setTotalBals] = useState(0); // Balance Loader
  const [estBal, setEstBal] = useState(0); // Estimated Balance
  const [balLoader, setBalLoader] = useState(true); // Balance Loader
  const [estLoader, setEstLoader] = useState(true); // Estimated Loader

  
  // function
useEffect(() => {
    if (
      priceConversion &&
      priceConversion.length > 0 &&
      walletData &&
      walletData.length > 0
    ) {
      let estBal = 0;
      walletData.map((item) => {
        if (item && item.currency && item.currency.type == "fiat") {
          if (item.currency.currencySymbol == "USD") {
           
            estBal = estBal + (item.derivativeWallet + item.p2pbalance);
          } else {
            let CNVPriceData = priceConversion.find(
              (el) =>
                el.baseSymbol == reqData.firstCurrencySymbol &&
                el.convertSymbol == item.currency.currencySymbol
            );
            
            if (CNVPriceData) {

              let bal =
                (item.derivativeWallet + item.p2pbalance) /
                CNVPriceData.convertPrice;

              let CNVPrice = priceConversion.find(
                (el) =>
                  el.baseSymbol == reqData.firstCurrencySymbol &&
                  el.convertSymbol == "USD"
              );
              // console.log("CNVPriceDataCNVPriceData4", CNVPrice);
              if (CNVPrice) {
                // console.log("CNVPriceDataCNVPriceData5",estBal , bal , CNVPrice.convertPrice)
                estBal = estBal + bal * CNVPrice.convertPrice;
              }
              // console.log("estBalestBalestBal", estBal);
            }
          }
        } else {
          let CNVPriceData = priceConversion.find(
            (el) =>
              el.baseSymbol == item.currency.currencySymbol &&
              el.convertSymbol == "USD"
          ); 

         
     
          if (CNVPriceData) {
            estBal =
              estBal +
              ( item.p2pbalance) *
                CNVPriceData.convertPrice;
          }
        }
      });
      // console.log("estBalestBalestBal",estBal)


      setEstBal(estBal);
      console.log(estBal,'-------------bal')
      let firPriceCNV = priceConversion.find(
        (el) =>
          el.baseSymbol == reqData.firstCurrencySymbol &&
          el.convertSymbol == "USD"
      );

      if (firPriceCNV) {
          console.log(estBal / firPriceCNV.convertPrice,'(estBal / firPriceCNV.convertPrice')
        setTotalBals(estBal / firPriceCNV.convertPrice);
        setEstLoader(false);
        setBalLoader(false);
      }
    }
  }, [priceConversion, walletData]);
  const p2pSpotPair = async () => {
  // this.P2pwallet();
       try {
        const { result, status, loading } = await getP2PPairList(dispatch)

       if (result) {
           setSpotpair(result)
            result.length > 0 && result.map((item, key) => {
                
                if(item.first_currency == 'USDT' && item.second_currency=='USD'){
                   
                    if(btcbalance !=undefined && btcbalance!=0){
                        var est_value=(item.index_price*btcbalance).toFixed(2);
                        setEstvalue(est_value);

                    }
                }
            })
        }
    } catch (err) { }
}
    
useEffect(() => {
   // wallData();
   
  }, [])
  
  useEffect(() => {
    if(walletData && walletData.length > 0) {
     
        walletData.map((item, key) => { 
            console.log("Wallet Item---",item);
            if(item.currencySymbol == 'USDT'){
                setBtcBalance(item.p2pbalance);
            }
         })
    }
    p2pSpotPair();
}, [walletData,btcbalance])

console.log("btcbalancebtcbalancebtcbalance",spotpair);
    return (
        <div className="dashboardContent userPages">
            <div className="container">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={5} lg={5}>
                        <h3 className="dash_title">Wallet</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7} lg={7}>
                       <LastLogin/>
                    </GridItem>
                </GridContainer>
                <div className="dashboard_box mb-2">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <h5 className="dash_subtitle">Estimated Balance</h5>
                            <div className="balance_details_panel">
                                <div className="balance_details_left">
                                <div className="mb-3">
                                        <h3>Total Account Balance</h3>
                                        <h2>{toFixed(totalBals, 8)}{" "}<span>{reqData.firstCurrencySymbol}</span></h2>
                                    </div>
                                    <div>
                                        <h3>Estimated Value:</h3>
                                        <h4>{toFixed(estBal, 2)}{" "}USD</h4>
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                        {/* <WalletTransfer /> */}
                    </GridContainer>
                </div>
                <P2pUserWalletList />
            </div>
        </div>
    )
}

export default P2pwallet;