import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

// import component
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';


const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const dashboardRoutes = [];
export default function Forgot(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="page_wrap">
      <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />
      <div className="login_container">
     
        <div className="row w-100">
          <div className="col-lg-4 col-md-6 m-auto">
             <ForgotPassword />
            {/* <form className="login_form p-4 mb-4" data-aos="fade-up">
            <h2 data-aos="fade-up">Forgot Password</h2>
                <p className="paraLabel text-center mb-3">Enter Your registered email address or username, we’ll send you reset password link to your email address within two minutes</p>

              <div className="form-group pl-3 pr-3">
              <label>Email Address</label>
                <input type="text" className="form-control" placeholder="" />
              </div>
              <div className="form-group pl-3 pr-3 text-center">
                <img src={require("../assets/images/new/captchaGoogle.jpg")} className="img-fluid" alt="" />
              </div>
              <div className="form-group pl-3 pr-3">
                <Button>Submit</Button>
              </div>
               <div className="d-flex just_cent mt-4">
                  <Link to="/home" className="class_link">Home</Link>
                  <Link to="/login" className="ml-auto">Login</Link>
                </div>
            </form> */}
          </div>
        </div>
      </div>
    </div>
  );
}
