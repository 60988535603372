import React, { useEffect } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Checkbox from 'rc-checkbox';
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import {Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return null;
}

const marks = [
  {
    value: 0,
    label: '1%',
  },
  {
    value: 15,
    label: '15%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 65,
    label: '65%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 100,
    label: '100%',
  },
];

function valuetext(value) {
  return `${value}%`;
}

// Account Approval Table
const stakingHistory = [
{ date: "11-05-2021 15:15", crypto: "Bitcoin", amount: "0.00215487 BTC", apy : "7.25%", interestEarned : "0.00000254 BTC", status : <div className="textStatusGreen">Success</div>, },
{ date: "11-05-2021 15:15", crypto: "Ethereum", amount: "0.00215487 BTC", apy : "7.25%", interestEarned : "0.00000254 BTC", status : <div className="textStatusGreen">Success</div>, },
{ date: "11-05-2021 15:15", crypto: "Ripple", amount: "0.00215487 BTC", apy : "7.25%", interestEarned : "0.00000254 BTC", status : <div className="textStatusOrange">Redeemed</div>, },
{ date: "11-05-2021 15:15", crypto: "Tether Coin", amount: "0.00215487 BTC", apy : "7.25%", interestEarned : "0.00000254 BTC", status : <div className="textStatusOrange">Redeemed</div>, },
{ date: "11-05-2021 15:15", crypto: "Ripple", amount: "0.00215487 BTC", apy : "7.25%", interestEarned : "0.00000254 BTC", status : <div className="textStatusGreen">Success</div>, }
];
const stakingHistoryColumns = [
  {
    name: 'Date',
    selector: 'date',
    sortable: false,
  },
  {
    name: 'Crypto',
    selector: 'crypto',
    sortable: false,
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: false,
  },
  {
    name: 'APY',
    selector: 'apy',
    sortable: false,
  },
  {
    name: 'Interest Earned',
    selector: 'interestEarned',
    sortable: false,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
  
];

export default function Staking(props) {
const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
 const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { ...rest } = props;
   
  return (
    <div className="dashboard_container page_wrap">
      <div className="modal fade" id="stakingModal">
    <div className="modal-dialog modal-dialog-centered modal-lg modalCustomSize">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title mt-0">Transfer Bitcoin</h4>
          <button type="button" className="close" data-dismiss="modal">&times;</button>
        </div>
        <div className="modal-body">
          <GridContainer>
            <GridItem sm={12} md={7}>
              <div className="stakePopDetails contact_form settingsSelect">
                <div className="flexText">
                  <label>Wallet Balance</label>
                  <h4>0.0521458798 BTC</h4>
                </div>
                <div className="form-group">
                  <label class="flexLabel">
                    <span>Interested Amount to Stake</span>
                    <a href="#">Deposit</a>
                  </label>
                  <Slider
                    defaultValue={20}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-custom"
                    step={10}
                    valueLabelDisplay="auto"
                    marks={marks}
                  />
                </div>
                <div className="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="btnType1">Stake Amount</span>
                    </div>
                    <input type="text" class="form-control text-right borderZero" value="0.00058780" />
                    <div class="input-group-append">
                      <span class="btnType1">BTC</span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <div className="form-check">
                      <Checkbox name="stakeTerms" />
                      <label className="form-check-label" for="flexCheckDefault">I have read the <a href="#">terms & conditions</a></label>
                  </div>
                </div>
                <div className="form-group">
                <button className="btn btn-primary text-uppercase w-100" data-toggle="modal" data-target="#stakingModal">Transfer Confirm</button>
                </div>
              </div>
            </GridItem>
            <GridItem sm={12} md={5}>
              <div className="stakeDetailsRight">
                <h4>Staking Details</h4>
                <ul>
                  <li>
                    <label>7-Day APY</label>
                    <span className="textDepositGreen">4.25%</span>
                  </li>
                  <li>
                    <label>Flexible Interest Per Thousand</label>
                    <span>0.00000520 BTC</span>
                  </li>
                  <li>
                    <label>Maximum subscription</label>
                    <span>5.55 BTC</span>
                  </li>
                  <li>
                    <label>Value date</label>
                    <span>24-05-2021 15:15</span>
                  </li>
                </ul>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  </div>
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
        <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages">
            <div className="container">
              <GridContainer>
                  <GridItem xs={12} sm={12} md={5} lg={5}>
                    <h3 className="dash_title">Staking</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7} lg={7}>
                    <ul className="profile_dash">
                     <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li>
                     <li>13-05-2021  15:15, Chrome, 182.254.127  - <span>Last login</span></li>
                   </ul>
                  </GridItem>
                </GridContainer>

              <GridContainer>
                  <GridItem xs={12} sm={12} md={8} lg={7}>
                  <div className="dashboard_box">
                    <h5 className="dash_subtitle">Staking Details</h5>
                    <div className="balance_details_panel">
                      <div className="balance_details_left">
                        <div className="mb-3">
                          <h3>Total Staking</h3>
                          <h2>0.00455349 <span>BTC</span></h2>
                        </div>
                        <div>
                          <h3>Estimated Value:</h3>
                          <h4>$232.77</h4>
                        </div>
                      </div>
                      <div className="balance_details_right">
                        <div className="chartDash">
                          <img src={require("../assets/images/dashboardChart.jpg")} alt="" className="img-fluid" />
                        </div>
                        <div className="chartLabel">
                          <ul>
                            <li>
                              <label><i class="fas fa-square-full squareOrange"></i> BUSD</label>
                              <span>106.49508070</span>
                            </li>
                            <li>
                              <label><i class="fas fa-square-full squareBlue"></i> BNB</label>
                              <span>0.1083000</span>
                            </li>
                            <li>
                              <label><i class="fas fa-square-full squareYellow"></i> TRX</label>
                              <span>283.1749000</span>
                            </li>
                            <li>
                              <label><i class="fas fa-square-full squareGreen"></i> XRP</label>
                              <span>16.31823000</span>
                            </li>
                            <li>
                              <label><i class="fas fa-square-full squareGrey"></i> Other</label>
                              <span>0</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={5}>
                  <div className="dashboard_box boxSpace">
                    <h5 className="dash_subtitle">Current Subscription</h5>
                    <div className="stakingSubscription">
                      <Scrollbars style={{ width: "100%", height:175 }}>
                      <ul>
                        <li>
                          <div className="csLeft">
                            <h3><img src={require("../assets/images/btcIcon.png")} alt="" className="img-fluid" /> Bitcoin</h3>
                            <p><span>20-05-2021 15:15</span> <span className="textDepositGreen">5.25%</span> <span>Auto</span></p>
                          </div>
                          <div className="csRight">
                            <h4>150.25487825 BTC</h4>
                            <button className="btn btn-outline text-uppercase py-1 m-0">Redeem Now</button>
                          </div>
                        </li>
                        <li>
                          <div className="csLeft">
                            <h3><img src={require("../assets/images/usdtIcon.png")} alt="" className="img-fluid" /> TetherCoin</h3>
                            <p><span>20-05-2021 15:15</span> <span className="textDepositGreen">5.25%</span> <span>Auto</span></p>
                          </div>
                          <div className="csRight">
                            <h4>150.25487825 USDT</h4>
                            <button className="btn btn-outline text-uppercase py-1 m-0">Redeem Now</button>
                          </div>
                        </li>
                        <li>
                          <div className="csLeft">
                            <h3><img src={require("../assets/images/ethIcon.png")} alt="" className="img-fluid" /> Ethereum</h3>
                            <p><span>20-05-2021 15:15</span> <span className="textDepositGreen">5.25%</span> <span>Auto</span></p>
                          </div>
                          <div className="csRight">
                            <h4>150.25487825 ETH</h4>
                            <button className="btn btn-outline text-uppercase py-1 m-0">Redeem Now</button>
                          </div>
                        </li>
                        <li>
                          <div className="csLeft">
                            <h3><img src={require("../assets/images/xrpIcon.png")} alt="" className="img-fluid" /> Ripple</h3>
                            <p><span>20-05-2021 15:15</span> <span className="textDepositGreen">5.25%</span> <span>Auto</span></p>
                          </div>
                          <div className="csRight">
                            <h4>150.25487825 XRP</h4>
                            <button className="btn btn-outline text-uppercase py-1 m-0">Redeem Now</button>
                          </div>
                        </li>
                        <li>
                          <div className="csLeft">
                            <h3><img src={require("../assets/images/ltcIcon.png")} alt="" className="img-fluid" /> Litecoin</h3>
                            <p><span>20-05-2021 15:15</span> <span className="textDepositGreen">5.25%</span> <span>Auto</span></p>
                          </div>
                          <div className="csRight">
                            <h4>150.25487825 LTC</h4>
                            <button className="btn btn-outline text-uppercase py-1 m-0">Redeem Now</button>
                          </div>
                        </li>
                      </ul>
                    </Scrollbars>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className="dashboard_box">
                <div className="table-responsive stakingCurrencyList">
                  <table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Coin</th>
                        <th>7-Day APY</th>
                        <th>Yesterday's Flexbile APY</th>
                        <th>Flexible Interest Per Thousand</th>
                        <th>Auto Transfer</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><img src={require("../assets/images/btcIcon.png")} alt="" className="img-fluid" /> Bitcoin</td>
                        <td className="textDepositGreen">4.25%</td>
                        <td>3.12%</td>
                        <td>0.00000520 BTC</td>
                        <td>
                          <Switch
                              checked={state.checkedB}
                              onChange={handleChange}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </td>
                        <td><button className="btn btn-primary text-uppercase py-2 m-0" data-toggle="modal" data-target="#stakingModal">Stake Now</button></td>
                      </tr>
                      <tr>
                        <td><img src={require("../assets/images/ethIcon.png")} alt="" className="img-fluid" /> Ethereum</td>
                        <td className="textDepositGreen">4.25%</td>
                        <td>3.12%</td>
                        <td>0.00000520 BTC</td>
                        <td>
                          <Switch
                              checked={state.checkedB}
                              onChange={handleChange}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </td>
                        <td><button className="btn btn-primary text-uppercase py-2 m-0" data-toggle="modal" data-target="#stakingModal">Stake Now</button></td>
                      </tr>
                      <tr>
                        <td><img src={require("../assets/images/usdtIcon.png")} alt="" className="img-fluid" /> TetherCoin</td>
                        <td className="textDepositGreen">4.25%</td>
                        <td>3.12%</td>
                        <td>0.00000520 BTC</td>
                        <td>
                          <Switch
                              checked={state.checkedB}
                              onChange={handleChange}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </td>
                        <td><button className="btn btn-primary text-uppercase py-2 m-0" data-toggle="modal" data-target="#stakingModal">Stake Now</button></td>
                      </tr>
                      <tr>
                        <td><img src={require("../assets/images/xrpIcon.png")} alt="" className="img-fluid" /> Ripple</td>
                        <td className="textDepositGreen">4.25%</td>
                        <td>3.12%</td>
                        <td>0.00000520 BTC</td>
                        <td>
                          <Switch
                              checked={state.checkedB}
                              onChange={handleChange}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </td>
                        <td><button className="btn btn-primary text-uppercase py-2 m-0" data-toggle="modal" data-target="#stakingModal">Stake Now</button></td>
                      </tr>
                      <tr>
                        <td><img src={require("../assets/images/ltcIcon.png")} alt="" className="img-fluid" /> Litecoin</td>
                        <td className="textDepositGreen">4.25%</td>
                        <td>3.12%</td>
                        <td>0.00000520 BTC</td>
                        <td>
                          <Switch
                              checked={state.checkedB}
                              onChange={handleChange}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </td>
                        <td><button className="btn btn-primary text-uppercase py-2 m-0" data-toggle="modal" data-target="#stakingModal">Stake Now</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-12">
                  <h3 class="dash_title mb-3">Staking History</h3>
                </div>
              </div>
              <div className="dashboard_box stakingHistoryTable boxSpace">
                <div className="newUsersFilter contact_form settingsSelect mb-0">
                  <div className="newsSelectGroup">
                    <label>Filter by</label>
                    <Select value="0">
                        <MenuItem value={0}>Bitcoin</MenuItem>
                        <MenuItem value={20}>Ethereum</MenuItem>
                        <MenuItem value={30}>Ripple</MenuItem>
                        <MenuItem value={40}>Litecoin</MenuItem>
                        <MenuItem value={50}>TetherCoin</MenuItem>
                      </Select>
                      <div className="tableSearchBox">
                        <div class="input-group">
                          <input type="text" class="form-control" placeholder="Search by Date / Amount" />
                          <div class="input-group-append">
                            <span class="btnType1"><i class="fas fa-search"></i></span>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="newsSelectGroup">
                    <button className="btn btn-outline text-uppercase py-1 m-0">Download PDF</button>
                  </div>
                </div>
                <DataTable columns={stakingHistoryColumns} data={stakingHistory} noHeader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
