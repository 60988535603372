import React, { useEffect, useState } from "react";
// @material-ui/core components
import { Link, useLocation } from "react-router-dom";
import Header from "components/Header/Header.js";
import Checkbox from "rc-checkbox";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from "@material-ui/core/Switch";
import { Scrollbars } from "react-custom-scrollbars";
import DataTable from "react-data-table-component";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import ReactHtmlParser from "react-html-parser";

// action
import { getCms } from "actions/commonAction";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Cms(props) {
  const { ...rest } = props;

  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [cms, setCms] = useState({});

  const getCmsData = async () => {
    try {
      let identifier = location.pathname.split("/")[1];
      let data = { identifier };
      let { status, result, message } = await getCms(data);
      setLoader(false);
      if (status) {
        setCms(result);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getCmsData();
  }, []);

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header
            className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={
              <img
                src={require("../assets/images/logo.png")}
                alt="logo"
                className="img-fluid"
              />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest}
          />
          <div className="dashboardContent userPages">
            <div className="container">
              {loader && (
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="text-center">
                    <i className="fas fa-spinner fa-spin"></i>
                  </div>
                </GridItem>
              )}
              {!loader && Object.keys(cms).length > 0 && (
                <>
                  <GridContainer>
                    <GridItem md={12} className="text-center">
                      <h2 className="main_title">{ cms.title }</h2>
                    </GridItem>
                  </GridContainer>

                  <div className="supporTicketAccordian faq_accodion wow fadeIn">
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        { ReactHtmlParser(cms.content) }
                      </GridItem>
                    </GridContainer>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}