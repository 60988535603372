// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {};
    let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}/g;


    if (isEmpty(value.oldPassword)) {
        errors.oldPassword = "Old Password Required"
    }
    if (isEmpty(value.password)) {
        errors.password = "New Password Required"
    } else if (!(passwordRegex.test(value.password))) {
        errors.password = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18"
    }else if (value.password.length > 18) {
        errors.password = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18"
    }

    if(value.oldPassword == value.password){
        errors.password = "Please enter new password"
    }


    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "Confirm Password Required"
    } else if (value.password != value.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password is Mismatching"
    }
    

    return errors;
}

export default validation;
