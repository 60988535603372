import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Link, useParams, useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTooltip from "react-tooltip";
import Switch from "@material-ui/core/Switch";
import LastLogin from "../Dashboard/LastLogin";

import CheckboxNew from "./Checkbox";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { toastAlert } from "../../lib/toastAlert";

// import action
import { getKycDetail, setUserKyc } from "../../actions/userKyc";
import { getAssetData } from "../../actions/walletAction";
import { getCurrency, setCurrencyOption } from "../../actions/commonAction";
import {
  buyConfirmP2PTrade,
  buyP2PTrade,
  getLastAd,
  getP2PPairList,
  updateTrade,
  cancelMyadAction,
  getBuyAdData,
  getSellAdData,
  getSarchBuyData,
  getSarchSellData,
  updatevacation,
} from "../../actions/p2pAction";

// import lib
import { toFixed } from "../../lib/roundOf";

import config from "../../config";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  currencyselected: "USDT",
  posttocurrency: "USD",
  postprefcurrency: [],
  transMode: [],
  postminlimit: "",
  postmaxlimit: "",
  posttooptions: [],
  postcheckboxaccept: false,
  BuyorSell: "Buy",
  quantity: 0,
  price: "",
  postId: "",
  secondamount: "",
};

const initialBuyOrder = {
  buyorderPrice: 0,
  sellerEmail: "",
  paymentTimelimit: "",
  crypto_amount: 0,
  fiat_amount: 0,
  buyOrderId: "",
  buyQuantity: 0,
  buyFiatAmount: 0,
  buytransferMode: "",
  buytoFiatCurrency: "",
  buytoCryptoCurrency: "",
};

export default function P2pBuySell(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [buyOrderValue, setBuyOrderValue] = useState(initialBuyOrder);
  const [userId, setUserId] = useState("");
  const [userBalance, setUserBalance] = useState(0);
  const [priceCNV, setPriceCNV] = useState({}); // Price Conversion Data
  const [balLoader, setBalLoader] = useState(false); // Balance Loader
  const [estLoader, setEstLoader] = useState(false); // Estimated Loader
  const [spotpairsdata, setSpotpairsdata] = useState([]);
  const [validateError, setValidateError] = useState({});
  const [currencyDetails, setCurrencyDetails] = useState([]);
  const [cryptocurrency, setCryptocurrency] = useState("");
  const [maxbuy, setMaxbuy] = useState("");
  const [buytocurrency, setBuytocurrency] = useState("INR");
  const [buyads, setBuyads] = useState([]);
  const [sellads, setSellads] = useState([]);
  const [buyLoader, setBuyLoader] = useState(false);
  const [sellLoader, setSellLoader] = useState(false);
  const [buyAddDetails, setBuyAddDetails] = useState({});
  const [sellAddDetails, setSellAddDetails] = useState({});
  const [fiatAmount, setFiatAmount] = useState("");
  const [cryptoAmount, setCryptoAmount] = useState("");
  const { isAuth } = useSelector((state) => state.auth);
  const [offset, setOffset] = useState(0);
  const [buyTotalCount, setBuyTotalCount] = useState(0);
  const [sellTotalCount, setSellTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [recentAdDetails, setRecentAdDetails] = useState([]);
  const [recentLoader, setRecentLoader] = useState(false);
  const [upiPrimary, setUpiPrimary] = useState("");
  const [bankPrimary, setBankPrimary] = useState("");
  const [gpayPrimary, setGPayPrimary] = useState("");
  const [posttradeprice, setPosttradeprice] = useState(0);
  const [buy_vac, setbuy_vac] = useState(false);
  const [sell_vac, setsell_vac] = useState(false);

  const pageSize = 5;
  const {
    buytoFiatCurrency,
    buytoCryptoCurrency,
    buyFiatAmount,
    buytransferMode,
    buyorderPrice,
    sellerEmail,
    paymentTimelimit,
    crypto_amount,
    fiat_amount,
    buyOrderId,
    buyQuantity,
  } = buyOrderValue;

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    var curprice;
    if (name === "quantity") {
      curprice = toFixed(price * value, 2);
      setPosttradeprice(curprice);
    }
    var error;
    if (name === "price") {
      curprice = toFixed(quantity * value, 2);
      setPosttradeprice(curprice);
      error = { ...validateError, ...{ postprice: "" } };
    } else {
      error = { ...validateError, ...{ [name]: "" } };
    }
    setValidateError(error);
  };

  const { ...rest } = props;

  const {
    currencyselected,
    posttocurrency,
    postprefcurrency,
    transMode,
    postminlimit,
    postmaxlimit,
    posttooptions,
    postcheckboxaccept,
    BuyorSell,
    quantity,
    price,
    postId,
    secondamount,
  } = formValue;

  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const currencyData = useSelector((state) => state.currency);
  const authDetails = useSelector((state) => state.auth);
  const priceConversion = useSelector((state) => state.priceConversion);
  const userKyc = useSelector((state) => state.userKyc);
  const upiData = useSelector((state) => state.upiDetail);
  const bankData = useSelector((state) => state.bankDetail);
  const qrData = useSelector((state) => state.qrCodeDetail);
  const userSetting = useSelector((state) => state.userSetting);

  //  Handle on change
  const handleposttocurrency = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const getcurrencyDetails = async () => {
    try {
      const { result, status, loading } = await getCurrency(dispatch);
    } catch (err) {}
  };

  const p2pSpotPair = async () => {
    try {
      const { result, status, loading } = await getP2PPairList(dispatch);
      if (result) {
        setSpotpairsdata(result);
        setCryptocurrency(result[0].first_currency);
      }
    } catch (err) {}
  };

  const getRecentAd = async (user) => {
    try {
      let reqData = { curUser: user };
      setRecentLoader(true);
      let { result, loading, message, error } = await getLastAd(reqData);
      setRecentLoader(loading);
      setRecentAdDetails(result);
    } catch (err) {}
  };

  const getbuyads = async (userId, crypto, fiat, transferMode, fiatAmount) => {
    var postdata = {
      type: "Sell",
      userId,
      crypto,
      fiat,
      transferMode,
      fiatAmount,
      limit: pageSize,
      offset: offset,
    };
    setLoader(true);
    setBuyLoader(true);
    let { result, loading, total_count, message, error } = await getBuyAdData(
      postdata
    );
    setLoader(loading);
    setBuyLoader(loading);
    if (result) {
      setBuyTotalCount(total_count);
      offset === 0 ? setBuyads(result) : setBuyads([...buyads, ...result]);
    }
  };

  const getsellads = async (userId, crypto, fiat, transferMode, fiatAmount) => {
    var postdata = {
      type: "Buy",
      userId,
      crypto,
      fiat,
      transferMode,
      fiatAmount,
      limit: pageSize,
      offset: offset,
    };
    setLoader(true);
    setSellLoader(true);
    let { result, loading, total_count, message, error } = await getSellAdData(
      postdata
    );
    setLoader(loading);
    setSellLoader(loading);
    if (result) {
      setSellTotalCount(total_count);
      offset === 0 ? setSellads(result) : setSellads([...sellads, ...result]);
    }
  };

  useEffect(() => {
    // assetBalance();
    getcurrencyDetails();
    p2pSpotPair();
  }, []);

  useEffect(() => {
    setBalLoader(true);
    console.log(walletData, "--------walletData");
    if (walletData && walletData.length > 0) {
      var wallet = walletData.find((x) => x.currencySymbol === cryptocurrency);
      if (wallet) {
        setUserBalance(wallet);
        setBalLoader(false);
      }
    }
  }, [walletData, cryptocurrency]);

  useEffect(() => {
    if (userSetting) {
      setbuy_vac(userSetting.buy_vacation);
      setsell_vac(userSetting.sell_vacation);
    }
  }, [userSetting]);

  useEffect(() => {
    setEstLoader(true);
    if (
      posttocurrency &&
      priceConversion &&
      priceConversion.length > 0 &&
      cryptocurrency
    ) {
      let CNVPriceData = priceConversion.find(
        (el) =>
          el.baseSymbol === cryptocurrency &&
          el.convertSymbol === posttocurrency
      );
      if (CNVPriceData) {
        setPriceCNV(CNVPriceData);
        setEstLoader(false);
      } else {
        setEstLoader(false);
      }
    } else {
      setEstLoader(false);
    }
  }, [posttocurrency, priceConversion, cryptocurrency]);

  useEffect(() => {
    if (authDetails.userId) {
      setUserId(authDetails.userId);
      getRecentAd(authDetails.userId);
    }

    if (cryptocurrency) {
      getbuyads(userId, cryptocurrency);
      getsellads(userId, cryptocurrency);
    }
  }, [cryptocurrency, authDetails, offset]);

  const updateMyad = async (e) => {
    window.$("#edit_post").modal("show");
    if (e && e != undefined) {
      var totprice = e.maxlimit / e.quantity;
      let formData = {
        ...formValue,
        ...{
          transMode: e.transferMode,
          postminlimit: e.minlimit,
          postmaxlimit: e.maxlimit,
          price: totprice,
          quantity: e.quantity,
          postId: e._id,
          currencyselected: e.firstCurrency,
          BuyorSell: e.BuyorSell,
        },
      };
      setPosttradeprice(e.maxlimit);
      setFormValue(formData);
    }
  };

  const cancelMyad = async (e) => {
    window.$("#cancel_post").modal("show");
    if (e && e !== undefined) {
      let formData = {
        ...formValue,
        ...{
          transMode: e.transferMode,
          postminlimit: e.minlimit,
          postmaxlimit: e.maxlimit,
          price: e.price,
          postId: e._id,
          currencyselected: e.firstCurrency,
          BuyorSell: e.BuyorSell,
        },
      };
      setFormValue(formData);
    }
  };

  const updatePost = async (e) => {
    e.preventDefault();
    let reqData = {
      type: BuyorSell,
      postId,
      userId: authDetails.userId,
      price,
      quantity,
      minlimit: postminlimit,
      maxlimit: posttradeprice,
      postprefcurrency: transMode,
      bankPrimary,
      upiPrimary,
      gpayPrimary,
    };
    let { status, loading, message, error } = await updateTrade(
      reqData,
      dispatch
    );
    if (status == "success") {
      window.$("#edit_post").modal("hide");
      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      getRecentAd(authDetails.userId);
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  const cancelPost = async (e) => {
    e.preventDefault();
    let reqData = {
      postId: postId,
      userId: authDetails.userId,
      price: price,
      minlimit: postminlimit,
      maxlimit: postmaxlimit,
      postprefcurrency: transMode,
    };

    let { status, message, error } = await cancelMyadAction(reqData, dispatch);
    if (status === "success") {
      window.$("#cancel_post").modal("hide");
      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      getRecentAd(authDetails.userId);
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  const handlepostprefcurrency = (e) => {
    const { name, value, checked } = e.target;
    let checkedValues = postprefcurrency;
    if (checked) checkedValues = checkedValues.concat(value);
    else
      checkedValues = postprefcurrency.filter((e) => {
        return e !== value;
      });
    let formData = { ...formValue, ...{ [name]: checkedValues.sort() } };
    setFormValue(formData);
  };

  const handletransMode = (e) => {
    const { name, value, checked } = e.target;
    let checkedValues = transMode;
    if (checked) checkedValues = checkedValues.concat(value);
    else
      checkedValues = transMode.filter((e) => {
        return e !== value;
      });
    let formData = { ...formValue, ...{ [name]: checkedValues.sort() } };
    setFormValue(formData);
  };

  // Handle nav currency and buy sell changes
  const handlecurrenttab = async (e) => {
    setCryptocurrency(e.first_currency);
    setOffset(0);
    var curindex = currencyDetails.findIndex(
      (x) => x.currencySymbol === e.first_currency
    );
    if (curindex != -1) {
      setMaxbuy(currencyDetails[curindex].maxbuy);
    }
  };

  // Find ads use filter
  const searchfindAds = async (e) => {
    e.preventDefault();
    getbuyads(
      userId,
      cryptocurrency,
      posttocurrency,
      postprefcurrency,
      secondamount
    );
    getsellads(
      userId,
      cryptocurrency,
      posttocurrency,
      postprefcurrency,
      secondamount
    );
  };

  // Buy and sell ad
  const buySingleAd = async (e) => {
    if (!isAuth) {
      toastAlert("error", "Please Login");
      return;
    }
    if (
      (userKyc &&
        userKyc &&
        userKyc.addressProof &&
        userKyc.addressProof.status != "" &&
        userKyc.addressProof.status != "approved") ||
      (userKyc.idProof &&
        userKyc.idProof.status != "" &&
        userKyc.idProof.status != "approved")
    ) {
      toastAlert(
        "success",
        "KYC must be submitted & verified to Continue Trade"
      );
      history.push("/kyc");
    } else {
      setBuyAddDetails(e);

      if (e && e != undefined) {
        // setCurrentPost(e)
        let buyOrderData = {
          ...buyOrderValue,
          ...{
            postprefcurrency: e.transferMode,
            buytransferMode: e.transferMode,
            buypaymentdetail: e.paymentDetail,
            buyorderPrice: e.price,
            sellerEmail: e.user_info.email,
            buyQuantity: e.quantity,
            buytoFiatCurrency: e.firstCurrency,
            buytoCryptoCurrency: e.secondCurrency,
            crypto_amount: 0,
            fiat_amount: 0,
            buyOrderId: "",
          },
        };
        setBuyOrderValue(buyOrderData);
      }
      window.$("#BuyModel").modal("show");
      // if(e && e!= undefined){
      //   // setCurrentPost(e)
      //   let formData = { ...formValue, ...{ "postprefcurrency":e.transferMode,
      //   "postminlimit":e.minlimit,
      //   "postmaxlimit":e.maxlimit,
      //   "price":e.price,
      //   "postId": e._id,
      //   "currencyselected":e.firstCurrency,
      //   "BuyorSell":e.BuyorSell,
      //  } }
      //   setFormValue(formData)
      // }
    }
  };
  const handlecrypto = async (e, type) => {
    var fiat_amount = e.currentTarget.value;

    // fiat amount should not be more than 2 decimals
    if (!new RegExp(`^\\d*(\\.\\d{0,2})?$`).test(fiat_amount)) {
      return;
    }

    if (type == "buy") {
      var crypto_quantity = buyAddDetails.maxlimit;
      var fiat_price = buyAddDetails.quantity;
    } else {
      var crypto_quantity = sellAddDetails.maxlimit;
      var fiat_price = sellAddDetails.quantity;
    }
    var one = crypto_quantity / fiat_price;
    var crypto_amount = parseFloat(toFixed(fiat_amount / one, 8));
    setFiatAmount(fiat_amount);
    setCryptoAmount(crypto_amount);
  };

  const handlebuycrypto = async (e) => {
    e.preventDefault();
    let id = buyAddDetails && buyAddDetails._id;
    let crypto_amount = cryptoAmount;
    let fiat_amount = fiatAmount;
    let reqData = {
      _id: id,
      userid: userId,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
    };
    let { status, result, message, error } = await buyP2PTrade(
      reqData,
      dispatch
    );
    if (message) {
      toastAlert("error", message, "login");
    } else {
      handleConfirmbuycrypto();
      window.$("#BuyModel").modal("hide");
    }
  };
  const handleConfirmbuycrypto = async (e) => {
    let id = buyAddDetails && buyAddDetails._id;
    let crypto_amount = cryptoAmount;
    // let total_fiat = this.state.total_fiat;
    let fiat_amount = fiatAmount;
    let BuyorSell = "Buy";

    let reqData = {
      _id: id,
      userid: userId,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
      // total_fiat: total_fiat,
      BuyorSell: BuyorSell,
    };

    
    let { status, result, loading, message, error } = await buyConfirmP2PTrade(
      reqData,
      dispatch
    );
    if (status) {
      toastAlert("success", message, "login");
      history.push("/Chat/" + result);
    } else {
      // handleConfirmbuycrypto();
      // this.props.history.push('/WalletDepositWithdrawCrypto?coinsend='+"BTC")
      // history.push('/spot/' + pair)
      // history.push('/Chat')
    }
  };

  // Sell process
  // Buy and sell ad
  const sellSingleAd = async (e) => {
    if (!isAuth) {
      toastAlert("error", "Please Login");
      return;
    }
    if (
      (userKyc &&
        userKyc &&
        userKyc.addressProof &&
        userKyc.addressProof.status != "" &&
        userKyc.addressProof.status != "approved") ||
      (userKyc.idProof &&
        userKyc.idProof.status != "" &&
        userKyc.idProof.status != "approved")
    ) {
      toastAlert(
        "success",
        "KYC must be submitted & verified to Continue Trade"
      );
      history.push("/kyc");
    } else {
      if (e && e != undefined) {
        setSellAddDetails(e);
        // setCurrentPost(e)
      }
      window.$("#SellModel").modal("show");
    }
  };

  const handlesellcrypto = async (e) => {
    e.preventDefault();
    let id = sellAddDetails && sellAddDetails._id;
    let crypto_amount = cryptoAmount;
    let fiat_amount = fiatAmount;
    let reqData = {
      _id: id,
      userid: userId,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
    };
    let { status, result, message, error } = await buyP2PTrade(
      reqData,
      dispatch
    );
    if (message) {
      toastAlert("error", message, "login");
    } else {
      handleConfirmsellcrypto();
      window.$("#SellModel").modal("hide");
    }
  };
  const handleConfirmsellcrypto = async (e) => {
    let id = sellAddDetails && sellAddDetails._id;
    let crypto_amount = cryptoAmount;
    // let total_fiat = this.state.total_fiat;
    let fiat_amount = fiatAmount;
    let BuyorSell = "Sell";

    let reqData = {
      _id: id,
      userid: userId,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
      // total_fiat: total_fiat,
      BuyorSell: BuyorSell,
    };

    let { status, result, message, error } = await buyConfirmP2PTrade(
      reqData,
      dispatch
    );
    console.log("status----", status);
    if (status) {
      toastAlert("success", message, "login");
      history.push("/Chat/" + result);
    } else {
      toastAlert("error", message, "login");
      // handleConfirmbuycrypto();
      // this.props.history.push('/WalletDepositWithdrawCrypto?coinsend='+"BTC")
      // history.push('/spot/' + pair)

      // history.push('/Chat')
    }
  };

  const refreshAds = async () => {
    window.location.reload();
    // setOffset(0);
    // setFormValue({
    //   posttocurrency: "USD",
    //   postprefcurrency: [],
    //   secondamount: "",
    // });
    // getbuyads(userId, cryptocurrency);
    // getsellads(userId, cryptocurrency);
  };

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (bankData && bankData.result) {
      bankData.result.map((item, key) => {
        if (item.isPrimary === true) {
          setBankPrimary(item);
          return false;
        }
        return true;
      });
    }
  }, [bankData]);

  const handleCheckBox_sell = async (e) => {
    const { name, checked } = e.target;
    setsell_vac(checked);
    await Vacationupdate("sell", checked);
  };

  const handleCheckBox_buy = async (e) => {
    const { name, checked } = e.target;
    setbuy_vac(checked);
    await Vacationupdate("buy", checked);
  };

  const Vacationupdate = async (type, checked) => {
    var postdata = {
      userid: userId,
      sell_vacation: checked,
      buy_vacation: checked,
      type: type,
    };

    let { result, loading, message, error } = await updatevacation(postdata);
    if (result) {
      toastAlert("success", "Vacation Updated successfully", "login");
    }
    // axios.post(url + "tradeapi/getsellads", postdata).then((res) => {
    //   this.setState({ sellads: res.data.tradedata });
    // });
  };

  const closepopup = async (e) => {
    setFiatAmount("");
    setCryptoAmount("");
  };

  useEffect(() => {
    if (upiData && upiData.result && upiData.result.length > 0) {
      upiData.result.map((item, key) => {
        if (item.isPrimary === true) {
          setUpiPrimary(item.upiId);
          return false;
        }
        return true;
      });
    }
  }, [upiData]);
  

  useEffect(() => {
    if (qrData && qrData.result) {
      qrData.result.map((item, key) => {
        if (item.isPrimary === true) {
          setGPayPrimary(item);
          return false;
        }
        return true;
      });
    }

    if(currencyData && currencyData.length > 0){
      let currencyCheck = currencyData.find((el) => (el.currencySymbol == posttocurrency) )
      console.log(currencyCheck,"currencyCheck")
      
      if(currencyCheck == undefined){
       let dataCurrency = currencyData.find((el) => el.type == 'fiat')
       if(dataCurrency != undefined){
        let formData = { ...formValue, ...{ 'posttocurrency': dataCurrency.currencySymbol } };
        setFormValue(formData);
       }
        
      }

    }
  }, [qrData]);

  return (
    // <div className="dashboard_container page_wrap">
    //   <ScrollToTopOnMount />
    <div className="dashboardMain">
      <div className="dashboardRight afterLoginHeader">
        <Header
          className="header"
          color="transparent"
          routes={dashboardRoutes}
          brand={
            <img
              src={require("../../assets/images/logo.png")}
              alt="logo"
              className="img-fluid"
            />
          }
          rightLinks={<HeaderLinksAfterlogin />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "dark",
          }}
          {...rest}
        />
        <div className="dashboardContent userPages peer2peer_section">
          <div className="container">
            <GridContainer>
              <GridItem xs={12} sm={12} md={5} lg={5}>
                <h3 className="dash_title">Peer-to-Peer</h3>
              </GridItem>
              <GridItem xs={12} sm={12} md={7} lg={7}>
                <LastLogin />
                {/* <ul className="profile_dash">
                    <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li>
                    <li>13-05-2021  15:15, Chrome, 182.254.127  - <span>Last login</span></li>
                  </ul> */}
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="dashboard_box">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                      <div className="balance_details_panel pb-0">
                        {authDetails.isAuth && (
                          <div className="balance_details_left">
                            <h5 className="dash_subtitle paddin_title_p">
                              Estimated P2P Balance
                            </h5>
                            <div className="flexDashBalance">
                              <div className="mr-5">
                                <h3>Total Account Balance</h3>
                                {balLoader && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                                {!balLoader && (
                                  <h2 className="balance_color_dash">
                                    {parseFloat(
                                      toFixed(userBalance.p2pbalance, 8)
                                    )}{" "}
                                    <span>{cryptocurrency}</span>
                                  </h2>
                                )}
                              </div>
                              <div>
                                <h3>Estimated Value:</h3>
                                {(estLoader || balLoader) && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                                {!estLoader && !balLoader && (
                                  <h4>
                                    {posttocurrency}{" "}
                                    {toFixed(
                                      userBalance.p2pbalance *
                                        priceCNV.convertPrice,
                                      2
                                    )}
                                  </h4>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="balance_details_right aligncontans_cen">
                          <div className="chartDash">
                            <img
                              src={require("../../assets/images/p2pimg.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                      <div class="peer_clas_parant">
                        <div className="peer2peer_contant_first">
                          <h1>Tradax P2P BUY/SELL YOUR CRYPTO LOCALLY</h1>
                          <p>
                            Peer-to-peer exchange (or P2P exchange) is a
                            marketplace where people can trade crypto directly
                            with each other on their own terms, in virtually any
                            country.
                          </p>
                          {isAuth && (
                            <>
                              <a href="/postmyad">
                                {" "}
                                <button>Post new Order</button>
                              </a>
                              <a href="/myads">
                                {" "}
                                <button>My Orders</button>
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </GridItem>
                    {authDetails.isAuth && recentLoader && (
                      <i className="fas fa-spinner fa-spin d-none"></i>
                    )}
                    {authDetails.isAuth && (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className="recentad_section">
                          <div className="recent_title">
                            <h3>Recent Post Ad</h3>
                          </div>
                          {recentLoader && (
                            <i className="fas fa-spinner fa-spin"></i>
                          )}
                          {!recentLoader && recentAdDetails && (
                            <>
                              <div className="Post_id">
                                <p>
                                  <span>Pair:</span> {recentAdDetails.pair}
                                </p>
                              </div>
                              <div className="Posted_date">
                                <p>
                                  <span>Posted on:</span>{" "}
                                  {moment(recentAdDetails.orderDate).format(
                                    "DD MMM YYYY hh:mm"
                                  )}
                                </p>
                              </div>
                              <div className="Sell_section">
                                <p>
                                  <span>{recentAdDetails.BuyorSell}:</span>{" "}
                                  {recentAdDetails.secondCurrency}{" "}
                                  {recentAdDetails.price} |{" "}
                                  {parseFloat(
                                    toFixed(recentAdDetails.quantity, 8)
                                  )}{" "}
                                  {recentAdDetails.firstCurrency}
                                </p>
                              </div>
                              <div className="Sell_section">
                                <p>
                                  {recentAdDetails.transferMode &&
                                    recentAdDetails.transferMode.join(", ")}
                                </p>
                              </div>
                              <div className="link_section">
                                {recentAdDetails.status === "0" && (
                                  <>
                                    <p>
                                      [
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          updateMyad(recentAdDetails)
                                        }
                                      >
                                        Edit My Post
                                      </a>
                                      ]
                                    </p>
                                    <p>
                                      [
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          cancelMyad(recentAdDetails)
                                        }
                                      >
                                        Cancel My Post
                                      </a>
                                      ]
                                    </p>
                                  </>
                                )}
                                {(recentAdDetails.status === "2" ||
                                  recentAdDetails.status === "5") && (
                                  <span className="textStatusGreen">
                                    Trade In Process
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                          {!recentLoader && !recentAdDetails && (
                            <>
                              <div className="Post_id">
                                <p>There is no recent order found.</p>
                              </div>
                              <div className="Posted_date"></div>
                              <div className="Sell_section"></div>
                              <div className="Sell_section"></div>
                              <div className="link_section"></div>
                            </>
                          )}
                        </div>

                        {/* <!-- Modal --> */}
                        <div
                          class="modal fade buy_sellaction confirm_sellaction modalzindex"
                          id="edit_post"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="edit_post"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div class="modal-content">
                              <div class="modal-body">
                                <div className="confirm_body ">
                                  <h2>Edit My Post</h2>
                                  <img
                                    src={require("../../assets/images/postconfirm.png")}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <div className="model_detail_text">
                                    <div className="detail_text_model">
                                      <span className="righttext">Type:</span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">
                                        {BuyorSell}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Cryptocurrency:
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">
                                        {currencyselected}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Quantity:
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="quantity"
                                        value={quantity}
                                        onChange={handleChange}
                                        onInput={validPositive}
                                      />
                                      {validateError.quantity && (
                                        <p className="error-message">
                                          {validateError.quantity}
                                        </p>
                                      )}
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">Price:</span>
                                    </div>
                                    <div className="detail_text_model">
                                      <input
                                        type="number"
                                        class="form-control"
                                        name="price"
                                        value={price}
                                        onChange={handleChange}
                                        onInput={validPositive}
                                      />
                                      {validateError.price && (
                                        <p className="error-message">
                                          {validateError.price}
                                        </p>
                                      )}
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Price Limit From:{" "}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <input
                                        type="number"
                                        class="form-control"
                                        name="postminlimit"
                                        value={postminlimit}
                                        onChange={handleChange}
                                        onInput={validPositive}
                                      />
                                      {validateError.postminlimit && (
                                        <p className="error-message">
                                          {validateError.postminlimit}
                                        </p>
                                      )}
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Price Limit To:{" "}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">
                                        <input
                                          type="number"
                                          class="form-control"
                                          name="postmaxlimit"
                                          value={posttradeprice}
                                          onChange={handleChange}
                                          disabled
                                        />
                                      </span>
                                      {validateError.postmaxlimit && (
                                        <p className="error-message">
                                          {validateError.postmaxlimit}
                                        </p>
                                      )}
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Preferred Payments:{" "}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <input
                                        type="checkbox"
                                        value="Bank"
                                        id="bank"
                                        name="transMode"
                                        onChange={handletransMode}
                                        checked={transMode.some(
                                          (e) => e === "Bank"
                                        )}
                                      />
                                      <label
                                        htmlFor="bank"
                                        className="padin_s_checknox"
                                      >
                                        Bank
                                      </label>
                                      <input
                                        type="checkbox"
                                        value="Gpay"
                                        id="gpay"
                                        name="transMode"
                                        onChange={handletransMode}
                                        checked={transMode.some(
                                          (e) => e === "Gpay"
                                        )}
                                      />
                                      <label
                                        htmlFor="gpay"
                                        className="padin_s_checknox"
                                      >
                                        Gpay
                                      </label>
                                      <input
                                        type="checkbox"
                                        value="UPI"
                                        id="upi"
                                        name="transMode"
                                        onChange={handletransMode}
                                        checked={transMode.some(
                                          (e) => e === "UPI"
                                        )}
                                      />
                                      <label
                                        htmlFor="upi"
                                        className="padin_s_checknox"
                                      >
                                        UPI
                                      </label>
                                      {validateError.postprefcurrency && (
                                        <p className="error-message">
                                          {validateError.postprefcurrency}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="new_confirm_pay_button mat_205">
                                    <button
                                      className="button1 button2"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      onClick={updatePost}
                                      className="button1"
                                    >
                                      Submit Post
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="modal fade buy_sellaction confirm_sellaction modalzindex"
                          id="cancel_post"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="cancel_post"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="confirm_body ">
                                  <h2>Cancel My Post</h2>
                                  <img
                                    src={require("../../assets/images/postconfirm.png")}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <div className="model_detail_text">
                                    <div className="detail_text_model">
                                      <span className="righttext">Type:</span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">
                                        {BuyorSell}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Cryptocurrency:
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">
                                        {currencyselected}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">Price:</span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">{price}</span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Price Limit From:{" "}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">
                                        {postminlimit}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Price Limit To:{" "}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">
                                        {postmaxlimit}{" "}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="righttext">
                                        Preferred Payments:{" "}
                                      </span>
                                    </div>
                                    <div className="detail_text_model">
                                      <span className="colo_fff">
                                        {transMode.join(", ")}{" "}
                                      </span>
                                    </div>
                                  </div>

                                  <h4>Are you sure want to cancel the Ad?</h4>

                                  <div className="new_confirm_pay_button mat_205">
                                    <button
                                      className="button1 button2"
                                      data-dismiss="modal"
                                    >
                                      No{" "}
                                    </button>
                                    <button
                                      className="button1"
                                      onClick={cancelPost}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                    )}

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <h5 className="dash_subtitle paddin_title_p mar-ledf_dd ">
                        Selling Vacation
                      </h5>
                      <div className="recentad_section flex_recent_section">
                        <div className="recent_title">
                          <h3>Selling Vacation</h3>
                        </div>
                        <div className="widthe_100_s">
                          {/* <CheckboxNew />   */}
                          <FormControlLabel
                            value="end"
                            control={<Checkbox color="primary" />}
                            label="Disable all your advertisment for sell temporarily"
                            labelPlacement="end"
                            className="checkbox_p2p"
                            checked={sell_vac}
                            onChange={handleCheckBox_sell}
                          />
                        </div>
                        <div className="recent_title">
                          <h3>Buying Vacation</h3>
                        </div>
                        <div className="widthe_100_s">
                          {/* <CheckboxNew />   */}
                          <FormControlLabel
                            value="end"
                            control={<Checkbox color="primary" />}
                            label="Disable all your advertisment for buy temporarily"
                            labelPlacement="end"
                            className="checkbox_p2p"
                            checked={buy_vac}
                            onChange={handleCheckBox_buy}
                          />
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              </GridItem>
            </GridContainer>
            <div className="dashboard_box dashHistoryTable onlytab_bordernone">
              <nav>
                <div
                  class="nav nav-tabs primaryNav"
                  id="nav-tab"
                  role="tablist"
                >
                  {spotpairsdata.length > 0 &&
                    spotpairsdata.map((item, key) => {
                      return (
                        <a
                          className={`nav-item nav-link ${
                            key === 0 ? "active" : ""
                          }`}
                          onClick={() => {
                            handlecurrenttab(item);
                          }}
                          id="nav-content-tab"
                          data-toggle="tab"
                          href="#nav-content"
                          role="tab"
                          aria-controls="nav-content"
                          aria-selected="true"
                          key={key}
                        >
                          {item.first_currency}
                        </a>
                      );
                    })}
                </div>
              </nav>
              <div className="boxSpace pt-1">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-recentTransaction"
                    role="tabpanel"
                    aria-labelledby="nav-recentTransaction-tab"
                  >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className="recentad_section">
                          <form className="contact_form mb-0 w100_form_p2p">
                            <div className="settingsSelect clas_Newselect clas_Newselect124">
                              <GridContainer>
                                <GridItem xs={12} sm={7} md={6} lg={4}>
                                  <div className="form-group d-flex_p2p flex-wrwp-mobile_1">
                                    <label className="label_color_78">
                                      Enter Amount
                                    </label>
                                    <div className="flot_fixe_ins">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="secondamount"
                                        value={secondamount}
                                        onChange={handleChange}
                                      />
                                      <Select
                                        name="posttocurrency"
                                        value={posttocurrency}
                                        onChange={handleposttocurrency}
                                      >
                                        {currencyData &&
                                          currencyData.length > 0 &&
                                          currencyData.map((item, key) => {
                                            console.log(item.currencySymbol,'item')
                                            if (item.type == "fiat") {
                                              return (
                                                <MenuItem
                                                  value={item.currencySymbol}
                                                >
                                                  {item.currencySymbol}
                                                </MenuItem>
                                              );
                                            }
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  {validateError.secondamount && (
                                    <p className="error-message">
                                      {validateError.secondamount}
                                    </p>
                                  )}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={5}>
                                  <div className="form-group d-flex_p2p flex-wrwp-mobile_1">
                                    <label className="label_color_78">
                                      Payments
                                    </label>
                                    <input
                                      type="checkbox"
                                      value="Bank"
                                      id="bank"
                                      name="postprefcurrency"
                                      onChange={handlepostprefcurrency}
                                      checked={postprefcurrency.some(
                                        (e) => e === "Bank"
                                      )}
                                    />
                                    <label htmlFor="bank">Bank</label>
                                    <input
                                      type="checkbox"
                                      value="Gpay"
                                      id="gpay"
                                      name="postprefcurrency"
                                      onChange={handlepostprefcurrency}
                                      checked={postprefcurrency.some(
                                        (e) => e === "Gpay"
                                      )}
                                    />
                                    <label htmlFor="gpay">Gpay</label>
                                    <input
                                      type="checkbox"
                                      value="UPI"
                                      id="upi"
                                      name="postprefcurrency"
                                      onChange={handlepostprefcurrency}
                                      checked={postprefcurrency.some(
                                        (e) => e === "UPI"
                                      )}
                                    />
                                    <label htmlFor="upi">UPI</label>
                                    <div className="peer2peer_contant_first ">
                                      <button
                                        className="buttont_poidf"
                                        onClick={searchfindAds}
                                      >
                                        Search
                                      </button>
                                    </div>
                                  </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={3}>
                                  <p className="refresh_color_78">
                                    <a
                                      onClick={refreshAds}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      className="refresh_color_78"
                                    >
                                      Refresh
                                    </a>
                                  </p>
                                </GridItem>
                              </GridContainer>
                            </div>
                          </form>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6} lg={6}>
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th colSpan="4" align="center">
                                  <p className="blew">Buy</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {buyLoader && (
                                <tr>
                                  <td colSpan="4" align="center">
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </td>
                                </tr>
                              )}
                              {!buyLoader &&
                                buyads.length > 0 &&
                                buyads.map((item, i) => {
                                  if (item.maxlimit > 0) {
                                    return (
                                      <tr>
                                        <td>
                                          <div className="buy_table_contant">
                                            <h4>{item.user_info.email}</h4>
                                            <p>
                                              {item.totalOrdersCount} orders,
                                              <br></br>
                                              {item.completionPercentage}%
                                              completion
                                            </p>
                                          </div>
                                        </td>
                                        <td className="balance_amt_detail">
                                          <p>
                                            <span className="mar-lrft0_rigt ">
                                              Price Per :{" "}
                                            </span>
                                            {item.price} {item.secondCurrency}
                                          </p>
                                          <p>
                                            <span className="mar-lrft0_rigt ">
                                              Total Quantity:
                                            </span>
                                            {parseFloat(
                                              toFixed(item.quantity, 8)
                                            )}{" "}
                                            {item.firstCurrency}
                                            <br></br>
                                            <span className="mar-lrft0_rigt ">
                                              Available Limit
                                            </span>
                                            {item.minlimit}{" "}
                                            {item.secondCurrency} -{" "}
                                            {item.maxlimit}{" "}
                                            {item.secondCurrency}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="clas_po_78">
                                            {item.transferMode.join(", ")}
                                          </p>
                                        </td>
                                        <td className="buy_button_op">
                                          <div>
                                            <button
                                              onClick={() => buySingleAd(item)}
                                            >
                                              Buy
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                })}
                              {!buyLoader && buyads.length == 0 && (
                                <tr>
                                  <td colSpan="4" align="center">
                                    Oops No Result Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        className="border_lefrt"
                      >
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th colSpan="4" align="center">
                                  <p className="blew sell_text_colo">Sell</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sellLoader && (
                                <tr>
                                  <td colSpan="4" align="center">
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </td>
                                </tr>
                              )}
                              {!sellLoader &&
                                sellads.length > 0 &&
                                sellads.map((item, i) => {
                                  if (item.maxlimit > 0) {
                                    return (
                                      <tr>
                                        <td>
                                          <div className="buy_table_contant">
                                            <h4>{item.user_info.email}</h4>
                                            <p>
                                              {item.totalOrdersCount} orders,
                                              <br></br>
                                              {item.completionPercentage}%
                                              completion
                                            </p>
                                          </div>
                                        </td>
                                        <td className="balance_amt_detail">
                                          <p>
                                            <span className="mar-lrft0_rigt ">
                                              Price Per :{" "}
                                            </span>
                                            {item.price} {item.secondCurrency}
                                          </p>
                                          <p>
                                            <span className="mar-lrft0_rigt ">
                                              Total Quantity:
                                            </span>
                                            {parseFloat(
                                              toFixed(item.quantity, 8)
                                            )}{" "}
                                            {item.firstCurrency}
                                            <br></br>
                                            <span className="mar-lrft0_rigt ">
                                              Available Limit :
                                            </span>
                                            {item.minlimit}{" "}
                                            {item.secondCurrency} -{" "}
                                            {item.maxlimit}{" "}
                                            {item.secondCurrency}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="clas_po_78">
                                            {item.transferMode.join(", ")}
                                          </p>
                                        </td>
                                        <td className="buy_button_op">
                                          <div>
                                            <button class="btn btn-outline-danger"
                                              onClick={() => sellSingleAd(item)}
                                            >
                                              Sell
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                })}
                              {!sellLoader && sellads.length == 0 && (
                                <tr>
                                  <td colSpan="4" align="center">
                                    Oops No Result Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-loginHistory"
                    role="tabpanel"
                    aria-labelledby="nav-loginHistory-tab"
                  ></div>
                </div>
              </div>
            </div>
            {(buyads.length < buyTotalCount ||
              sellads.length < sellTotalCount) && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="peer2peer_contant_first ">
                    {loader && (
                      <button>
                        <i className="fas fa-spinner fa-spin"></i>
                      </button>
                    )}
                    {!loader && (
                      <button onClick={() => setOffset(offset + pageSize)}>
                        Show More
                      </button>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="confirm_payment"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirm_payment"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="confirm_body">
                <h2>Confirm payment</h2>
                <img
                  src={require("../../assets/images/pulp.png")}
                  alt=""
                  className="img-fluid"
                />
                <p>Please confirm that payment has been made to the seller. </p>
                <p>Malicious clicks will lead to account frozen</p>
                <div className="new_confirm_pay_button">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button className="button1 ">Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction modalzindex"
        id="BuyModel"
        tabindex="-1"
        role="dialog"
        aria-labelledby="BuyModel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle blew">
                Buy
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closepopup()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={7}
                  className="grid_wh_padding"
                >
                  <div className="model_detail_text">
                    <div className="detail_text_model">
                      <span className="f-20 colo_er_opo">
                        {buyAddDetails &&
                          buyAddDetails.user_info &&
                          buyAddDetails.user_info.email}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {buyAddDetails.totalOrdersCount} orders,{" "}
                        {buyAddDetails.completionPercentage}% completion
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Price Per</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {buyAddDetails && buyAddDetails.price}{" "}
                        {buyAddDetails && buyAddDetails.secondCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Payment Time Limit</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">30 Minutes</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Available </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {buyAddDetails &&
                          parseFloat(toFixed(buyAddDetails.quantity, 8))}{" "}
                        {buyAddDetails && buyAddDetails.firstCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Buyer’s Payment</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {buyAddDetails &&
                          buyAddDetails.transferMode &&
                          buyAddDetails.transferMode.join(", ")}{" "}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Trade Limit </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {buyAddDetails && buyAddDetails.minlimit} -{" "}
                        {buyAddDetails && buyAddDetails.maxlimit}{" "}
                        {buyAddDetails && buyAddDetails.secondCurrency}
                      </span>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={5}
                  className="bor_lef_ds solo_padding "
                >
                  {/* <form className="contact_form mb-0"> */}
                  <div className="contact_form mb-0">
                    <div className="form-group">
                      <label className="color_whit_7 w100_form_p2p">
                        I want to pay{" "}
                      </label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          id="fiatAmount"
                          name="fiatAmount"
                          value={fiatAmount}
                          onChange={(e) => handlecrypto(e, "buy")}
                        />
                        <div class="input-group-append">
                          <button className="inpor_inside_buttons">
                            {buyAddDetails && buyAddDetails.secondCurrency}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="color_whit_7 ">I will receive</label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          name="cryptoAmount"
                          id="cryptoAmount"
                          value={cryptoAmount}
                          readOnly="true"
                        />
                        <div class="input-group-append">
                          <button className="inpor_inside_buttons">
                            {buyAddDetails && buyAddDetails.firstCurrency}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <button
                        className="btn btn-primary text-uppercase py-2 w-100"
                        onClick={handlebuycrypto}
                      >
                        Trade Request
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                </GridItem>
              </GridContainer>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => closepopup()}
              >
                Close
              </button>
              {/* <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Sell ad Modal --> */}
      <div
        class="modal fade buy_sellaction modalzindex"
        id="SellModel"
        tabindex="-1"
        role="dialog"
        aria-labelledby="SellModel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle blew">
                Sell
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closepopup()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={7}
                  className="grid_wh_padding"
                >
                  <div className="model_detail_text">
                    <div className="detail_text_model">
                      <span className="f-20 colo_er_opo">
                        {sellAddDetails &&
                          sellAddDetails.user_info &&
                          sellAddDetails.user_info.email}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {sellAddDetails.totalOrdersCount} orders,{" "}
                        {sellAddDetails.completionPercentage}% completion
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Price Per</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {(sellAddDetails && sellAddDetails.maxlimit) /
                          (sellAddDetails &&
                            parseFloat(
                              toFixed(sellAddDetails.quantity, 8)
                            ))}{" "}
                        {sellAddDetails && sellAddDetails.secondCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Payment Time Limit</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">30 Minutes</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Available </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {sellAddDetails && sellAddDetails.quantity}{" "}
                        {sellAddDetails && sellAddDetails.firstCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Seller’s Payment</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {sellAddDetails &&
                          sellAddDetails.transferMode &&
                          sellAddDetails.transferMode.join(", ")}{" "}
                      </span>
                    </div>
                    {/* <div className="detail_text_model">
                                  <span className="colo_er_opo">Payment Detail</span>
                                </div>
                                <div className="detail_text_model">
                                  <span className="color_whit_7">{sellAddDetails &&sellAddDetails.transferMode=='Gpay'?<img src={sellAddDetails.paymentDetail} height="100" width="100" />:sellAddDetails.paymentDetail} </span>
                                </div> */}
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Trade Limit</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        Min:{sellAddDetails && sellAddDetails.minlimit} - Max:
                        {sellAddDetails && sellAddDetails.maxlimit}{" "}
                      </span>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={5}
                  className="bor_lef_ds solo_padding "
                >
                  {/* <form className="contact_form mb-0"> */}
                  <div className="contact_form mb-0">
                    <div className="form-group">
                      <label className="color_whit_7 w100_form_p2p">
                        I will receive{" "}
                      </label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          id="fiatAmount"
                          name="fiatAmount"
                          value={fiatAmount}
                          onChange={(e) => handlecrypto(e, "sell")}
                        />
                        <div class="input-group-append">
                          <button className="inpor_inside_buttons">
                            {sellAddDetails && sellAddDetails.secondCurrency}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="color_whit_7 ">I will sell</label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          name="cryptoAmount"
                          id="cryptoAmount"
                          value={cryptoAmount}
                          readOnly="true"
                        />
                        <div class="input-group-append">
                          <button className="inpor_inside_buttons">
                            {sellAddDetails && sellAddDetails.firstCurrency}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <button
                        className="btn btn-primary text-uppercase py-2 w-100"
                        onClick={handlesellcrypto}
                      >
                        Trade Request
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                </GridItem>
              </GridContainer>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => closepopup()}
              >
                Close
              </button>
              {/* <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    // </div>
  );
}
