// import package
import React, { useState, useEffect } from 'react';
import { Switch } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'

// import action
import { getUserSetting, editUserSetting,alertNotification} from '../../actions/users';

// import lib
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
    "twoFA": false,
    "passwordChange": false,
    "siteNotification": false,
}

const Notification = () => {
    const dispatch = useDispatch();

    // state
    const [formValue, setFormValue] = useState(initialFormValue);

    const { twoFA, passwordChange, siteNotification } = formValue;

    // redux-state
    const userSetting = useSelector(state => state.userSetting)

    // function
    const handleChange = (e) => {
    
      const { name, checked } = e.target;
      setFormValue({ ...formValue, ...{ [name]: checked } })
      notifyAlert(name, checked)
  }

  const notifyAlert = async(name, checked) => {
    const { status, message } = await alertNotification({[name]:checked})

    if(status){
      toastAlert('success', message, 'notification');
    }
    else{
      toastAlert('error', "Something Went Wrong", 'notification');

    }
  } 

  const fetchData = async()=> {
      setFormValue({...formValue,...{'twoFA':userSetting.twoFA,'passwordChange':userSetting.passwordChange}})
  }

    useEffect(() => {
        if (isEmpty(userSetting)) {
            //getUserSetting(dispatch)
            fetchData()
        } else {
            setFormValue(userSetting)
        }
    }, [userSetting])
console.log("usersetting---",userSetting)
    return (

        <div className="dashboard_box dashAlertNotification">
                    <h5 class="dash_subtitle mb-0">Alert and Notifications</h5>
                    <div className="boxSpace">
                      <ul>
                        <li>
                          <label>When enable / disable 2FA</label>
                          <Switch
                        checked={twoFA}
                        onChange={handleChange}
                        color="primary"
                        name="twoFA"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                        </li>
                        <li>
                          <label>When login password change</label>
                          <Switch
                        checked={passwordChange}
                        onChange={handleChange}
                        color="primary"
                        name="passwordChange"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                        </li>
                        {/* <li className="border_non_p">
                          <label>Promotional email</label>
                          <Switch
                        checked={siteNotification}
                        onChange={handleChange}
                        color="primary"
                        name="siteNotification"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                        </li> */}
                      </ul>
                    </div>
                  </div>


        
    )
}

export default Notification;