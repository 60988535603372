import React, { useEffect,useState } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Checkbox from 'rc-checkbox';
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { getBlogCategorySlug } from '../actions/users';
import Moment from 'react-moment';
import 'moment-timezone';
import {
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import LeftBar from '../partials/leftBar'
import  config from '../lib/config'
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function SupportTicket(props) {
  const history = useHistory();
  const [ blogData, setBlogData ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [nextBtn,setNextBtn ] = useState(true)
  const [prevBtn,setPrevBtn ] = useState(false)

  const { ...rest } = props;
  let { slug } = useParams();
  

  const onPagination = async() => {
    let passQueryParams = {
       slug:slug,
       page:page+1,
       limit:5
    }
    const { status, data, nextBtn, prevBtn } = await getBlogCategorySlug(passQueryParams)
    if(status){
       setBlogData(data)
       setPage(page+1)
       setNextBtn(nextBtn)
       setPrevBtn(prevBtn)
       
    }
  }

  const onPrevious = async() => {
    let passQueryParams = {
       slug:slug,
       page:page-1,
       limit:5
    }
    const { status, data, nextBtn, prevBtn } = await getBlogCategorySlug(passQueryParams)
    if(status){
       setBlogData(data)
       setPage(page-1)
       setNextBtn(nextBtn)
       setPrevBtn(prevBtn)
    }
  }
  
  const getBlogData = async() => {
    let passData ={
      slug:slug,
      page:page,
      limit:5
    }
    const { status, data, nextBtn, prevBtn } = await getBlogCategorySlug(passData)
    if(status){
       setBlogData(data)
       setNextBtn(nextBtn)
       setPrevBtn(prevBtn)
    }
  }
  useEffect(()=>{
   getBlogData()
  },[slug])
  const redirect = async(item)=>{
    history.push('/blog-description/'+item._id)
  }
  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages">
            <div className="container">



              <GridContainer>
                <GridItem md={12} className="text-center">
                  <h2 className="main_title">Blog</h2>
                </GridItem>
              </GridContainer>


              <div className="blog_inner wow fadeIn">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <GridContainer className="blog_white_c">
                    {
                      blogData && blogData.length>0 && blogData.map((item,i)=>{
                        return(
                          <GridItem sm={12} md={12} className="text-center">
                            <Link to={"/blog-description/"+item.slug}>
                              <div className="news_section">
                              <div className="blog_description_img_">
                             <img alt="App" src={`${config.API_URL}/images/blogImages/${item.image}`} className="img-fluid" />
                              </div>
                                
                                <div className="news_content">
                                  <h3>{item.title}</h3>
                                  <p className="d-flex justify-content-between">{item.meta_description}</p>
                                  <p className="blog_category">Category: <span>{item.blog_category.category_name}</span></p>
                                  <p className="d-flex justify-content-between"><span className="first_span">{item.author}</span>
                                  <span>
                                  <Moment format="MMMM DD,YYYY">
                                    {item.created_date}
                                  </Moment>
                                  </span></p>
                                </div>
                              </div>
                            </Link>
                          </GridItem>
                        )
                      })
                    }
                    {
                      prevBtn?<Link onClick={onPrevious} className="nav-link home_menu_btn">Previous</Link>:""
                    }
                    
                    {
                      nextBtn?<Link onClick={onPagination} className="nav-link home_menu_btn" disabled={nextBtn}>Next</Link>:""
                    }
                      
                      {/*<GridItem sm={12} md={12} className="text-center">
                                              <Link to="/blog-description">
                                                <div className="news_section">
                                                  <img alt="App" src={require("../assets/images/news_3.png")} className="img-fluid" />
                                                  <div className="news_content">
                                                    <h3>Sichuan Energy Regulator to meet to Bitcoin Mining:Report</h3>
                                                    <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                                                    <p className="blog_category">Category: <span>Bitcoin Mining</span></p>
                                                    <p><span className="first_span">Jamie Crawley</span><span>May,2021</span></p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </GridItem>
                                            <GridItem sm={12} md={12} className="text-center">
                                              <Link to="/blog-description">
                                                <div className="news_section">
                                                  <img alt="App" src={require("../assets/images/news_4.png")} className="img-fluid" />
                                                  <div className="news_content">
                                                    <h3>Meme.com. a Platform Pairing Memes With Tokens Raises $5M</h3>
                                                    <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                                                    <p className="blog_category">Category: <span>MEMES</span></p>
                                                    <p><span className="first_span">Jamie Crawley</span><span>May,2021</span></p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </GridItem>
                                            <GridItem sm={12} md={12} className="text-center">
                                              <Link to="/blog-description">
                                                <div className="news_section">
                                                  <img alt="App" src={require("../assets/images/news_1.png")} className="img-fluid" />
                                                  <div className="news_content">
                                                    <h3>Sichuan Energy Regulator to meet to Bitcoin Mining:Report</h3>
                                                    <p>The Sichuan Energy Regulatory Office said Thursday it will meet June 2 to discuss crypto mining activities amid China’s nationwide crackdown.</p>
                                                    <p className="blog_category">Category: <span>Bitcoin Mining</span></p>
                                                    <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </GridItem>
                                            <GridItem sm={12} md={12} className="text-center">
                                              <Link to="/blog-description">
                                                <div className="news_section">
                                                  <img alt="App" src={require("../assets/images/news_2.png")} className="img-fluid" />
                                                  <div className="news_content">
                                                    <h3>DeFi'Rasies Challenges' For Invester,Regulators,SEC's Gensler Says </h3>
                                                    <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                                                    <p className="blog_category">Category: <span>DEFI</span></p>
                                                    <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </GridItem>*/}
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <LeftBar/>
                  </GridItem>
                </GridContainer>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
