// import package
import React, { useState, useEffect, useContext } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from 'react-redux';
import clsx from 'classnames';

// import context
import SocketContext from '../Context/SocketContext';

// import action
import { getFilledOrder } from '../../actions/perpetualTradeAction';

// import lib
import isEmpty from '../../lib/isEmpty';
import { capitalize } from '../../lib/stringCase';

const FilledOrder = () => {
    const socketContext = useContext(SocketContext)

    // state
    const [hasMore, setHasMore] = useState(true)
    const [loader, setLoader] = useState(true)
    const [orderData, setOrderData] = useState({
        currentPage: 1,
        nextPage: 1,
        limit: 10,
        count: 0,
        data: []
    })

    const { currentPage, nextPage, limit, count, data } = orderData

    // redux-state
    const tradePair = useSelector(state => state.tradePair);

    // function
    const fetchFilledOrder = async (reqData, pairId) => {
        try {
            const { status, loading, result } = await getFilledOrder(reqData, pairId);
            setLoader(loading)
            if (status == 'success') {
                setOrderData({
                    'currentPage': result.currentPage,
                    'nextPage': result.nextPage,
                    'limit': result.limit,
                    'count': result.count,
                    'data': [...data, ...result.data],
                })
            }
        } catch (err) { }
    }

    const fetchMoreData = () => {
        if (data.length == count) {
            setHasMore(false)
            return;
        }

        let reqData = {
            page: nextPage,
            limit
        }
        fetchFilledOrder(reqData, tradePair.pairId)
    };

    useEffect(() => {
        if (!isEmpty(tradePair)) {
            let reqData = {
                page: nextPage,
                limit
            }
            fetchFilledOrder(reqData, tradePair.pairId)

            // socket
            socketContext.socket.on('perpetualFilledOrder', (result) => {
                if (result.pairId == tradePair.pairId) {
                    setOrderData({
                        'currentPage': result.currentPage,
                        'nextPage': result.nextPage,
                        'limit': result.limit,
                        'count': result.count,
                        'data': result.data,
                    })
                }
            })
        }
    }, [tradePair])

    return (
        <div className="table-responsive">
            <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                height={250}
            >
                <table id="closedPLTable" className="table table-striped">
                    <thead>
                        <tr>
                            <th>Contracts</th>
                            <th>Closing Direction</th>
                            <th>Quntity</th>
                            <th>Entry Price</th>
                            <th>Exit Price</th>
                            <th>Closed P&L(Excl Fee)</th>
                            <th>Exit Type</th>
                            <th>Close Position Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.length > 0 && data.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{item.firstCurrency}/{item.secondCurrency}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.filledQuantity}</td>
                                        <td>{item.price}</td>
                                        <td>{item.price}</td>
                                        <td>{capitalize(item.orderType)}</td>
                                        <td className={clsx({ "greenText": item.buyorsell == 'buy' }, { "pinkText": item.buyorsell == 'sell' })}>{capitalize(item.buyorsell)}</td>
                                        <td>{item._id}</td>
                                        <td>{item.orderDate}</td>
                                    </tr>
                                )
                            })
                        }

                        {
                            !loader && data && data.length == 0 && <tr>
                                <td colspan="8" height="150" className="text-center">- No data Available -</td>
                            </tr>
                        }

                    </tbody>
                </table>
            </InfiniteScroll>
        </div>
    )
}

export default FilledOrder;