import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Switch from "@material-ui/core/Switch";
import { toastAlert } from "../../lib/toastAlert";
import { TimeAgo } from "@n1ru4l/react-time-ago";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CountdownTimer from "timer-countdown";
//import { io } from "socket.io-client";
import SocketContext from "../Context/SocketContext";

// import action file
import {
  getSingleOrderDetails,
  getOrderStatus,
  check2FA,
  getChatDetails,
  saveChatData,
  confirmPay,
  releaseCryptocurrency,
  cancelTrade,
  disputeTrade,
} from "../../actions/p2pAction";

// import lib
import config from "../../config";
const socketURL = config.SOCKET_URL;

const chatFormValue = {
  senderid: "",
  userid: "",
  msg: "",
  proofImage: "",
};

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function (props) {
  const { t, i18n } = useTranslation();
  const socketContext = useContext(SocketContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { ...rest } = props;
  const [twoFACode, setTwoFACode] = useState("");
  const [timeStatus, setTimeStatus] = useState(true);
  const [fromUserId, setFromUserId] = useState("");
  const [orderBookId, setOrderBookId] = useState("");
  const [orderDetails, setOrderDetails] = useState({});
  const [timeleft, setTimeleft] = useState("");
  const [timecomplete, setTimecomplete] = useState("");
  const [tradeStatusNum, setTradeStatusNum] = useState(0);
  const [chats, setChats] = useState([]);
  const [userId, setUserId] = useState("");
  const [confirmpayDisable, setConfirmpayDisable] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [chatformValue, setChatFormValue] = useState(chatFormValue);
  const [messenger, setMessenger] = useState();
  const [releaseDisable, setReleaseDisable] = useState(false);
  const [proofimagevalidatation, setproofimagevalidatation] = useState("");
  const [bankActive, setBankActive] = useState(false);
  const [gpayActive, setGpayActive] = useState(false);
  const [upiActive, setUpiActive] = useState(false);

  //chat formvalue
  const { senderid, userid, msg, proofImage } = chatformValue;
  const chatsEndRef = useRef(null);

  const authDetails = useSelector((state) => state.auth);

  const scrollToBottom = () => {
    chatsEndRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  const formatRemainingTime = (time) => {
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimecomplete("Yes");
      setTimeleft(0);
      return <div className="timer">Too late...</div>;
    }
    return (
      <div className="timer">
        <div className="value">{formatRemainingTime(remainingTime)}</div>
      </div>
    );
  };

  const getAd = async () => {
    try {
      let reqData = {
        orderId: id,
      };
      setTimeStatus(false);
      let { result, currentTime, status, message, error } =
        await getSingleOrderDetails(reqData, dispatch);
      if (result) {
        let order = result[0];
        setFromUserId(order.from_userId);
        setOrderBookId(order._id);
        setOrderDetails(order);
        if (status == false) {
          this.props.history.push("/dashboard");
        } else {
          let utcDate = new Date().toISOString();
          //let currentTime = new Date(utcDate).getTime();
          let EndTime = new Date(order.end_time).getTime();
          setTimeStatus(true);
          if (EndTime > currentTime) {
            let remainleft = (EndTime - currentTime) / 1000;
            setTimeleft(remainleft);
          } else {
            setTimeleft(0);
            setTimecomplete("Yes");
          }
          setTradeStatusNum(order.status);

          let bank_active = order.tradedata.transferMode.some(
            (e) => e === "Bank"
          );
          let gpy_active =
            !bank_active &&
            order.tradedata.transferMode.some((e) => e === "Gpay");
          let upi_active =
            !bank_active &&
            !gpy_active &&
            order.tradedata.transferMode.some((e) => e === "UPI");

          setBankActive(bank_active);
          setGpayActive(gpy_active);
          setUpiActive(upi_active);
        }
      }
    } catch (err) {}
  };

  const getchatdata = async () => {
    try {
      let reqData = {
        orderId: id,
      };
      let { result, status, message, error } = await getChatDetails(
        reqData,
        dispatch
      );
      if (status == false) {
        history.push("/dashboard");
      } else {
        setChats(result.reverse());
        scrollToBottom();
      }
    } catch (err) {}
  };

  //  Confirm pay
  const handleConfirmPay = async (e) => {
    setConfirmpayDisable(true);
    if (timecomplete && timecomplete == "Yes") {
      toastAlert("error", "Sorry. Time Exceeded..", "login");
      return false;
    }

    let userid = userId;
    var reqData = {};
    reqData.id = id;
    reqData.userid = userid;
    let { result, status, message, error } = await confirmPay(
      reqData,
      dispatch
    );
    if (status == false) {
      toastAlert("error", message, "login");
    } else {
      toastAlert("success", message, "login");
      getAd();
    }
    window.$("#confirm_payment").modal("hide");
    setConfirmpayDisable(false);
  };

  // Handle release asset
  const enable2FA = async (e) => {
    if (timecomplete == "Yes") {
      toastAlert("error", "Sorry. Time Exceeded..", "login");
      return false;
    }
    if (tradeStatusNum == 0) {
      toastAlert("error", "Payment not yet confirmed", "login");
      return false;
    }
    var efadatat = { id: id };
    let { result, status, message, error } = await check2FA(efadatat);
    if (status == "failed") toastAlert("error", message, "login");
    else window.$("#2fa_enable").modal("show");
  };

  const handleChange1 = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    if (name == "twoFACode") {
      if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
        return;
      }
      setTwoFACode(value);
    }
  };

  //  Handle cancel trade
  const handleconfirmCancel = async (e) => {
    setCancelDisable(true);
    if (timecomplete == "Yes") {
      toastAlert("error", "Sorry. Time Exceeded..", "login");
      return false;
    }

    var reqData = { id, userid: userId };
    let { result, status, message, error } = await cancelTrade(
      reqData,
      dispatch
    );
    window.$("#cancel_trade").modal("hide");
    if (status == false) {
      toastAlert("error", message, "login");
    } else {
      toastAlert("success", message, "login");
    }
    setCancelDisable(false);
  };

  // Handle dispute trade
  const handleDispute = async (e) => {
    let userid = userId;
    var reqData = {};
    reqData.id = id;
    reqData.userid = userid;
    let { result, status, message, error } = await disputeTrade(
      reqData,
      dispatch
    );
    if (status == "failed") {
      toastAlert("error", message, "login");
    } else {
      toastAlert("success", message, "login");
    }
    setCancelDisable(false);
  };

  const fetchOrderStatus = async (data) => {
    try {
      let reqData = { userId: authDetails.userId, orderId: id };
      let { result, loading, message, error } = await getOrderStatus(
        reqData,
        dispatch
      );
      if (result == 4) {
        setTimecomplete("Yes");
        toastAlert("success", "Trade Completed Successfully.", "login");
      }
      setTradeStatusNum(result);
    } catch (err) {}
    var finalChat = data[0];
    setChats((prev) => [...prev, finalChat]);
    scrollToBottom();
  };

  const handleMessageChange = (event) => {
    setChatMessage(event.target.value);
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    let formData = { ...chatformValue, ...{ [name]: files[0] } };
    setChatFormValue(formData);
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|docx|doc)$/;

    if (files[0].size > 1000000) {
      setproofimagevalidatation("Image Size is too Large");
    }
    if (!imageFormat.test(files[0].name)) {
      setproofimagevalidatation("Invalid Image Format");
    } else setproofimagevalidatation("");
  };

  //  Send message
  const handleSend = async (e) => {
    let messenger;
    if (orderDetails.BuyorSell == "Sell") {
      setMessenger("Seller");
    }
    if (orderDetails.BuyorSell == "Buy") {
      setMessenger("Buyer");
    }
    let reqData = {
      id: id,
      userid: userId,
      msg: chatMessage,
    };

    if (proofimagevalidatation != "") {
      toastAlert("error", "Please Select Valid File", "login");
      return;
    }

    // if (
    //   (chatMessage == "" || chatMessage == undefined) &&
    //   (proofImage == "" || proofImage == undefined)
    // ) {
    //   toastAlert("error", "Please Enter Message", "login");
    //   return;
    // }

    const formData = new FormData();
    formData.append("senderid", id);
    formData.append("userid", userId);
    formData.append("msg", chatMessage);
    formData.append("proofImage", proofImage);

    var { result, status, message, error } = await saveChatData(
      formData,
      dispatch
    );
    setChatFormValue(chatFormValue);

    if (status == true) {
      toastAlert("success", message, "login");
      setChatMessage("");
    } else {
      toastAlert("error", message, "login");
    }
  };

  // Handle release asset
  const handlereleaseCryptocurrency = async () => {
    if (timecomplete == "Yes") {
      toastAlert("error", "Sorry. Time Exceeded..", "login");
      return false;
    }
    setReleaseDisable(true);
    let userid = userId;
    var reqData = {};
    reqData.id = id;
    reqData.userid = userid;
    reqData.twoFACode = twoFACode;
    let { result, status, message, error } = await releaseCryptocurrency(
      reqData,
      dispatch
    );

    if (status == false) {
      toastAlert("error", message);
    } else {
      toastAlert("success", message, "login");
      window.$("#2fa_enable").modal("hide");
    }
    setReleaseDisable(false);
  };

  const copyText = (entryText) => {
    navigator.clipboard.writeText(entryText);
    toastAlert("success", "Copied to your clipboard", "login");
  };

  useEffect(() => {
    if (authDetails.userId) {
      getAd();
      getchatdata();
      setUserId(authDetails.userId);
    }
  }, [authDetails]);

  useEffect(() => {
    if (id && id != undefined) {
      socketContext.socket.on("p2pchat-" + id, (data) => {
        fetchOrderStatus(data);
      });
    }
  }, []);

  return (
    <div className="dashboardMain">
      <div className="dashboardRight afterLoginHeader">
        <Header
          className="header"
          color="transparent"
          routes={dashboardRoutes}
          brand={
            <img
              src={require("../../assets/images/logo.png")}
              alt="logo"
              className="img-fluid"
            />
          }
          rightLinks={<HeaderLinksAfterlogin />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "dark",
          }}
          {...rest}
        />
        <div className="dashboardContent userPages peer2peer_section">
          <div className="container">
            <GridContainer>
              <GridItem xs={12} sm={12} md={5} lg={5}>
                <h3 className="dash_title">
                  Peer-to-Peer{" "}
                  <span>
                    {" "}
                    <i class="fas fa-chevron-right arrow_key_titlw"></i>{" "}
                  </span>{" "}
                  <span className="color_gol-sd">
                    {orderDetails &&
                      orderDetails.tradedata &&
                      (orderDetails.tradedata.userId != userId
                        ? orderDetails.BuyorSell
                        : orderDetails.tradedata.BuyorSell)}{" "}
                    {orderDetails && orderDetails.firstCurrency}
                  </span>
                </h3>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="dashboard_box t2patid">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={7}>
                      <div className="chat_content_center">
                        <div>
                          <p>Final Amount</p>
                          <h4>
                            {orderDetails && orderDetails.fiat_amount}{" "}
                            {orderDetails && orderDetails.secondCurrency}
                          </h4>
                        </div>
                        <div>
                          <p>Price</p>
                          <h4>
                            {orderDetails && orderDetails.price}{" "}
                            {orderDetails && orderDetails.secondCurrency}
                          </h4>
                        </div>
                        <div>
                          <p>Quantity</p>
                          <h4>
                            {orderDetails && orderDetails.crypto_amount}{" "}
                            {orderDetails && orderDetails.firstCurrency}
                          </h4>
                        </div>
                      </div>
                      <div className="text-center chat_conte_po">
                        {timeleft > 0 &&
                          (tradeStatusNum == 0 || tradeStatusNum == 1) && (
                            <div>
                              <h3>Payment to be made</h3>
                              <div className="new_timer">
                                <CountdownCircleTimer
                                  isPlaying={true}
                                  duration={1800}
                                  initialRemainingTime={timeleft}
                                  colors={[
                                    ["#fe923f", 0.33],
                                    ["#fe923f", 0.33],
                                    ["#fe923f", 0.33],
                                  ]}
                                >
                                  {renderTime}
                                </CountdownCircleTimer>
                              </div>

                              <div className="button_section_po_chat">
                                <h3>Minutes</h3>
                                {tradeStatusNum == 0 &&
                                  fromUserId &&
                                  userId &&
                                  fromUserId != userId && (
                                    <>
                                      <p>
                                        Please make a payment within 30 mins,
                                        otherwise, the order will be cancelled
                                        automatically.
                                      </p>
                                      <button
                                        type="button"
                                        className="btn btn-primary text-uppercase py-2  ma-aut0pdd"
                                        data-toggle="modal"
                                        data-target="#confirm_payment"
                                      >
                                        Transfered Payment
                                      </button>
                                      <button
                                        className="btn btn-primary text-uppercase py-2 ma-aut0pdd"
                                        data-toggle="modal"
                                        data-target="#cancel_trade"
                                      >
                                        Cancel Order
                                      </button>
                                    </>
                                  )}
                                {fromUserId && userId && fromUserId == userId && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-primary text-uppercase py-2  ma-aut0pdd"
                                      onClick={() => enable2FA()}
                                    >
                                      Release Asset
                                    </button>
                                    {tradeStatusNum == 0 && (
                                      <button
                                        className="btn btn-primary text-uppercase py-2 ma-aut0pdd"
                                        data-toggle="modal"
                                        data-target="#cancel_trade"
                                      >
                                        Cancel Order
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        {timecomplete == "Yes" && tradeStatusNum == 1 && (
                          <button
                            onClick={() => handleDispute()}
                            className="button_take_control"
                          >
                            Dispute trade
                          </button>
                        )}
                        {tradeStatusNum == 5 && (
                          <h5>Time Exceeded - Trade Closed</h5>
                        )}

                        {timeleft === 0 && tradeStatusNum != 5 && (
                          <h5>Time Exceeded - Trade Closed</h5>
                        )}
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6} lg={5}>
                      <div className="chat_box_dark">
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <div className="chat_message_section">
                              {chats &&
                                chats.length > 0 &&
                                chats.map((item, i) => {
                                  var imagepath = `${config.API_URL}/images/chat/${item.attachment}`;
                                  var name = item.admin
                                    ? "Admin"
                                    : item.senderdetails.name.trim()
                                    ? item.senderdetails.name
                                    : item.senderdetails.email.split("@")[0];

                                  return (
                                    <div
                                      className={
                                        item.Sender_userId != userId
                                          ? "message_section messAge_recive"
                                          : "message_section message_send"
                                      }
                                      key={i}
                                    >
                                      <div>
                                        <span className="new_caht_span_deati">
                                          <span>{name}</span>
                                          <span>
                                            <TimeAgo
                                              date={new Date(item.created_at)}
                                            >
                                              {({ value }) => value}
                                            </TimeAgo>
                                          </span>
                                        </span>{" "}
                                        <p>
                                          {item.message}
                                          {item.attachment != "" ? (
                                            <>
                                              <a
                                                href={imagepath}
                                                target="_blank"
                                              >
                                                &nbsp;&nbsp;
                                                <i class="fas fa-paperclip"></i>
                                              </a>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                        <br />
                                      </div>
                                    </div>
                                  );
                                })}
                              <div className="chatRef" ref={chatsEndRef} />
                            </div>
                          </GridItem>
                          {tradeStatusNum != 2 &&
                            tradeStatusNum != 4 &&
                            tradeStatusNum != 5 && (
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="footer_chat">
                                  <div className="form-group">
                                    <div className="input_position">
                                      <form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          handleSend(e);
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="write message..."
                                          name="message"
                                          value={chatMessage}
                                          onChange={handleMessageChange}
                                        />
                                        <div className="flx_amount_just">
                                          <div className="button_section_chat">
                                            <div>
                                              <i class="fas fa-paperclip"></i>
                                              <input
                                                type="file"
                                                name="proofImage"
                                                onChange={handleFile}
                                              />
                                            </div>
                                          </div>
                                          <i
                                            class="fas fa-paper-plane"
                                            onClick={() => handleSend()}
                                          ></i>
                                        </div>

                                        {proofImage && proofImage.name}
                                        <p style={{ color: "red" }}>
                                          {proofimagevalidatation}
                                        </p>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            )}
                        </GridContainer>
                      </div>
                    </GridItem>
                    {orderDetails &&
                      orderDetails.tradedata &&
                      fromUserId !== userId && (
                        <GridItem xs={12} sm={12} md={6} lg={12}>
                          <div className="tab_bank_s border_bottimdd">
                            <ul className="nav nav-tabs">
                              {orderDetails.tradedata.transferMode.map(
                                (mode, i) => {
                                  let active =
                                    (mode === "Bank" && bankActive) ||
                                    (mode === "Gpay" && gpayActive) ||
                                    (mode === "UPI" && upiActive);
                                  return (
                                    <li>
                                      <a
                                        data-toggle="tab"
                                        href={`#menu${mode}`}
                                        className={active ? "active" : ""}
                                      >
                                        {mode}
                                      </a>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                            <div className="tab-content">
                              <div
                                id="menuBank"
                                className={`tab-pane fade ${
                                  bankActive ? "in active show" : ""
                                }`}
                              >
                                <div className="account_details_section">
                                  <h4>
                                    Transfer the funds to the sellers's account
                                    provided below.
                                  </h4>
                                  <GridContainer className="mt-3 grid_border">
                                    {orderDetails.sellerdetails.bankDetail && (
                                      <GridItem xs={12} sm={12} md={12} lg={5}>
                                        <div className="Upi_font_tessaas">
                                          <div>
                                            <div className="upli_lavelee">
                                              <label>Name</label>
                                              <p>
                                                {
                                                  orderDetails.sellerdetails
                                                    .bankDetail.bankName
                                                }{" "}
                                                <i
                                                  className="fas fa-copy"
                                                  onClick={() =>
                                                    copyText(
                                                      orderDetails.sellerdetails
                                                        .bankDetail.bankName
                                                    )
                                                  }
                                                ></i>
                                              </p>
                                            </div>
                                            <div className="upli_lavelee">
                                              <label>Account Number</label>
                                              <p>
                                                {
                                                  orderDetails.sellerdetails
                                                    .bankDetail.accountNo
                                                }{" "}
                                                <i
                                                  className="fas fa-copy"
                                                  onClick={() =>
                                                    copyText(
                                                      orderDetails.sellerdetails
                                                        .bankDetail.accountNo
                                                    )
                                                  }
                                                ></i>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="upli_lavelee">
                                            <label>{t("IBAN_CODE")}</label>
                                            <p>
                                              {
                                                orderDetails.sellerdetails
                                                  .bankDetail.bankcode
                                              }{" "}
                                              <i
                                                className="fas fa-copy"
                                                onClick={() =>
                                                  copyText(
                                                    orderDetails.sellerdetails
                                                      .bankDetail.bankcode
                                                  )
                                                }
                                              ></i>
                                            </p>
                                          </div>
                                        </div>
                                      </GridItem>
                                    )}
                                  </GridContainer>
                                </div>
                              </div>
                              <div
                                id="menuGpay"
                                className={`tab-pane fade ${
                                  gpayActive ? "in active show" : ""
                                }`}
                              >
                                <div className="account_details_section">
                                  <h4>
                                    Transfer the funds to the sellers's account
                                    provided below.
                                  </h4>
                                  <GridContainer className="mt-3 grid_border">
                                    {orderDetails.sellerdetails.gpayDetail && (
                                      <GridItem xs={12} sm={12} md={12} lg={5}>
                                        <div className="Upi_font_tessaas">
                                          <img
                                            src={`${config.API_URL}/images/gpay/${orderDetails.sellerdetails.gpayDetail.frontImage}`}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                      </GridItem>
                                    )}
                                  </GridContainer>
                                </div>
                              </div>
                              <div
                                id="menuUPI"
                                className={`tab-pane fade ${
                                  upiActive ? "in active show" : ""
                                }`}
                              >
                                <div className="account_details_section">
                                  <h4>
                                    Transfer the funds to the sellers's account
                                    provided below.
                                  </h4>
                                  <GridContainer className="mt-3 grid_border">
                                    {orderDetails.sellerdetails.upiDetail && (
                                      <GridItem xs={12} sm={12} md={12} lg={5}>
                                        <div className="Upi_font_tessaas">
                                          <div>
                                            <div className="upli_lavelee">
                                              <label>UPI ID</label>
                                              <p>
                                                {
                                                  orderDetails.sellerdetails
                                                    .upiDetail.upiId
                                                }{" "}
                                                <i
                                                  className="fas fa-copy"
                                                  onClick={() =>
                                                    copyText(
                                                      orderDetails.sellerdetails
                                                        .upiDetail.upiId
                                                    )
                                                  }
                                                ></i>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </GridItem>
                                    )}
                                  </GridContainer>
                                </div>
                              </div>
                            </div>
                          </div>
                        </GridItem>
                      )}
                  </GridContainer>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <div
        className="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="cancel_trade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="cancel_trade"
        aria-hidden="true"
        data-backdrop=""
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="confirm_body">
                <h2>Cancel Trade</h2>
                <img
                  src={require("../../assets/images/pulp.png")}
                  alt=""
                  className="img-fluid"
                />
                <p>Are you sure want to cancel the Trade?</p>
                <div className="new_confirm_pay_button">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    className="button1"
                    onClick={() => handleconfirmCancel()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="confirm_payment"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirm_payment"
        aria-hidden="true"
        data-backdrop=""
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="confirm_body">
                <h2>Confirm payment</h2>
                <img
                  src={require("../../assets/images/pulp.png")}
                  alt=""
                  className="img-fluid"
                />
                <div>
                  <span>
                    Please confirm that payment has been made to the seller.{" "}
                  </span>
                  <span>Malicious clicks will lead to account frozen.</span>
                </div>
                <div className="new_confirm_pay_button">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    className="button1"
                    onClick={() => handleConfirmPay()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="2fa_enable"
        tabindex="-1"
        role="dialog"
        aria-labelledby="2fa_enable"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="confirm_body ">
                <h2>2FA code Verification</h2>
                <img
                  src={require("../../assets/images/postconfirm.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="model_detail_text justify-content-center">
                  <div className="form-group pl-3 pr-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("ENTER_TWO_FA_CODE")}
                      name="twoFACode"
                      value={twoFACode}
                      onChange={handleChange1}
                    />
                    {/* {validateError.twoFACode && <p className="error-message">{t(validateError.twoFACode)}</p>} */}
                  </div>
                </div>
                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    className="button1"
                    onClick={handlereleaseCryptocurrency}
                  >
                    Release
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}

      <Footer />
    </div>
    // </div>
  );
}
