// import lib
import isEmpty from '../../lib/isEmpty';

export const validation = value => {
    let errors = {};
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|)$/;
console.log("validationaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",value)
    if (isEmpty(value.type)) {
        errors.type = "Select Document Type";
    }
    if (isEmpty(value.proofNumber)) {
        errors.proofNumber = "Proof Number Required";
    }

    if (value.frontImage && value.frontImage.size) {
        if (value.frontImage.size > 1000000) {
            errors.frontImage = "Image Size is too Large"
        } else if (!imageFormat.test(value.frontImage.name)) {
            errors.frontImage = "Invalid Image Format"
        }
    } else {
        errors.frontImage = "Image is Required";
    }

    if (value.backImage && value.backImage.size) {
        if (value.backImage.size > 1000000 ) {
            errors.backImage = "Image Size is too Large"
        } else if (!imageFormat.test(value.backImage.name)) {
            errors.backImage = "Invalid Image Format"
        }
    } else {
        errors.backImage = "Image is Required";
    }

    if (value.selfiImage && value.selfiImage.size) {
        if (value.selfiImage.size > 1000000) {
            errors.selfiImage = "Image Size is too Large"
        } else if (!imageFormat.test(value.selfiImage.name)) {
            errors.selfiImage = "Invalid Image Format"
        }
    } else {
        errors.selfiImage = "Image is Required";
    }

    return errors;
}

export default validation;