// import package
import React, { useState, useEffect, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import clsx from "classnames";
import moment from "moment";

// import context
import SocketContext from "../Context/SocketContext";

// import component
import CancelOrder from "./CancelOrder";

// import action
import { getOpenOrder } from "../../actions/spotTradeAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { capitalize } from "../../lib/stringCase";
import { triggerCondition } from "../../lib/displayStatus";

const OpenOrder = (props) => {
    const socketContext = useContext(SocketContext);

    // props
    const { handleCount } = props;

    // state

    const [loader, setLoader] = useState(true);
    const [orderData, setOrderData] = useState({
        currentPage: 1,
        nextPage: true,
        limit: 10,
        count: 0,
        data: [],
    });

    const { currentPage, nextPage, limit, count, data } = orderData;

    // redux-state
    const tradePair = useSelector((state) => state.tradePair);

    // function
    const fetchOpenOrder = async (reqData, pairId) => {
        try {
            const { status, loading, result } = await getOpenOrder(
                reqData,
                pairId
            );
            setLoader(loading);
            if (status == "success") {
                handleCount(result.count);
                setOrderData({
                    currentPage: result.currentPage,
                    nextPage: result.nextPage,
                    limit: result.limit,
                    count: result.count,
                    data: [...data, ...result.data],
                });
            } else {
                setOrderData({
                    ...orderData,
                    ...{ nextPage: false },
                });
            }
        } catch (err) {}
    };

    const fetchMoreData = () => {
        if (data.length == count) {
            setOrderData({
                ...orderData,
                ...{ nextPage: false },
            });
            return;
        }

        let reqData = {
            page: currentPage + 1,
            limit,
        };
        fetchOpenOrder(reqData, tradePair.pairId);
    };

    useEffect(() => {
        if (!isEmpty(tradePair)) {
            let reqData = {
                page: currentPage,
                limit,
            };
            fetchOpenOrder(reqData, tradePair.pairId);

            // socket
            socketContext.socket.on("openOrder", (result) => {
                if (result.pairId == tradePair.pairId) {
                    handleCount(result.count);
                    setOrderData({
                        currentPage: result.currentPage,
                        nextPage: result.nextPage,
                        limit: result.limit,
                        count: result.count,
                        data: result.data,
                    });
                }
            });
        }
    }, [tradePair]);

    return (
        <div className="table-responsive">
            <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={nextPage}
                // loader={<h4>Loading...</h4>}
                height={250}
            >
                <table id="positionsTable" className="table table-striped">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Pair</th>
                            <th>Type</th>
                            <th>Side</th>
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Filled</th>
                            <th>Total</th>
                            <th>Trigger Conditions</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loader &&
                            data &&
                            data.length > 0 &&
                            data.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            {moment(item.orderDate).format(
                                                "DD-MM-YYYY HH:mm"
                                            )}
                                        </td>
                                        <td>
                                            {item.firstCurrency}/
                                            {item.secondCurrency}
                                        </td>
                                        <td>{capitalize(item.orderType)}</td>
                                        <td
                                            className={clsx(
                                                {
                                                    greenText:
                                                        item.buyorsell == "buy",
                                                },
                                                {
                                                    pinkText:
                                                        item.buyorsell ==
                                                        "sell",
                                                }
                                            )}
                                        >
                                            {capitalize(item.buyorsell)}
                                        </td>
                                        <td>{item.price}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.filledQuantity}</td>
                                        <td>{item.quantity * item.price}</td>
                                        <td>
                                            {triggerCondition(
                                                item.conditionalType
                                            )}
                                        </td>
                                        <td>
                                            <CancelOrder orderId={item._id} />
                                        </td>
                                    </tr>
                                );
                            })}
                        {!loader && data && data.length == 0 && (
                            <tr>
                                <td
                                    colspan="8"
                                    height="150"
                                    className="text-center"
                                >
                                    - No data Available -
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </InfiniteScroll>
        </div>
    );
};
export default OpenOrder;