export const getAuthToken = () => {
    if (localStorage.getItem('user_token')) {
        return localStorage.getItem('user_token')
    }
    return '';
}

export const removeAuthToken = () => {
    localStorage.removeItem('user_token');
}

export const setAuthToken = (token) => {
    localStorage.setItem('user_token', token);
    return true
}

export const setTheme = async () => {
    let theme = await getTheme()
    if(theme == 'light'){
        theme = 'dark'
    } else {
        theme = 'light'
    }
    localStorage.setItem('theme', theme);
    return true
}


export const changeTradeTheme = (theme) => {
    if (theme == 'dark') {
        document.body.classList.add('light_theme');
    } else if (theme == 'light') {
        document.body.classList.remove('light_theme');
    }
    return true;
}

export const getTheme = () => {
    if (localStorage.getItem('theme')) {
        return localStorage.getItem('theme')
    }
    return 'dark';
}

export const setLang = async (value) => {
    localStorage.setItem('lang', value);
    return true
}

export const getLang = () => {
    if (localStorage.getItem('lang')) {
        return localStorage.getItem('lang')
    }
    return '';
}