// import package
import React, { createRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import Checkbox from 'rc-checkbox';
import { useHistory } from 'react-router-dom';

// import config
import config from '../../config';

// import action
import { createUser } from '../../actions/users';

// import lib
import validation from './validation';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';
import { getLang } from '../../lib/localStorage';


const initialFormValue = {
    'email': '',
    'password': '',
    'confirmPassword': '',
    'reCaptcha': '',
    'isTerms': false
}

const RegisterForm = () => {
    const recaptchaRef = createRef();
    const grecaptchaObject = window.grecaptcha;
    const history = useHistory();

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [toched, setToched] = useState({});
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();
    const { email, password, confirmPassword, reCaptcha, isTerms } = formValue;
    const [sighnupshowPassword, setSighnupshowPassword] = useState(false);
    const [sighnupOptionPassword, setSighnupOptionPassword] = useState(false);
    // function

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        setValidateError(validation(formData))
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        setToched({ ...toched, ...{ [name]: true } })
    }

    const handleRecaptcha = (value) => {
        let formData = { ...formValue, ...{ 'reCaptcha': value } }
        setFormValue(formData)
        setValidateError(validation(formData))
    }

    const handleCheckBox = (e) => {
        const { name, checked } = e.target
        let formData = { ...formValue, ...{ [name]: checked } }
        setFormValue(formData)
        setValidateError(validation(formData))
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        let reqData = {
            email,
            password,
            confirmPassword,
            reCaptcha,
            isTerms,
            langCode: getLang()
        }
        let { status, loading, message, error } = await createUser(reqData);
        setLoader(loading);
        if (status == 'success') {
            setFormValue(initialFormValue)
            toastAlert('success', message, 'signup');
            history.push('/login')
        } else {
            if (error) {
                setValidateError(error);
            }
            toastAlert('error', message, 'signup');
        }
    }

    useEffect(() => {
        setValidateError(validation(formValue))
    }, [])

    return (
        <form className="login_form p-4 mb-4" data-aos="fade-up">
            <h2 data-aos="fade-up">Sign Up</h2>
            <div className="form-group pl-3 pr-3">
                <label>Email Address</label>
                <input
                    type="text"
                    className="form-control"

                    name="email"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {toched.email && validateError.email && <p className="error-message">{validateError.email}</p>}
            </div>
            <div className="form-group pl-3 pr-3">
                <label>Password</label>
                <div className='input-group'>
                <input
                    type={`${sighnupshowPassword ? "text" : "password"
                        }`}
                    //type="password"

                    className="form-control"

                    name="password"
                    value={password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                  <div class="input-group-append">
                <span
                    className="input-group-text"
                    onClick={() =>
                        setSighnupshowPassword(!sighnupshowPassword)
                    }
                >
                    <i
                        className={`fa fa-eye-slash ${sighnupshowPassword ? "d-none" : ""
                            }`}
                    ></i>
                    <i
                        className={`far fa-eye ${sighnupshowPassword ? "" : "d-none"
                            }`}
                    ></i>
                </span>
                </div>
                </div>
                {toched.password && validateError.password && <p className="error-message">{validateError.password}</p>}
            </div>
            <div className="form-group pl-3 pr-3">
                <label>Confirm Password</label>
                <div className='input-group'>
               
                <input
                    type={`${sighnupOptionPassword ? "text" : "password"
                        }`}
                    //type="password"
                    className="form-control"

                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <div class="input-group-append">
                 <span
                    className="input-group-text"
                    onClick={() =>
                        setSighnupOptionPassword(!sighnupOptionPassword)
                    }
                >
                    <i
                        className={`fa fa-eye-slash ${sighnupOptionPassword ? "d-none" : ""
                            }`}
                    ></i>
                    <i
                        className={`far fa-eye ${sighnupOptionPassword ? "" : "d-none"
                            }`}
                    ></i>
                </span>
                </div>
                </div>
                {toched.confirmPassword && validateError.confirmPassword && <p className="error-message">{validateError.confirmPassword}</p>}
            </div>

            <div className="form-group pl-3 pr-3 text-center">
                <ReCAPTCHA
                    className="recapta_form"
                    ref={recaptchaRef}
                    grecaptcha={grecaptchaObject}
                    sitekey={config.RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptcha}
                />
                {/* {validateError.reCaptcha != "" && <p className="error-message">{validateError.reCaptcha}</p>} */}
            </div>

            <div className="form-group pl-3 pr-3">
                <div className="form-check">
                    <Checkbox
                        name="isTerms"
                        onChange={handleCheckBox}
                        checked={isTerms}
                    />

                    {/* <input
                        type="checkbox"
                        id="flexCheckDefault"
                        name="isTerms"
                        checked={true}
                        // value=""
                        onChange={handleChange}
                    /> */}
                    <label className="form-check-label" for="flexCheckDefault">
                        I accept and agree to the <Link to="/terms">Terms & Conditions</Link>
                    </label>
                </div>
            </div>
            <div className="form-group pl-3 pr-3">
                <Button
                    onClick={handleFormSubmit}
                    disabled={!isEmpty(validateError) || loader}
                >
                    {loader && <i class="fas fa-spinner fa-spin"></i>}
                    Submit
                </Button>
            </div>
            {/* <div className="d-flex">
                <Link to="/forgot-password" className="mr-auto">Forgot password?</Link>
                <Link to="/login" className="ml-auto">Already have an account?</Link>
            </div> */}
            <div className="d-flex text-center justify-content-center">
                <Link to="/login"><span className="span_inside_lo">Already have an account?</span> Log In</Link>
            </div>
        </form>
    )
}

export default RegisterForm;
