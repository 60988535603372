// import config
import axios, { setAuthorization, removeAuthorization } from '../config/axios'
import config from '../lib/config';
import { removeAuthToken, setAuthToken } from '../lib/localStorage'

//import lib

import {toastAlert} from '../lib/toastAlert'

// import constant
import {
    SET_USER_ACCOUNT,
    SET_BANK_DETAIL,
    SET_UPI_DETAIL,
    SET_QR_DETAIL,
    SET_QR_FORM,
    SET_BANK_FORM,
    SET_UPI_FORM,
    SET_USER_SETTING,
    SET_INSTANT_SPOT,
    LOGOUT
} from '../constant';

// import action
// import { settingModalStatus } from './modal'
import { decodeJwt } from './jsonWebToken';

export const createUser = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `/api/register`,
            data
        });
        return {
            status: 'success',
            loading: false,
            message: respData.data.message,
            // userToken: respData.data.userToken,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const verifyOtp = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/verifyOtp`,
            data
        });

        return {
            status: "success",
            messages: respData.data.messages,
            loading: false
        }

    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const createUserPhone = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/registerphone`,
            data
        });

        return {
            loading: false
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const userEmailActivation = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/confirm-mail`,
            data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const login = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/login`,
            data
        });

        if (respData.data.status == 'TWO_FA') {
            return {
                status: "TWO_FA",
                loading: false,
                message: respData.data.message
            }
        }

        decodeJwt(respData.data.token, dispatch)
        setAuthToken(respData.data.token)
        dispatch(setAccountData(respData.data.result))
        setAuthorization(respData.data.token)
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            message: respData.data.message
        }
    }
    catch (err) {
        console.log("Error000----", err.response.data.errors)
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
// export const login = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/login`,
//             data
//         });

//         // if (respData.data.result.type == 'codeVerification') {
//         //     return {
//         //         status: "ipAddressVerification",
//         //         loading: false,
//         //         messages: respData.data.messages,
//         //         result: respData.data.result,
//         //     }
//         // } else {
//         decodeJwt(respData.data.token, dispatch)
//         setAuthToken(respData.data.token)
//         dispatch(setAccountData(respData.data.result))
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result,
//             message: respData.data.message
//         }
//         // }
//     }
//     catch (err) {
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

export const editUserProfile = async (data, dispatch) => {

    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/userProfile`,
            data
        });
        dispatch(setAccountData(respData.data.result))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const viewUserProfile = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/userProfile`
        });
        dispatch(setAccountData(respData.data.result))
        return {
            status: "success",
            loading: false,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const setAccountData = (data) => {
    return {

        type: SET_USER_ACCOUNT,
        data: {
            'userId': data.userId,
            'firstName': data.firstName,
            'lastName': data.lastName,
            'email': data.email,
            'blockNo': data.blockNo,
            'address': data.address,
            'city': data.city,
            'state': data.state,
            'country': data.country,
            'postalCode': data.postalCode,
            'emailStatus': data.emailStatus,
            'phoneStatus': data.phoneStatus,
            'IDstatus': data.IDstatus,
            'Addresstatus': data.Addresstatus,
            'phonenumber': data.phonenumber,
            'phoneCode': data.phoneCode,
            'type': data.type,
            'google2Fa': data.google2Fa,
            'createAt': data.createAt,
            'bankDetail': data.bankDetail,
            'upiDetail': data.upiDetail
        }
    }
}

export const getBankDetail = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/bankdetail`
        });
        dispatch(setBankDetail({
            'result': respData.data.result
        }))
        return true
    }
    catch (err) {
        return false
    }
}

export const getUPIDetail = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/upidetail`
        });
        dispatch(setUPIDetail({
            'result': respData.data.result.upiDetails
        }))
        return true
    }
    catch (err) {
        return false
    }
}

export const getQRDetail = async (dispatch) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `/api/qrdetail`
        });

        dispatch(setQRDetail({
            'result': respData.data.result.qrDetails
        }))
        return true
    }
    catch (err) {
        return false
    }
}


export const updateBankDetail = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/bankdetail`,
            data
        });

        dispatch(setBankDetail({
            'formType': '',
            'formDisable': true,
            'result': respData.data.result
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const updateUPIDetail = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/upidetail`,
            data
        });
        dispatch(setUPIDetail({
            'formType': '',
            'formDisable': false,
            'result': respData.data.result
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}
export const updateQRDetail = async (data, dispatch) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `/api/qrdetail`,
            data
        });


        dispatch(setQRDetail({
            'formType': '',
            'formDisable': false,
            'result': respData.data.result
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const setPrimaryBank = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/bankdetail`,
            data
        });

        dispatch(setBankDetail({
            'result': respData.data.result
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}


export const setPrimaryUPI = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/primaryupi`,
            data
        });
        dispatch(setUPIDetail({
            'result': respData.data.result
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        console.log("err---", err)
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const setPrimaryQR = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/primaryqr`,
            data
        });

        dispatch(setQRDetail({
            'result': respData.data.result
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        console.log("err---", err)
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const deleteBankDetail = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/bankdetail`,
            data
        });

        dispatch(setBankDetail({
            'result': respData.data.result,
            'formType': '',
            'formDisable': true,
            'editRecord': {}
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const deleteUPIDetail = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/upidetail`,
            data
        });

        dispatch(setUPIDetail({
            'result': respData.data.result,
            'formType': '',
            'formDisable': true,
            'editRecord': {}
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const deleteQRDetail = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/qrdetail`,
            data
        });

        dispatch(setQRDetail({
            'result': respData.data.result,
            'formType': '',
            'formDisable': true,
            'editRecord': {}
        }))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const setBankDetail = (data) => {
    return {
        type: SET_BANK_DETAIL,
        data
    }
}
export const setUPIDetail = (data) => {
    return {
        type: SET_UPI_DETAIL,
        data
    }
}
export const setUPIForm = (data) => {
    return {
        type: SET_UPI_FORM,
        data
    }
}
export const setQRDetail = (data) => {
    return {
        type: SET_QR_DETAIL,
        data
    }
}
export const setQRForm = (data) => {
    return {
        type: SET_QR_FORM,
        data
    }
}

export const setBankForm = (data) => {
    return {
        type: SET_BANK_FORM,
        data
    }
}

export const changePassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/changePassword`,
            data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const get2faCode = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/security/2fa`
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const update2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/security/2fa`,
            data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const disabled2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/security/2fa`,
            data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const getUserSetting = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/userSetting`,
        });
        dispatch(setUserSetting(respData.data.result))
        return true
    }
    catch (err) {
        return false
    }
}

export const setUserSetting = (data) => {
    return {
        type: SET_USER_SETTING,
        data
    }
}

export const alertNotification = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/alertNotification`,
            data
        });

        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const editUserSetting = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/userSetting`,
            data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const verifyIpAddress = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/verifyIpAddress`,
            data
        });
        await setAuthToken(respData.data.token)
        // decodeJwt(respData.data.token, dispatch)
        // await dispatch(setCurrentUser(respData.data.result))
        // await setInitialCall();
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            messages: respData.data.messages
        }

    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const getCurrentUser = async (token, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/currentUser`,
            'headers': {
                'Authorization': token
            },
        });
        // dispatch(setCurrentUser(respData.data.result))
        return true
    }
    catch (err) {
        return false
    }
}



export const forgotPassword = async (data) => {
    console.log("dasdasdadasdadasda *******************")
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/forgotPassword`,
            data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const forgotPasswordMobile = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/forgotPassword/mobile`,
            data
        });
        return {
            status: "success",
            loading: false,
            messages: respData.data.messages
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const resetPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/resetPassword`,
            data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.message
        }
    }
}

export const logout = (history, dispatch) => {
    removeAuthToken();
    //dispatch two reducer auth reducer,accout.js 
    dispatch({
        type: LOGOUT,
        data: {
            'userId': "",
            'firstName': "",
            'lastName': "",
            'email': "",
            'blockNo': "",
            'address': "",
            'city': "",
            'state': "",
            'country': "",
            'postalCode': "",
            'emailStatus': "",
            'phoneStatus': "",
            'phonenumber': "",
            'type': "",
            'createAt': "",
            'bankDetail': ""
        }

    })

    toastAlert('success',"Logout Successfully","logout")
    history.push('/login')
}

export const changeAccountEmail = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/account/email`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data
        });

        return {
            status: "success",
            loading: false,
            messages: respData.data.messages
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const userOldEmailVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/api/account/email`,
            data
        });

        return {
            status: "success",
            loading: false,
            messages: respData.data.messages
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const userNewEmailVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `${config.API_URL}/api/account/email`,
            data
        });

        return {
            status: "success",
            loading: false,
            messages: respData.data.messages
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const changeNewPhone = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/account/phone`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data
        });

        return {
            status: "success",
            loading: false,
            messages: respData.data.messages
        }

    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const verifyNewPhone = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/api/account/phone`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data
        });

        return {
            status: "success",
            loading: false,
            messages: respData.data.messages,
            result: respData.data.result
        }

    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const editProfile = async (data) => {
    try {
        const formData = new FormData();
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('profileImage', data.profileImage);

        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/api/account/profile`,
            'headers': {
                'content-type': 'multipart/form-data',
                'Authorization': localStorage.user_token
            },
            'data': formData
        });

        return {
            status: "success",
            loading: false
        }

    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const getGeoInfoData = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.getGeoInfo}`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getUserDetail = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/userProfile`,
            'headers': {
                'Authorization': localStorage.user_token
            }
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const sentOTP = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/sent-otp`,
            data
        });
        return {
            status: "success",
            loading: false,
            messages: respData.data.messages,
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getReferralHistory = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/referralHistory`,
            'headers': {
                'Authorization': localStorage.user_token
            },
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getHomePageBlog = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/getHomePageBlog/`
        });

        return {
            status: respData.data.status,
            data1: respData.data.data1,
            data2: respData.data.data2
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.message 
        }
    }
}
export const getBlogAll = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/getBlogAll`,
            data

        });

        return {
            status: respData.data.status,
            data: respData.data.data,
            nextBtn: respData.data.nextBtn,
            prevBtn: respData.data.prevBtn,
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getBlogBySlug = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api//getBlogBySlug/`,
            'data': data
        });

        return {
            status: respData.data.status,
            data: respData.data.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getBlogCategorySlug = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/getBlogCategorySlug/`,
            'data': data
        });

        return {
            status: respData.data.status,
            data: respData.data.data,
            nextBtn: respData.data.nextBtn,
            prevBtn: respData.data.prevBtn
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getCategory = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/getCategory`,
        });
        // console.log(respData,'respDatarespDatarespData--')
        return {
            status: respData.data.status,
            data: respData.data.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getTrendingPost = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/getTrendingPost/`
        });

        return {
            status: respData.data.status,
            data: respData.data.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const newsLetters = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/newLetters/`,
            data: data
        });
        return {
            status: respData.data.status,
            data: respData.data.message
        }
    }
    catch (err) {
        console.log("errerrerrerrerr",err.response.data.errors)
        return {
            loading: false,
            status: false,
            error: err.response.data.errors
        }
    }
}

