// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (isEmpty(value.email)) {
        errors.email = "Email Field is Required"
    } else if (!(emailRegex.test(value.email))) {
        errors.email = "Invalid Email"
    }

    if (isEmpty(value.password)) {
        errors.password = "Password Field is Required"
    }
    if (isEmpty(value.reCaptcha)) {
        errors.reCaptcha = "Recaptcha Required"
    }
    // if (!(value.isTerms == true)) {
    //     errors.isTerms = "REQUIRED"
    // }

    return errors;
}

export default validation;