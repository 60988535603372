import React, { useEffect } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Checkbox from 'rc-checkbox';
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function SupportTicket(props) {
  const { ...rest } = props;

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages">
            <div className="container">



              <GridContainer>
                <GridItem md={12} className="text-center">
                  <h2 className="main_title">About us</h2>
                </GridItem>
              </GridContainer>


              <div className="supporTicketAccordian faq_accodion wow fadeIn">
                <GridContainer className="mat_top_78">
                  <GridItem xs={12} sm={12} md={12} lg={4} >
                   <img src={require("../assets/images/about_1.png")} alt="" className="img-fluid" />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={8} >
                    <div className="termsection">
                      <h2>About Tradax</h2>
                      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                      
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="mat_top_78">
                  <GridItem xs={12} sm={12} md={12} lg={8} >
                    <div className="termsection">
                      <h2>Tradax Crypto Trading System</h2>
                      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                     
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={4} >
                  <img src={require("../assets/images/about_2.png")} alt="" className="img-fluid" />
                  </GridItem>
                  




                </GridContainer>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
