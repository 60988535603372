export const kycStatus = (status) => {
    switch (status) {
        case 2: return "Pending";
        case 3: return "Verified";
        case 4: return "Rejected";
        default: return "New"
    }
}

export const idProofName = (type, status) => {
    let typeValue = type;
    if (status == 1) {
        typeValue = ''
    }
    switch (typeValue) {
        case 1: return "Passport";
        case 2: return "Driving Licence";
        case 3: return "National Security Card";
        default: return ""
    }
}

export const addressProofName = (type, status) => {
    let typeValue = type;
    if (status == 1) {
        typeValue = ''
    }
    switch (typeValue) {
        case 1: return "Bank Passbook";
        case 2: return "National Card";
        case 3: return "Passport";
        default: return ""
    }
}

export const bankProofName = (type, status) => {
    let typeValue = type;
    if (status == 1) {
        typeValue = ''
    }
    switch (typeValue) {
        case 1: return "Bank Passbook";
        case 2: return "Bank statement";
        default: return ""
    }
}

export const twoFAStatus = (type, callFrom) => {
    if (type == 'enabled' && callFrom == 'status') {
        return "ENABLED"
    } else if (type == 'disabled' && callFrom == 'status') {
        return "DISABLED"
    } else if (type == 'enabled' && callFrom == 'button') {
        return "DISABLED"
    } else if (type == 'disabled' && callFrom == 'button') {
        return "ENABLE"
    } else {
        return ""
    }
}

export const documentStatus = (status) => {
    switch (status) {
        case 'pending': return "NOT_VERIFIED";
        case 'approved': return "VERIFIED";
        case 'rejected': return "REJECTED";
        default: return ""
    }
}

export const documentType = (status) => {
    switch (status) {
        case 'license': return "DRIVING_LICENSE";
        case 'pan': return "PANCARD";
        case 'aadhar': return "AADHAR_CARD";
        case 'gas': return "GAS_BILL";
        default: return ""
    }
}

export const userStatus = (status) => {
    switch (status) {
        case 'verified': return "VERIFIED";
        case 'unverified': return "NOT_VERIFIED";
        case 'basic': return "BASIC";
        case 'advanced': return "ADVANCED";
        case 'pro': return "PRO";
        default: return ""
    }
}

export const transactionStatus = (status) => {
    switch (status) {
        case 'fiat_deposit': return "Deposit";
        case 'fiat_withdraw': return "Withdraw";
        case 'coin_deposit': return "Deposit";
        case 'coin_withdraw': return "Withdraw";
        default: return ""
    }
}

export const triggerCondition = (status) => {
    switch (status) {
        case 'equal': return "Deposit";
        case 'greater_than': return "Withdraw";
        case 'lesser_than': return "Deposit";
        default: return "-"
    }
}