// import package
import React, {useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import { Hidden, Button, Menu, MenuItem } from '@material-ui/core';
import { useRouteMatch } from "react-router-dom";
// import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components

import moment from 'moment';
// import action
import { logout } from '../../actions/users';
import { setTradeTheme } from '../../actions/commonAction'

import { setTheme } from '../../actions/commonAction'

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { getNotificationHistory } from "../../actions/dashboardAction";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

  const history = useHistory();
  const dispatch=useDispatch();
  const routeMatch = useRouteMatch();
  const classes = useStyles();


  const[userId,setuserId]=useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState([]);
  const [readdata, setReadData] = useState();

  // redux state 
  const tradeThemeData = useSelector(state => state.tradeTheme)
  const { isAuth } = useSelector(state => state.auth);
  const authDetails = useSelector(state => state.auth);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const [anchorE2, setAnchorE2] = React.useState(null);
  
  const handleClose1 = () => {
    setAnchorE2(null);
  };
  
  const [anchorE3, setAnchorE3] = React.useState(null);
  const handleClick2 = (event) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE3(null);
  };

  const [anchorE4, setAnchorE4] = React.useState(null);
  const handleClick3 = (event) => {
    setAnchorE4(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorE4(null);
  };
  const [anchorE5, setAnchorE5] = React.useState(null);
  const handleClick4 = (event) => {
    setAnchorE5(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorE5(null);
  };
  
  useEffect(() => {
    
    if(authDetails.userId){
     
      fetchNotificationHistory(authDetails.userId);
      setuserId(authDetails.userId);
   }
}, [authDetails])
const fetchNotificationHistory = async (user) => {
  try {
    let reqData = {
      curUser:user,
       }
      const { status, loading, result } = await getNotificationHistory(reqData);
    //  setLoader(loading);
   //   setData(result);
  
      if (status == 'success') {
        let i=0;
        setData(result);
        result.map((item,key)=>{
          if(item.admin_read==false)
          {
            i=i+1
          }
         
         
        })
        setReadData(i)
        console.log("Item---",i)
      }
    
  } catch (err) { }
}

  const themeData = useSelector(state => state.theme)
    return (
    <div className="inner_page_menu">
      <div className="dashboard_login">
        <Hidden smDown>
        {
            isAuth==true?(
            <>
          <ul className="list-iline">
            {/* {
              ['/spot/:tikerRoot?', '/derivative/:tikerRoot?'].includes(routeMatch.path) && <li>
                <div className="toggleMode" title="toggle dark mode">
                  <label>
                    <input type="checkbox" checked={tradeThemeData == 'light' ? true : false} name="" onClick={() => setTradeTheme(dispatch, tradeThemeData == 'light' ? 'dark' : 'light')} />
                    <span></span>
                  </label>
                </div>
              </li>
            } */}

            {/* <li >
            <input type="checkbox" id="toggle_checkbox" checked={tradeThemeData == 'light' ? true : false} name="" onClick={() => setTradeTheme(dispatch, tradeThemeData == 'light' ? 'dark' : 'light')}/>

              <label for="toggle_checkbox" className="radio_dark">
                <div id="star">
                  <div class="star" id="star-1">★</div>
                  <div class="star" id="star-2">★</div>
                </div>
                <div id="moon"></div>
              </label>
            </li> */}
            {
              isAuth &&
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            } 
            {
              isAuth &&
              <li>
                <Link to="/spot">Spot</Link>
              </li>
            }
            {
              isAuth &&
              <li>
                <Button aria-controls="profile_menu" aria-haspopup="true" onClick={handleClick2}>
                  P2P
                </Button>
                <Menu
                  id="profile_menu"
                  anchorEl={anchorE3}
                  keepMounted
                  open={Boolean(anchorE3)}
                  onClose={handleClose2}
                >
                  <MenuItem><Link to="/p2p">P2P</Link></MenuItem>
                  <MenuItem><Link to="/postmyad">Post a trade</Link></MenuItem>
                </Menu>
              </li>
            }
            {
            isAuth &&
              <li>
                <Button aria-controls="profile_menu" aria-haspopup="true" onClick={handleClick3}>
                  Wallet
                </Button>
                <Menu
                  id="profile_menu"
                  anchorEl={anchorE4}
                  keepMounted
                  open={Boolean(anchorE4)}
                  onClose={handleClose3}
                >
                  <MenuItem><Link to="/wallet">Spot Wallet</Link></MenuItem>
                  <MenuItem><Link to="/P2pwallet">P2p Wallet</Link></MenuItem>
                </Menu>
              </li>
            }
            {/* <li>
              <Link to="/wallet">Wallet</Link>
            </li> */}
            {/* <li>
              <Link to="">Staking</Link>
            </li> */}
             {
            isAuth &&
            <li>
              <span className="notify_count">{readdata && readdata}</span>
              {console.log(readdata,"datassssssssss")}
              <Button aria-controls="profile_menu" aria-haspopup="true" onClick={handleClick4}>
                <i className="fas fa-bell"></i>
                  </Button>
                  <Menu
                    id="profile_menu"
                    anchorEl={anchorE5}
                    keepMounted
                    open={Boolean(anchorE5)}
                    onClose={handleClose4}
                  >
                    {readdata > 0 ?
                    <MenuItem><Link to="/notification">View Notifications</Link></MenuItem> : <></>}
                    {/* <MenuItem><Link to="/tradenotification">Trade Notifications</Link></MenuItem> */}
                  </Menu>
             {/* <Link to="/notification"> <i className="fas fa-bell"></i></Link> */}
             
            </li>
              }
            <li>
              <Button aria-controls="profile_menu" aria-haspopup="true" onClick={handleClick}>
                <i className="fas fa-user"></i>
              </Button>
              <Menu
                id="profile_menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem><Link to="/profile">Profile</Link></MenuItem>
                <MenuItem><Link to="/kyc">KYC</Link></MenuItem>
                <MenuItem><Link to="/settings">Settings</Link></MenuItem>
                <MenuItem><Link to="/history">Transaction History</Link></MenuItem> 
                <MenuItem><Link to="/trade-history">Trade History</Link></MenuItem>  {/* to="/history"*/}
                 {/* to="/history"*/}
                <MenuItem><Link to="/support-ticket">Support</Link></MenuItem>
                <MenuItem><Link to="#" onClick={() => logout(history,dispatch)}>Logout</Link></MenuItem>
              </Menu>
            </li>
          </ul>
            </>
            ):
            (
              <>
               <ListItem className={classes.listItem}>
          <Link to="/login" color="transparent" className="nav-link home_menu_btn">Login</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/register" color="transparent" className="nav-link home_menu_btn">Sign Up</Link>
        </ListItem>
              </>
            )
          }
        
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <ul className="list-iline">
            <li>
              <Link to="/spot">Spot</Link>
            </li>
            <li>
              <Link to="/p2p">P2P</Link>
              {/* <Link to="/#">P2P</Link> */}

            </li>
            {/* <li>
              <Link to="">Derivative</Link>
            </li> */}
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/wallet">Wallet</Link>
            </li>
            {/* <li>
              <Link to="">Staking</Link>
            </li> */}
            <li>
              <Link to="notification">Notifications</Link>
            </li>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <Link to="/kyc">KYC</Link>
            </li>
            <li>
              <Link to="/settings">Settings</Link>
            </li>
            <li>
              <Link to="/history">Transaction History</Link>
            </li>
            <li>
              <Link to="/trade-history">Trade History</Link>
            </li>
            <li>
              <Link to="/support-ticket">Support</Link>
            </li>
            <li>
              <Link to="#" onClick={() => logout(history)}>Logout</Link>
            </li>
          </ul>
        </Hidden>
      </div>
    </div>
  );
}
