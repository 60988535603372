import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getSpotPair } from "actions/commonAction";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
// import components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { getHomePageBlog } from "../actions/users";
import { setLocalSpot } from "../actions/spotTradeAction";
import styles from "assets/jss/material-kit-react/views/home.js";
import MarketTrend from '../components/home/marketTrend'
import { getCmsall } from "actions/commonAction";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
} from "@material-ui/core";
import SocketContext from "../components/Context/SocketContext";
import keys from "../config";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from "react-moment";
import "moment-timezone";
import config from "../lib/config";
import { toFixed } from "../lib/roundOf";
import { getMarketTrend } from "../actions/homeAction";
import { toastAlert } from "../lib/toastAlert";
const url = keys.API_URL;

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  // const { buyorsell } = props;
  const [expanded, setExpanded] = React.useState("panel1");
  const [spotpair, setSpotPair] = useState([]);
  const [imgname, setImgname] = useState("");
  const [quant, setQuant] = useState("");
  const [curValue, setcurValue] = useState();
  const [tocurrency, setToCurrency] = useState();
  const socketContext = useContext(SocketContext);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [pairData, setPairData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [blogData1, setBlogData1] = useState();
  const [blogData2, setBlogData2] = useState();
  useEffect(() => {
    getBlogData();
    getcurrencyDetails();
    fetchMarketTrend();

    socketContext.socket.on("marketPrice", (result) => {
      setPairData((el) => {
        let pairList = [];
        el.map((item) => {
          if (item._id == result.pairId) {
            pairList.push({
              ...item,
              ...{
                markPrice: toFixed(result.data.markPrice, 2),
                change: toFixed(result.data.change, 2),
              },
            });
          } else {
            pairList.push(item);
          }
        });

        // let pairIndex = pairList.findIndex((item => item._id == result.pairId))
        // if (pairIndex >= 0) {
        //     pairList[pairIndex].markPrice = result.data.markPrice
        //     pairList[pairIndex].change = result.data.change
        // }
        return pairList;
      });
    });

    return () => {
      socketContext.socket.off("marketPrice");
    };
  }, []);

  const getcurrencyDetails = async () => {
    try {
      const { result, status, loading } = await getSpotPair();

      setSpotPair(result);
      setImgname(result[0].firstCurrencySymbol);
      setcurValue(result[0].markPrice);
      setToCurrency(result[0].secondCurrencySymbol);
    } catch (err) { }
  };

  const fetchMarketTrend = async () => {
    try {
      const { status, loading, result } = await getMarketTrend();
      if (status == "success") {
        setPairData(result);
        //  console.log("resultresultresult",result);
      }
    } catch (err) { }
  };

  const setQuantity = (e) => {
    //  e.preventDefault();
    setQuant(e.target.value);

    //console.log("Quantity---",quant);
  };

  const getBlogData = async () => {
    const { status, data1, data2 } = await getHomePageBlog();

    if (status) {
      setBlogData1(data1);
      setBlogData2(data2);
    }
  };
  const setInstantSpotBuy = async (e) => {
    if (imgname == "") {
      toastAlert("error", "Select Currency", "home");
    }
    if (quant == "") {
      toastAlert("error", "Enter Quantity", "home");
    }
    if (quant != "" && imgname != "") {
      let reqdata = {
        quantity: quant,
        markPrice: curValue,
        buyorsell: "Buy",
        currency: imgname,
      };

      // setLocalSpot(reqdata,dispatch)
      history.push("/spot/" + imgname + "_USDT");
    }
  };

  const setInstantSpotSell = async (e) => {
    if (imgname == "") {
      toastAlert("error", "Select Currency", "home");
    }
    if (quant == "") {
      toastAlert("error", "Enter Quantity", "home");
    }
    if (quant != "" && imgname != "") {
      let reqdata = {
        quantity: quant,
        markPrice: curValue,
        buyorsell: "Sell",
        currency: imgname,
      };
      //setLocalSpot(reqdata,dispatch)
      history.push("/spot/" + imgname + "_USDT");
    }
  };

  const spotHandleChange = async (e) => {
    // console.log("Value---",e.item)
    if (e.item) {
      setImgname(e.item.firstCurrencySymbol);
      setcurValue(e.item.markPrice);
      setToCurrency(e.item.secondCurrencySymbol);
    }
  };

  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // var settings = {
  //   dots: true,
  //   arrows: false,
  //   infinite: true,
  //   autoplaySpeed: 1000,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  // };
  var newsSlide = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const [anchorE2, setAnchorE2] = React.useState(null);
  const handleClick1 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorE2(null);
  };
  const { ...rest } = props;



  const [loader, setLoader] = useState(true);
  const [cms, setCms] = useState([]);

  const getCmsData = async () => {
    try {
      let { status, result, message } = await getCmsall();
      setLoader(false);
      if (status) {
        setCms(result);
      }
    } catch (err) {
      console.log(err, 'error')
    }
  };

  useEffect(() => {
    getCmsData();
  }, []);

  const createMarkup = (a) => {

    return { __html: a };
  }

  // console.log("cms", cms);
  return (
    <div className="page_wrap">
      <ScrollToTopOnMount />
      <Header
        className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <img
            src={require("../assets/images/logo.png")}
            alt="logo"
            className="img-fluid"
          />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest}
      />
      <div className="banner_container home_bg pt-5 pb-lg-0 p-md-5">
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={8} md={6} lg={6}>
              <div className="padding_zeeor" data-aos="fade-up" data-aos-duration="2000">
                {/* <h1 className="mb-0">
                  <span>Tradax</span> Most Trusted CryptoCurrency
                  <span>Exchange</span>
                </h1>
                <p>Easiest place to buy and sell digital assets. </p> */}
                {
                  cms && cms.length > 0 &&
                  <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Tradax Most Trusted CryptoCurrency Exchange")].content)}></div>
                }



                <Link to={"/spot"} >
                  <Button className="btn_bnner_new">trade now</Button>
                </Link>
                <div className="banner_calculator_panel hidden">
                  <div className="banner_calculator_equ">
                    <div className="home_drop_down">
                      <button
                        type="button"
                        className="selectPair dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {imgname == "" || imgname == undefined ? (
                          <span>
                            <img
                              src={require("../assets/images/cryptoicons/BTC.png")}
                            ></img>
                          </span>
                        ) : (
                          <span>
                            <img
                              src={require("../assets/images/cryptoicons/" +
                                imgname +
                                ".png")}
                            ></img>
                          </span>
                        )}
                        {
                          imgname != "" ||
                          (imgname != undefined && (
                            <span>
                              <img
                                src={require("../assets/images/cryptoicons/BTC.png")}
                              ></img>
                            </span>
                          ))

                          // <span><img src={require("../assets/images/cryptoicons/"+imgname+".svg")}></img></span>
                        }
                        {/* <img src={require("../assets/images/cryptoicons/"+imgname+".png")}></img> */}
                        <span>
                          {imgname}-{tocurrency}
                        </span>
                      </button>
                      <div
                        className="dropdown-menu"
                        id="pairDrop"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {/* <div class="search_price_section">
                                        <input type="text" placeholder="search" />
                                        <button>Search</button>
                                    </div> */}
                        <table class="table pairDropTable">
                          <thead>
                            <tr>
                              <th>Price</th>
                              <th>Latest Price</th>
                              <th>change</th>
                              {/* <th>24h Volume</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {spotpair &&
                              spotpair.length > 0 &&
                              spotpair.map((item, i) => {
                                // console.log(item, 'itemitem')
                                return (
                                  <tr>
                                    <td
                                      className="text-white"
                                      value={item.firstCurrencySymbol}
                                      onClick={(e) =>
                                        spotHandleChange({ item })
                                      }
                                    >
                                      {item.firstCurrencySymbol}
                                      <span>/{item.secondCurrencySymbol}</span>
                                    </td>
                                    <td className="balance_amt_detail">
                                      <span className="price_increase">
                                        {item.markPrice}
                                      </span>
                                      {/* <span>/$314.5</span> */}
                                    </td>
                                    <td>
                                      <span className="span_menu_gerrn">
                                        {item.change}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div>
                      {
                        imgname =="" || imgname == undefined ?
                          <span><img src={require("../assets/images/cryptoicons/BTC.png")}></img></span> :
                          <span><img src={require("../assets/images/cryptoicons/"+imgname+".png")}></img></span> 

                      }
                      {
                        imgname !="" || imgname != undefined &&
                        <span><img src={require("../assets/images/cryptoicons/BTC.svg")}></img></span> 

                          // <span><img src={require("../assets/images/cryptoicons/"+imgname+".svg")}></img></span> 
                      }
                     <Select
                                onChange={(e)=>spotHandleChange(e.target.value)}
                            >
                               { spotpair && spotpair.length > 0 && spotpair.map((item, i) => {
                            return(
                        <MenuItem value={item}>{item.firstCurrencySymbol} - {item.secondCurrencySymbol}</MenuItem>
                       
                      );
                        })
                      }
                               
                            </Select>
                      </div> */}
                    {/* <div>
                      
                      <select class="currency_select">
                        <option value="en" class="test" data-thumbnail={require("../assets/images/cryptoicons/BTC.svg")}>BTC</option>
                        <option value="au" data-thumbnail={require("../assets/images/cryptoicons/BUSD.svg")}>BUSD</option>
                        <option value="au" data-thumbnail={require("../assets/images/cryptoicons/ETH.svg")}>ETH</option>
                        <option value="uk" data-thumbnail={require("../assets/images/cryptoicons/DOGE.svg")}>DOGE</option>
                        <option value="de" data-thumbnail={require("../assets/images/cryptoicons/USDT.svg")}>USDT</option>
                        <option value="dk" data-thumbnail={require("../assets/images/cryptoicons/XMR.svg")}>XMR</option>
                        <option value="dk" data-thumbnail={require("../assets/images/cryptoicons/DASH.svg")}>DASH</option>
                        { spotpair.length > 0 && spotpair.map((item, i) => {
                          console.log("Spot pair---",item.firstCurrencySymbol);
                      return(
                        <option value={item.firstCurrencySymbol} class="test" data-thumbnail={require("../assets/images/cryptoicons/"+item.firstCurrencySymbol+".svg")}>{item.firstCurrencySymbol}</option>
                      );
                        })
                      }
                      </select>
                      <div class="lang_select">
                        <button class="btn_select" value=""></button>
                        <div class="currency_select_list_div">
                          <ul id="currency_select_list">
                            
                          </ul>
                        </div>
                      </div>
                    </div> */}

                    <h4>
                      <span className="whiteText">=</span> {curValue}
                      {tocurrency}
                    </h4>
                  </div>
                  <div className="banner_calculator_buy">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Quantity"
                        name="quantity"
                        onChange={setQuantity}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">
                          {imgname}
                        </span>
                      </div>
                    </div>
                    <Button
                      className="small_btn small_buy_btn"
                      onClick={setInstantSpotBuy}
                    >
                      Buy
                    </Button>
                    <Button
                      className="small_btn small_sell_btn"
                      onClick={setInstantSpotSell}
                    >
                      Sell
                    </Button>
                    <Button
                      className="small_btn small_trade_btn"
                      onClick={setInstantSpotBuy}
                    >
                      Trade
                    </Button>
                  </div>
                </div>

              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={6} lg={6}>
              <div className="" data-aos="fade-up" data-aos-duration="2000">
                <div className=" widht90 text-center">
                  <img
                    alt="Icon"
                    src={require("../assets/images/banner_right_img.png")}
                    className="img-fluid"
                  />
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      {/* Ticker Section */}

      <MarketTrend />
      <section className="section trade_easy_section">
        <div className="container">
          <GridContainer>
            <GridItem md={10} className="m-auto">
              <div className="trade_easy_panel" data-aos="fade-up" data-aos-duration="2000">
                <div>
                  <img
                    alt="Shape"
                    src={require("../assets/images/shape_01.png")}
                    className="shape_img_first"
                  />
                </div>
                <div>
                  <img
                    alt="Shape"
                    src={require("../assets/images/shape_02.png")}
                    className="shape_img_second"
                  />
                </div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    {/* <h2 className="main_title">Trade Easy</h2>
                    <p className="main_content">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour, or randomised words which
                      don't look even slightly believable. If you are going to
                      use a passage of Lorem Ipsum, you need to be sure there
                      isn't anything embarrassing hidden in the middle of text.
                    </p> */}
                    {
                      cms && cms.length > 0 &&
                      <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Trade Easy")].content)}></div>
                    }
                    {/* <div className="app_btn_grp">
                      <a href="#"
                      // target="_blank"
                      >
                        <img
                          alt="App"
                          src={require("../assets/images/google_play.png")}
                        />
                      </a>
                      <a href="#"
                      //  target="_blank"
                      >
                        <img
                          alt="App"
                          src={require("../assets/images/app_store.png")}
                        />
                      </a>
                    </div> */}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <div className="app_image" data-aos="fade-up" data-aos-duration="2000"></div>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </section>
      {/* Features Section */}
      <section className="section features_section">
        <div className="container">
          <GridContainer>
            <GridItem md={12} className="text-center">
              {/* <h2 className="main_title">Key Features</h2> */}
              {
                cms && cms.length > 0 &&
                <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Key Features")].content)}></div>
              }
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={10} className="m-auto">
              <GridContainer>
                {
                  cms && cms.length > 0 && cms.map((item, key) => {
                    // console.log(item, 'iiiiiiiiiiiiiii')
                    return (
                      <>
                        {item.title == 'landing_key' &&
                          < GridItem xs={12} sm={6} md={6} lg={6} >

                            <div className="features_panel" data-aos="fade-up" data-aos-duration="2000">
                              <div>

                                < div dangerouslySetInnerHTML={createMarkup(item.content)}></div>

                              </div>
                              <img
                                src={`${url}/images/Homeimages/${item.image}`}
                                style={{ width: "80px" }}
                                alt=""
                              />
                            </div>
                          </GridItem>
                        }
                      </>

                    )
                  })
                }
                {/* <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="features_panel" data-aos="fade-up" data-aos-duration="2000">
                    <div>
                      <h2>Instant Trade</h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Instant Trade")].content)}></div>
                      }
                    </div>
                    <img
                      alt="App"
                      src={require("../assets/images/feature_02.png")}
                    />
                  </div>
                </GridItem> */}
                {/* <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="features_panel" data-aos="fade-up" data-aos-duration="2000">
                    <div>
                      <h2>Secured Escrow Service</h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Secured Escrow Service")].content)}></div>
                      }
                    </div>
                    <img
                      alt="App"
                      src={require("../assets/images/feature_03.png")}
                    />
                  </div>
                </GridItem> */}
                {/* <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="features_panel" data-aos="fade-up" data-aos-duration="2000">
                    <div>
                      <h2>Free Hot Wallet</h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Free Hot Wallet")].content)}></div>
                      }
                    </div>
                    <img
                      alt="App"
                      src={require("../assets/images/feature_04.png")}
                    />
                  </div>
                </GridItem> */}
                {/* <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="features_panel" data-aos="fade-up" data-aos-duration="2000">
                    <div>
                      <h2>Worldwide Service</h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Worldwide Service")].content)}></div>
                      }
                    </div>
                    <img
                      alt="App"
                      src={require("../assets/images/feature_05.png")}
                    />
                  </div>
                </GridItem> */}
                {/* <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="features_panel" data-aos="fade-up" data-aos-duration="2000">
                    <div>
                      <h2>24/7 Customer Service</h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Customer Service")].content)}></div>
                      }
                    </div>
                    <img
                      alt="App"
                      src={require("../assets/images/feature_06.png")}
                    />
                  </div>
                </GridItem> */}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </section >
      {/* Peer to peer section */}
      < section className="section peer_section" >
        <div className="container">
          <GridContainer>
            <GridItem md={12} className="text-center">
              {/* <h2 className="main_title">Peer-to-Peer Trading Very Easy</h2> */}
              {
                cms && cms.length > 0 &&
                <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Peer-to-Peer Trading Very Easy")].content)}></div>
              }
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <img
                alt="App"
                src={require("../assets/images/peer_img.png")}
                className="img-fluid for_mobile_o"
                data-aos="fade-up" data-aos-duration="2000"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8} lg={8}>
              <GridContainer data-aos="fade-up" data-aos-duration="2000">
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="peer_to_peer_section border_bottm">
                    <span>1.</span>
                    <div>
                      <img
                        alt="App"
                        src={require("../assets/images/peer_1.png")}
                        className="img-fluid"
                      />
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Create an Account")].content)}></div>
                      }
                      {/* <h3>Create an Account</h3>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p> */}
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="peer_to_peer_section border_bottm_left border_left_bottm">
                    <span>2.</span>
                    <div>
                      <img
                        alt="App"
                        src={require("../assets/images/peer_2.png")}
                        className="img-fluid"
                      />
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Verify Your Identity")].content)}></div>
                      }
                      {/* <h3>Verify Your Identity</h3>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p> */}
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="peer_to_peer_section">
                    <span>3.</span>
                    <div>
                      <img
                        alt="App"
                        src={require("../assets/images/peer_3.png")}
                        className="img-fluid"
                      />
                      {/* <h3>Buy or Sell Crypto</h3>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p> */}
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Buy or Sell Crypto")].content)}></div>
                      }
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className="peer_to_peer_section border_left">
                    <span>4.</span>
                    <div>
                      <img
                        alt="App"
                        src={require("../assets/images/peer_4.png")}
                        className="img-fluid"
                      />
                      {
                        cms && cms.length > 0 &&
                        <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Admin Release Escrow")].content)}></div>
                      }
                      {/* <h3>Admin Release Escrow</h3>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p> */}
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </section >
      {/* Take control section */}
      < section className="section bg_take_control" >
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <img
                alt="App"
                src={require("../assets/images/take_control.png")}
                className="img-fluid"
                data-aos="fade-up" data-aos-duration="2000"
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={8} data-aos="fade-up" data-aos-duration="2000">
              {/* <h2 className="main_title">Take Control Today</h2>
              <p className="pad_buttom_po">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p> */}
              {
                cms && cms.length > 0 &&
                <div dangerouslySetInnerHTML={createMarkup(cms[cms.findIndex(value => value.identifier === "Take Control Today")].content)}></div>
              }

              <Link to="/login">
                <button className="btn_bnner_new border_radions">
                  Start Earning Today
                </button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </section >
      {/* Crypto News */}
      < section className="section peer_section slider_cus" >
        <div className="container">
          <GridContainer>
            <GridItem md={12} className="text-center">
              <h2 className="main_title">Latest insights</h2>
            </GridItem>
          </GridContainer>
          <Slider {...newsSlide}>
            <GridContainer>
              <GridItem md={12}>
                <GridContainer className="border_for2">
                  {blogData1 &&
                    blogData1.length > 0 &&
                    blogData1.map((item, i) => {
                      return (
                        <GridItem
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className="text-center"
                        >
                          <Link to={"/blog-description/" + item.slug}>
                            <div className="news_section">
                              <div className="blog_description_img_">
                                <img
                                  alt="App"
                                  src={`${config.API_URL}/images/blogImages/${item.image}`}
                                  className="img-fluid"
                                />
                              </div>
                              <div className="news_content">


                                <span className="newstag">
                                  {item.blog_category.category_name &&
                                    item.blog_category.category_name}
                                </span>
                                <h3>{item.title}</h3>
                                <p>
                                  <span className="first_span">
                                    {item.author}
                                  </span>
                                  <span>
                                    <Moment format="MMMM DD,YYYY">
                                      {item.created_date}
                                    </Moment>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </GridItem>
                      );
                    })}
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem md={12}>
                <GridContainer className="border_for2">
                  {blogData2 &&
                    blogData2.length > 0 &&
                    blogData2.map((item, i) => {
                      return (
                        <GridItem
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className="text-center"
                        >
                          <Link to={"/blog-description/" + item.slug}>
                            <div className="news_section">
                              <div className="blog_description_img_">
                                <img
                                  alt="App"
                                  src={`${config.API_URL}/images/blogImages/${item.image}`}
                                  className="img-fluid"
                                />
                              </div>
                              <div className="news_content">
                                <span className="newstag">
                                  {item && item.blog_category.category_name}
                                </span>
                                <h3>{item.title}</h3>
                                <p>
                                  <span className="first_span">
                                    {item.author}
                                  </span>
                                  <span>
                                    <Moment format="MMMM DD,YYYY">
                                      {item.created_date}
                                    </Moment>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </GridItem>
                      );
                    })}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Slider>
        </div>
      </section >

      {/* <div className="explore pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center content-container">
              <h1 className="mb-4 heading-title" data-aos="fade-up">Explore Our Markets</h1>
              <h3 className="heading-subtitle" data-aos="fade-up">The World's Leading Tradax Currency Exchange</h3>
              <p className="mb-4 heading-text" data-aos="fade-up">Trade Bitcoin, ETH, and hundreds of other Tradax Currencies in minutes.</p>
              <div className="table-responsive pb-4" data-aos="fade-up">
                <table className="common-table">
                  <thead>
                    <th width="13%">Name</th>
                    <th width="17%"> </th>
                    <th width="15%">Last Price</th>
                    <th width="16%">24H change</th>
                    <th width="18%">Market</th>
                    <th width="8%"> </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img src={require("../assets/images/coin1.png")} className="coin_logo mr-2" alt="" />
                        <label>BNB</label>
                      </td>
                      <td><span>BNB</span></td>
                      <td>$29.13</td>
                      <td className="text-green">+7.32%</td>
                      <td><img src={require("../assets/images/graph.png")} alt="" /></td>
                      <td><Button className="primary_btn">Buy</Button></td>
                    </tr>
                    <tr>
                      <td>
                        <img src={require("../assets/images/coin2.png")} className="coin_logo mr-2" alt="" />
                        <label>BTC</label>
                      </td>
                      <td><span>Bitcoin</span></td>
                      <td>$15,450.00</td>
                      <td className="text-green">+5.31%</td>
                      <td><img src={require("../assets/images/graph.png")} alt="" /></td>
                      <td><Button className="primary_btn">Buy</Button></td>
                    </tr>
                    <tr>
                      <td>
                        <img src={require("../assets/images/coin3.png")} className="coin_logo mr-2" alt="" />
                        <label>ETH</label>
                      </td>
                      <td><span>Ethereum</span></td>
                      <td>$438.89</td>
                      <td className="text-red">-9.34%</td>
                      <td><img src={require("../assets/images/graph.png")} alt="" /></td>
                      <td><Button className="primary_btn">Buy</Button></td>
                    </tr>
                    <tr>
                      <td>
                        <img src={require("../assets/images/coin4.png")} className="coin_logo mr-2" alt="" />
                        <label>XRP</label>
                      </td>
                      <td><span>Ripple</span></td>
                      <td>$0.257930</td>
                      <td className="text-green">+8.28%</td>
                      <td><img src={require("../assets/images/graph.png")} alt="" /></td>
                      <td><Button className="primary_btn">Buy</Button></td>
                    </tr>
                    <tr>
                      <td>
                        <img src={require("../assets/images/coin5.png")} className="coin_logo mr-2" alt="" />
                        <label>BCH</label>
                      </td>
                      <td><span>Bitcoin Cash</span></td>
                      <td>$255.66</td>
                      <td className="text-green">+5.23%</td>
                      <td><img src={require("../assets/images/graph.png")} alt="" /></td>
                      <td><Button className="primary_btn">Buy</Button></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6" className="text-center">
                        <Link to="" className="table_view">VIEW MORE MARKETS</Link>
                      </td>
                    </tr>
                  </tfoot>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="home_calculator bg-gray">
        <div className="container content-container">
          <div className="row">
            <div className="col-lg-5 py-5" data-aos="fade-up">
              <h4 className="pr-lg-4 mb-4">Buy crypto with a debit or credit card</h4>
              <h5>Quickly purchase top cryptocurrencies</h5>
              <p className="pr-lg-5 mb-4">Become a crypto owner in minutes using your debit or credit card.</p>
              <h5>Easily withdraw your money</h5>
              <p className="pr-lg-5 mb-4">Withdraw your funds to a card or bank account in a few clicks.</p>
              <p>We accept <img src={require("../assets/images/card.png")} alt="" /></p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6" data-aos="fade-up">
              <div className="calculate_currency p-md-5 p-3">
                <h1>Calculator</h1>
                <h5>Currency Calculator</h5>
                <div className="form-group row mb-3">
                  <div className="col-md-8 mb-3">
                    <div className="form-input">
                      <label>Get</label>
                      <input type="text" placeholder="0.0539" />
                    </div>
                  </div>
                  <div className="col-md-4 pl-md-0 mb-3">
                    <div className="form-input">
                      <Button aria-controls="coin_dropdown" aria-haspopup="true" onClick={handleClick}>
                        <img src={require("../assets/images/coin2.png")} alt="" className="mr-2" />
                        <span>BTC</span>
                      </Button>
                      <Menu
                        id="coin_dropdown"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}>
                        <MenuItem onClick={handleClose}>BTC</MenuItem>
                        <MenuItem onClick={handleClose}>BTC</MenuItem>
                        <MenuItem onClick={handleClose}>BTC</MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <div className="col-md-8">
                    <div className="form-input">
                      <label>Pay</label>
                      <input type="text" placeholder="0.0539" />
                    </div>
                  </div>
                  <div className="col-md-4 pl-md-0">
                    <div className="form-input">
                      <Button aria-controls="coin_dropdown" aria-haspopup="true" onClick={handleClick1}>
                        <img src={require("../assets/images/coin3.png")} alt="" className="mr-2" />
                        <span>ETH</span>
                      </Button>
                      <Menu
                        id="coin_dropdown"
                        anchorEl={anchorE2}
                        keepMounted
                        open={Boolean(anchorE2)}
                        onClose={handleClose1}>
                        <MenuItem onClick={handleClose1}>ETH</MenuItem>
                        <MenuItem onClick={handleClose1}>ETH</MenuItem>
                        <MenuItem onClick={handleClose1}>ETH</MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-md-12">
                    <span>Min: 20 Max: 10000</span>
                  </div>
                </div>
                <div className="form-group row"><div className="col-md-12">
                  <button className="btn btn-secondary text-uppercase w-100">
                    Buy Now
                  </button>
                </div></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="features_content py-5 mt-md-4">
        <div className="container content-container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="heading-title mb-4" data-aos="fade-up">Features</h1>
              <h3 className="heading-subtitle" data-aos="fade-up">The Most Trusted Tradax Currency Platform</h3>
              <p className="heading-text" data-aos="fade-up" data-aos-delay="100">Here are a few reasons why you should choose Tradax</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 my-4" data-aos="fade-up">
              <div className="feature_list feature_list1 text-center"><div className="feature_text">
                <img src={require("../assets/images/feature1.png")} alt="" />
                <h5>Secure Storage</h5>
                <p className="heading-text px-lg-4">We store the vast majority of the digital assets in secure offline storage.</p>
                <Link to="">Learn more...</Link>
              </div>
              </div></div>
            <div className="col-md-4 my-4" data-aos="fade-up" data-aos-delay="200">
              <div className="feature_list feature_list2 text-center"><div className="feature_text">
                <img src={require("../assets/images/feature2.png")} alt="" />
                <h5>Protected by Insurance</h5>
                <p className="heading-text px-lg-4">Tradax Currency stored on our servers is covered by our insurance policy.</p>
                <Link to="">Learn more...</Link>
              </div>
              </div></div>
            <div className="col-md-4 my-4" data-aos="fade-up">
              <div className="feature_list feature_list3 text-center"><div className="feature_text">
                <img src={require("../assets/images/feature3.png")} alt="" />
                <h5>Industry Best Practices</h5>
                <p className="heading-text px-lg-4">Tradax Currency supports a variety of the most popular digital currencies.</p>
                <Link to="">Learn more...</Link>
              </div>
              </div></div>
          </div>
        </div>
      </div>

      <div className="bg-gray start_trading py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center content-container">
              <h1 className="mb-4 heading-title" data-aos="fade-up">Start trading in a few minutes</h1>
              <p className="mb-5 heading-text" data-aos="fade-up">Tradax supports a variety of the most popular digital currencies.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 pr-md-0 text-md-right text-center">
              <label className="text-center">
                <img src={require("../assets/images/trade1.png")} alt="" />
                <p className="heading-text mt-3">Create an account</p>
              </label>
            </div>
            <div className="col-md-4 px-md-0 d-md-block d-none">
              <span></span>
            </div>
            <div className="col-md-4 col-sm-6 pl-md-0 text-md-left text-center">
              <label className="text-center">
                <img src={require("../assets/images/trade2.png")} alt="" />
                <p className="heading-text mt-3">Start buying & selling</p>
              </label>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div >
  );
}