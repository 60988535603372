// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {};
    let mobileRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (isEmpty(value.firstName)) {
        errors.firstName = "REQUIRED"
    }
    if (isEmpty(value.lastName)) {
        errors.lastName = "REQUIRED"
    }
    if (isEmpty(value.newPhoneCode)) {
        errors.newPhoneNo = "Please enter your Mobile code"
    } 
    if (isEmpty(value.newPhoneNo)) {
        errors.newPhoneNo = "Please enter your phone number"
    } else if (!mobileRegex.test(value.newPhoneNo)) {
        errors.newPhoneNo = "Phone number is invalid";
    }
    // if (isEmpty(value.blockNo)) {
    //     errors.blockNo = "REQUIRED"
    // }
    if (isEmpty(value.address)) {
        errors.address = "REQUIRED"
    }
    if (isEmpty(value.country)) {
        errors.country = "REQUIRED"
    }
    if (isEmpty(value.state)) {
        errors.state = "REQUIRED"
    }
    if (isEmpty(value.city)) {
        errors.city = "REQUIRED"
    }
    if (isEmpty(value.postalCode)) {
        errors.postalCode = "REQUIRED"
    }

    return errors;
}

export default validation;