// import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import action
import {
    getUPIDetail,
    getQRDetail,
    getBankDetail,
    getUserSetting,
} from "../../actions/users";
import { getPriceConversion } from "../../actions/commonAction";
import { getAssetData } from "../../actions/walletAction";
import { viewUserProfile } from "../../actions/users";
import { getKycDetail } from "../../actions/userKyc";
import { getSiteSetting } from "../../actions/homeAction";
const HelperRoute = () => {
    const dispatch = useDispatch();

    // redux-state
    const { isAuth } = useSelector((state) => state.auth);

    // function
    useEffect(() => {
        if (isAuth) {
            getBankDetail(dispatch);
            getUPIDetail(dispatch);
            getQRDetail(dispatch);
            getUserSetting(dispatch);
            getAssetData(dispatch);
            viewUserProfile(dispatch);
            getKycDetail(dispatch);
            getPriceConversion(dispatch);
        }
        getSiteSetting(dispatch);
    }, [isAuth]);

    return <div />;
};

export default HelperRoute;