import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
 import { getCategory, getTrendingPost } from "../actions/users";


export default function CategoryLeftBar(props) {

const [ categoryData,setCategoryData ] = useState([])
const [ trendingData,setTrendingData ] = useState([])

  useEffect(()=>{
    getCategoryData()
    getTrendingPostData()
  },[])

  const getCategoryData = async() =>{
    const { status, data } = await getCategory()

    if(status){
       setCategoryData(data)
    }
  }

  const getTrendingPostData = async () =>{
    const { status, data } = await getTrendingPost()

    if(status){
       setTrendingData(data)
    }
  }
  console.log(trendingData,'trendingDatatrendingData')
  return (
    <>
    <div class="blogWhiteCard p-0">
        <h3 class="blogSidebarTitle">Trending Posts</h3>
        <ul class="blogSideBarLinks">
          {
          trendingData && trendingData.length>0 && trendingData.map((item, i) => {
            return(
          <li><Link to={"/blog-description/"+item.slug}>{item.title}</Link></li>
         )
        })
      }   
        </ul>
      </div>
      <div class="blogWhiteCard p-0">
        <h3 class="blogSidebarTitle">Categories</h3>
        <ul class="blogSideBarLinks">
        {
          categoryData && categoryData.length>0 && categoryData.map((item, i) => {
            return(
          <li><Link to={"/blog-category/"+item.slug}>{item.category_name}</Link></li>
         )
        })
      }                         
        </ul>
      </div>
     </> 
  );
}
