import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
// @material-ui/core components
// import { Link } from "react-router-dom";
// import Header from "components/Header/Header.js";
// import Checkbox from 'rc-checkbox';
// import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Switch from '@material-ui/core/Switch';
// import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
// import MenuItem from "@material-ui/core/MenuItem";
// import {Select } from '@material-ui/core';
import { MenuItem, Select } from '@material-ui/core';
import { getChatDetails, getMyTransactions } from '../../actions/p2pAction';
import { getCurrency} from '../../actions/commonAction';
import { jsPDF } from "jspdf";
import ReactDatatable from '@ashvin27/react-datatable';
import 'jspdf-autotable';
import moment from 'moment';

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return null;
}

export default function CryptoHistory(props) {
    const dispatch=useDispatch();
    const doc = new jsPDF();
    const[userId,setuserId]=useState();
    const[transtype,setTranstype]=useState();
    const[currency,setCurrency]=useState('all');
    const[payment,setPayment]=useState('all');

    const[transData,setTransData]=useState([]);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const { ...rest } = props;
    const authDetails = useSelector(state => state.auth);
    const currencyData = useSelector(state => state.currency);
    const getcurrencyDetails = async () => {
        try {
            const { result, status, loading } = await getCurrency(dispatch)
          } catch (err) { }
          
      }
    useEffect(() => {
        getcurrencyDetails();
       }, [])

      useEffect(() => {
        if(authDetails.userId){
            setuserId(authDetails.userId);
            getTransactions();
       // getTransactionDetails(authDetails.userId)
       if(currencyData && currencyData){
        console.log("currencyDatacurrencyDatacurrencyData",currencyData);
    }
        }
       }, [authDetails,currencyData])
   
       const getSelected = async (e) => {
        setTranstype(e);
        setPayment(e);
        let reqData = {
          curUser:authDetails.userId,
          transactiontype:e,
          currencytype:currency
        }
        var arr=[];
      const { result, status, loading } = await getMyTransactions(reqData);
      if(result){
        if(result){
          result.map((item,key)=>{
            if(item.currencyId.type=="crypto")
            arr.push(item)
          })
         
            setTransData(arr)
        }
      }
     }
     const handleposttocurrency = async (e) => {
      let reqData = {
          curUser:authDetails.userId,
          transactiontype:transtype,
          currencytype:e
        }
        setCurrency(e);
        var arr=[];
      const { result, status, loading } = await getMyTransactions(reqData);
      if(result){
        if(result){
          result.map((item,key)=>{
            if(item.currencyId.type=="crypto")
            arr.push(item)
          })
         
            setTransData(arr)
        }
      }
   }
   const savePDF = async () => {
    //  alert("Welcome");
     const unit = "pt";
     const size = "A4"; // Use A1, A2, A3 or A4
     const orientation = "portrait"; // portrait or landscape
 
     const marginLeft = 40;
     const doc = new jsPDF(orientation, unit, size);
 
 
     doc.setFontSize(15);
    
     const title = "Crypto Transaction History";
     const headers = [["Date","Type", "Currency","Amount","To Address","Status"]];
 
     const data = transData.map(elt=> [moment(elt.createdAt).format('DD-MM-YYYY hh:mm'),elt.paymentType?"coin_withdraw" && "Withdraw":"Deposit",elt.currencySymbol,elt.amount,elt.toaddress,elt.status]);
     let content = {
       startY: 50,
       head: headers,
       body: data
     };
     console.log("Content0000====",content);
     doc.text(title, marginLeft, 40);
     doc.autoTable(content);
     doc.save("crypto-history.pdf")
    // alert("Document Saved")
   }  
       const getTransactions = async () => {
        try {
          var cryptoCurrency=[]
            let reqData = {
                curUser:authDetails.userId,
              }
            const { result, status, loading } = await getMyTransactions(reqData);
            if (result) {
                result.length > 0 && result.map((item, i) => {
              
                    if(item.currencyId.type!="fiat"){
                        cryptoCurrency.push(item);
                      
                    }
                })
                setTransData(cryptoCurrency);
              } 
          } catch (err) { 
              console.log("Transactions- err--------",err)
          }
          
      }

      // Crypto History Table

    const cryptoHistoryColumns = [
      {

        key: "createdAt",
        text: "Date",
        className: "Date",
        align: "left",
        width: "150px",
        sortable: false,
        cell:record=>{
          return(
            <div data-tag="allowRowEvents"> {moment(record.createdAt).format('DD-MM-YYYY    hh:mm')}</div>
             
          );
      }
  
      },
        {
          key: "paymentType",
          text: "Type",
          className: 'Type',
          sortable: false,
          cell:record=>{
            if(record.paymentType=='coin_withdraw'){
              var type="Withdraw";
              return(
                <div className="textWithdrawRed" data-tag="allowRowEvents">{type}</div>
                 
              );
          } else{
            var type="Deposit";
            return(
              <div className="textStatusGreen" data-tag="allowRowEvents">{type}</div>
               
            );
        }
           }
        },
        {
          key: "currencySymbol",
          text: "Crypto",
          className: 'TypeCrypto',
          sortable: false,
        },
        {
          key: "amount",
          text: "Amount",
          className: 'Amount',
          sortable: false,
          cell: record => {
            if(record && record.paymentType == "coin_deposit"){
              return record.amount
            }
            if(record && record.paymentType == "coin_withdraw"){
              return record.actualAmount
            }
          }
        },
        {
          key:'toaddresss',
          text:'Address',
          className:'Address',
          sortable: false,
          cell:record=>{
          
              return(
                  <div data-tag="allowRowEvents">{record.toaddress}</div>
                   
                );
              }
        },
        {
          
          key: 'status',
          text: 'Status',
          className: 'Status',
          sortable: false,
          cell:record=>{
            if(record.status=="completed" || record.status=="new"){
              return(
               
                <span className="textStatusGreen">{record.status.charAt(0).toUpperCase()+ record.status.slice(1)}</span>
              );
              }
              else{
                return(
               
                  <span className="textWithdrawRed">{record.status.charAt(0).toUpperCase()+ record.status.slice(1)}</span>
                );
              }
          }
        },
      ];

      const configdata = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: false,
        show_filter: true,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
};
    
  return (
    <>
        <h5>Crypto History</h5>
        <div className="dashboard_box stakingHistoryTable boxSpace">
            <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
                <div className="newsSelectGroup">
                    <label>Filter by</label>
                    
                    <Select name="payment" value={payment}
                    // placeholder={'Select Currency'}
                    onChange={(e)=>
                        getSelected(e.target.value)}>
                        <MenuItem value="all">All Transactions</MenuItem>
                        <MenuItem value="coin_withdraw">Withdraw</MenuItem>
                        <MenuItem value="coin_deposit">Deposit</MenuItem>
                        {/* <MenuItem value="Transfer">Transfer</MenuItem> */}
                    </Select>
                    <Select
                        name="currency"
                        value={currency}
                     //   placeholder={'Select Currency'}
                        onChange={(e)=>
                        handleposttocurrency(e.target.value)}
                        >
                        <MenuItem value="all">All Currency Types</MenuItem>
                        {
                            currencyData && currencyData.length > 0 && currencyData.map((item, key) => {
                              console.log(item,'item')

                                if (item.type == 'crypto' || item.type == 'token') {
                                  console.log(item.currencySymbol,'item')
                                    return (
                                        <MenuItem value={item.currencySymbol}>{item.currencySymbol}</MenuItem>
                                    )
                                }
                            })
                        }
                    </Select>
                    {/* <div className="tableSearchBox">
                        <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search by Trans.Ref / Bank"/>
                        <div class="input-group-append">
                            <span class="btnType1"><i class="fas fa-search"></i></span>
                        </div>
                        </div>
                    </div> */}
                </div>
                <div className="newsSelectGroup">
                    <button className="btn btn-outline text-uppercase py-1 m-0" onClick={savePDF}>Download PDF</button>
                </div>
            </div>
           <div className="table-responsive">
           <ReactDatatable
                className="historyCryptoTable w-100"
                records={transData}
                columns={cryptoHistoryColumns}
                config={configdata}
               // onPageChange={pageChange()}
              />
           </div>
           {/* <DataTable className="historyCryptoTable" columns={cryptoHistoryColumns}data={transData} noHeader /> */}
        </div>
    </>
  );
}
