import { combineReducers } from "redux";
import authReducer from "./authReducer";
import tradeReducers from "./tradeReducers";
// import errorReducer from "./errorReducers";

// common
import modal from "./modal";
import form from "./form";

import account from "./account";
import bankDetail from "./bankDetail";
import userSetting from "./userSetting";
import language from "./language";
import currency from "./currency";
import timezone from "./timezone";
import userKyc from "./userKyc";
import tradeAsset from "./tradeAsset";
import wallet from "./wallet";
import instantspot from "./instantSpot";
import pairList from "./pairListReducer";
// import tradeAsset  from "./tradeAsset";
import tradePair from "./tradePairReducers";
import marketPrice from "./marketPriceReducers";
import orderBookDetail from "./orderBookDetail";
import upiDetail from "./upiDetail";
import qrCodeDetail from "./qrCodeDetail";
import priceConversion from "./priceConversion";
import siteSetting from "./siteSetting";

export default combineReducers({
    auth: authReducer,
    account,
    bankDetail,
    userSetting,
    language,
    instantspot,
    currency,
    timezone,
    userKyc,
    wallet,
    pairList,
    tradeAsset,
    tradePair,
    marketPrice,
    orderBookDetail,
    upiDetail,
    qrCodeDetail,
    priceConversion,
    siteSetting,
    // modal: modal,
    // form,
    // trade: tradeReducers,
    // errors: errorReducer
});
