// import package
import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// import action
import { setQRForm, setPrimaryQR, deleteQRDetail } from "../../actions/users";

// import lib
import { toastAlert } from "../../lib/toastAlert";

const ViewQRDetail = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    // redux-state
    const { result } = useSelector((state) => state.qrCodeDetail);

    console.log(result, "resultresultresultresult");

    // function
    const handlePrimaryQR = async (item) => {
        try {
            let reqData = {
                id: item._id,
            };
            const { status, loading, message } = await setPrimaryQR(
                reqData,
                dispatch
            );
            if (status == "success") {
                toastAlert("success", t(message), "viewBank");
            } else {
                toastAlert("error", t(message), "viewBank");
            }
        } catch (err) {}
    };

    const handleDelete = async (item) => {
        try {
            if (window.confirm("Are you sure?")) {
                let reqData = {
                    id: item._id,
                };
                const { status, loading, message } = await deleteQRDetail(
                    reqData,
                    dispatch
                );
                if (status == "success") {
                    toastAlert("success", t(message), "login");
                } else {
                    toastAlert("error", t(message), "login");
                }
            }
        } catch (err) {}
    };

    return (
        <>
            <h5 className="dash_subtitle pb-3 mb-1">My GPay Accounts</h5>
            <div className="table-responsive">
                <table className="table dash_table">
                    <thead>
                        <th>{t("DEFAULT")}</th>
                        <th>{t("QR_NAME")}</th>
                        <th>{t("STATUS")}</th>
                    </thead>
                    <tbody>
                        {result &&
                            result.length > 0 &&
                            result.map((item, key) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className="form-check">
                                                <input
                                                    className=""
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault2"
                                                    checked={item.isPrimary}
                                                    onChange={() => {
                                                        handlePrimaryQR(item);
                                                    }}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    for="flexRadioDefault2"
                                                ></label>
                                            </div>
                                        </td>
                                        <td>{t("ACCOUNT")} {key + 1}</td>
                                        <td>
                                            [
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    dispatch(
                                                        setQRForm({
                                                            formDisable: false,
                                                            formType: "edit",
                                                            editRecord: {
                                                                ...item,
                                                                key: key + 1,
                                                            },
                                                        })
                                                    );
                                                }}
                                            >
                                                {t("EDIT")}
                                            </Link>
                                            ] [
                                            <Link
                                                to="#"
                                                onClick={() =>
                                                    handleDelete(item)
                                                }
                                            >
                                                {t("DELETE")}
                                            </Link>
                                            ]
                                        </td>
                                        {/* <td>
                                            <a data-tip data-for='clickme' data-event='click' className="ml-2">
                                                <i class="fas fa-info-circle"></i>
                                            </a>
                                            <ReactTooltip
                                                id='clickme'
                                                place='right'
                                                effect='solid'
                                                clickable={true}
                                            >
                                                {t('TWO_FA_MSG')}
                                            </ReactTooltip>
                                        </td> */}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ViewQRDetail;