// import package
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import component
// import FiatWithdraw from './FiatWithdraw';
// import FiatDeposit from './FiatDeposit';
// import CoinDeposit from './CoinDeposit';
// import CoinWithdraw from './CoinWithdraw';

// import action
import { getAssetData } from '../../actions/walletAction'

const P2pUserWalletList = () => {
    const { t, i18n } = useTranslation();

    // redux-state
    const walletData = useSelector(state => state.wallet);

    // state
    const [depositKey, setDepositKey] = useState(-1)
    const [withdrawKey, setWithdrawKey] = useState(-1)
    const [assetData, setAsstData] = useState({})

    // function
    // const fetchAsset = async () => {
    //     try {
    //         const { status, loading, result } = await getAssetData();
    //         if (status == 'success') {
    //             setWalletData(result)
    //         }
    //     } catch (err) { }
    // }

    // useEffect(() => {
    //     fetchAsset();
    // }, [])

    return (
        <>
        {
                walletData && walletData.length > 0 && walletData.map((item, key) => {
                 // if(item.currency.type!="crypto"){
                    return (
                        <>
                            <div className="dashboard_box mb-2">
                                <div className="walletCard">
                                    <div className="walletCardLeft">
                                        <div className="currencyName">
                                            <img src={item.currency.currencyimage} alt="" className="img-fluid" />
                                            {item.currency.currencyName}
                                        </div>
                                        <div className="currencyPrice">
                                            {item.p2pbalance.toFixed(2)}{" "}
                                            <small>{item.currency.currencySymbol}</small>
                                        </div>
                                    </div>
                                    {/* <div className="walletCardRight">
                                        <div className="textLinkGroup">
                                            [
                                            <Link href="#"
                                                onClick={() => {
                                                    setDepositKey(key == depositKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Deposit
                                            </Link>
                                        ]
                                        [
                                            <Link href="#"
                                                onClick={() => {
                                                    setWithdrawKey(key == withdrawKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Withdraw
                                            </Link>
                                        ]

                                        {
                                                ['crypto', 'token'].includes(item.currency.type) && <>
                                                    {' '}[ <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Trade</a> ]
                                            </>
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            {/* <Collapse in={key == depositKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinDeposit
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatDeposit
                                            assetData={item}
                                        />
                                    }

                                </div>
                            </Collapse> */}

                            {/* <Collapse in={key == withdrawKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinWithdraw
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatWithdraw
                                            assetData={item}
                                        />
                                    }
                                </div>


                            </Collapse> */}
                        </>
                    )
                        // }
                })
            }
            {/* {
                walletData && walletData.length > 0 && walletData.map((item, key) => {
                    return (
                        <>
                            <div className="dashboard_box mb-2">
                                <div className="walletCard">
                                    <div className="walletCardLeft">
                                        <div className="currencyName">
                                            <img src={item.currency.currencyimage} alt="" className="img-fluid" />
                                            {item.currency.currencyName}
                                        </div>
                                        <div className="currencyPrice">
                                            {item.spotwallet}{" "}
                                            <small>{item.currency.currencySymbol}</small>
                                        </div>
                                    </div>
                                    <div className="walletCardRight">
                                        <div className="textLinkGroup">
                                            [
                                            <Link href="#"
                                                onClick={() => {
                                                    setDepositKey(key == depositKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Deposit
                                            </Link>
                                        ]
                                        [
                                            <Link href="#"
                                                onClick={() => {
                                                    setWithdrawKey(key == withdrawKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Withdraw
                                            </Link>
                                        ]

                                        {
                                                ['crypto', 'token'].includes(item.currency.type) && <>
                                                    {' '}[ <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Trade</a> ]
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Collapse in={key == depositKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinDeposit
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatDeposit
                                            assetData={item}
                                        />
                                    }

                                </div>
                            </Collapse>

                            <Collapse in={key == withdrawKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinWithdraw
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatWithdraw
                                            assetData={item}
                                        />
                                    }
                                </div>


                            </Collapse>
                        </>
                    )
                })
            } */}
        </>
    )
}

export default P2pUserWalletList;
