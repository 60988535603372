// import package
import React, { useState, useEffect } from 'react';

// import action
import { getLoginHistory } from '../../actions/dashboardAction';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';
import Moment from 'react-moment';

const LastLogin = () => {
    // state
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);

    // function
    const fetchLoginHistory = async () => {
        try {
            const { status, loading, result } = await getLoginHistory();
            setLoader(loading)
            if (status == 'success') {
                // var lastLogin= result.slice(-1).pop();
                var lastLogin= result[0];
                setData(lastLogin)    
            }
        } catch (err) { }
    }
    useEffect(() => {
        fetchLoginHistory()
    }, [])

    return (
        <ul className="profile_dash">
            {/* <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li> */}
            <li>{dateTimeFormat(data.createdDate, 'YYYY-MM-DD HH:mm')},{data.broswername}, {data.ipaddress}  - <span>Last login</span></li>
        </ul>
    )
}

export default LastLogin;