// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {};
   
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    if (isEmpty(value.firstname)) {
        errors.firstname = "Please Enter Firstname"
    }
    if (isEmpty(value.lastname)) {
        errors.lastname = "Please Enter Lastname"
    }
    if (isEmpty(value.message)) {
        errors.message = "Please Enter Message"
    }
    if (isEmpty(value.email)) {
        errors.email = "Please Enter Email"
    }else if (!(emailRegex.test(value.email))) {
        errors.email = "Email is Invalid"
    }
    
    return errors;
}

export default validation;
