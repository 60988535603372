// import package
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import action
import { getTradeData } from "../../actions/p2pAction";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import Pagination from "../../lib/pagination";

const DisputeResolvedTrade = () => {
    // state
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [userId, setUserId] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const PageSize = 5;

    const authDetails = useSelector((state) => state.auth);

    // funtion
    const fetchTransaction = async (userId) => {
        try {
            var ids = "4";
            let data = {
                type: ids,
                userid: userId,
                dispute: true,
                currentPage: currentPage,
                perPage: PageSize,
            };
            const { status, loading, result, count } = await getTradeData(data);
            setLoader(loading);
            if (status != false) {
                setData(result);
                setTotalCount(count);
            }
        } catch (err) {}
    };

    useEffect(() => {
        if (authDetails.userId) {
            setUserId(authDetails.userId);
            fetchTransaction(authDetails.userId);
        }
    }, [authDetails, currentPage]);

    return (
        <div className="table-responsive">
            <table className="table mb-0">
                <thead>
                    <tr>
                        {/* <th>Id #</th> */}
                        <th>Info</th>
                        <th>Trader</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Created Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {loader && (
                        <tr>
                            <td colSpan="5" align="center">
                                Loading...
                            </td>
                        </tr>
                    )}
                    {!loader &&
                        data.length > 0 &&
                        data.map((item, key) => {
                            let type =
                                item.tradedata.userId != userId
                                    ? item.BuyorSell
                                    : item.tradedata.BuyorSell;
                            let trader =
                                type == "Buy"
                                    ? item.senderdetails.name
                                    : item.receiverdetails.name;
                            return (
                                <tr key={key}>
                                    <td>
                                        {type} {item.firstCurrency}
                                    </td>
                                    <td>{trader}</td>
                                    <td>
                                        {parseFloat(item.price.toFixed(2))}{" "}
                                        {item.secondCurrency}
                                    </td>
                                    <td>
                                        {parseFloat(
                                            item.crypto_amount.toFixed(8)
                                        )}{" "}
                                        {item.firstCurrency}
                                    </td>
                                    <td>
                                        {dateTimeFormat(
                                            item.created_date,
                                            "YYYY-MM-DD HH:mm"
                                        )}
                                    </td>
                                    <td>
                                        <Link to={"/Chat/" + item._id}>
                                            <button
                                                // onClick={""}
                                                className="button_take_control"
                                            >
                                                View / Chat
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}
                    {!loader && data && data.length <= 0 && (
                        <tr>
                            <td colSpan="5" align="center">
                                No Record
                            </td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="5" align="center">
                            <Pagination
                                currentPage={currentPage}
                                totalCount={totalCount}
                                pageSize={PageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default DisputeResolvedTrade;