let key = {};
// if (process.env.NODE_ENV === "production") {
if (process.env.REACT_APP_MODE === "production") {
    console.log("Set Production Config")
    const API_URL = 'https://api.tradax.pro';

    key = {
        secretOrKey: "FxUum76z",
        CRYPTO_SECRET_KEY: "1234567812345678",
        RECAPTCHA_SITE_KEY: "6Leq_CAfAAAAANZb36vzXXdhI6fLeFlsIYjemGZi", //local
        API_URL: 'https://api.tradax.pro',
        FRONT_URL: 'https://tradax.pro',
        ADMIN_URL: 'https://tradax.pro/admin',
        SOCKET_URL:   `${API_URL}`,
        getGeoInfo: "https://ipapi.co/json/",
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        }
    };

} else {
    console.log("Set Development Config")
    const API_URL = 'http://localhost'; //http://192.168.29.63:3000/  http://localhost
    //const API_URL = 'http://54.157.227.173';
    key = {
        secretOrKey: "FxUum76z",
        CRYPTO_SECRET_KEY: "1234567812345678",
        RECAPTCHA_SITE_KEY: "6LdpeoQUAAAAAHwFEDfpcA-W5-leSH8548lZWWeb",
        API_URL: `${API_URL}:2056`,
        SOCKET_URL: `${API_URL}:2056`,
        getGeoInfo: "https://ipapi.co/json/",
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        }
    };
}

export default key;
