// import config
import axios from "../config/axios";
// import constant
import { SET_SITE_SETTING } from "../constant";

export const getMarketTrend = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/spot/getMarketTrend`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const getSiteSetting = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/siteSetting`,
        });
        dispatch(setSiteSetting(respData.data.result));
        return true;
    } catch (err) {
        return false;
    }
};

export const setSiteSetting = (data) => {
    return {
        type: SET_SITE_SETTING,
        data,
    };
};