// import constant
import {
    SET_USER_WALLET_LIST,
    UPDATE_USER_WALLET_STAKE,
    UPDATE_USER_WALLET_P2P,
} from "../constant";

const initialValue = [];

const wallet = (state = initialValue, action) => {
    switch (action.type) {
        case SET_USER_WALLET_LIST:
            return [...initialValue, ...action.data];
        case UPDATE_USER_WALLET_STAKE:
            if (state && state.length > 0) {
                let newRecord = [];
                state.map((item, key) => {
                    if (item._id == action.data._id) {
                        newRecord.push({
                            ...item,
                            ...{
                                spotwallet: action.data.spotwallet,
                            },
                        });
                    } else {
                        newRecord.push(item);
                    }
                });

                return newRecord;
            }
            return state;
        case UPDATE_USER_WALLET_P2P:
            if (state && state.length > 0) {
                let newRecord = [];
                state.map((item, key) => {
                    if (item._id == action.data._id) {
                        newRecord.push({
                            ...item,
                            ...{
                                p2pbalance: action.data.p2pbalance,
                            },
                        });
                    } else {
                        newRecord.push(item);
                    }
                });

                return newRecord;
            }
            return state;
        default:
            return state;
    }
};

export default wallet;
