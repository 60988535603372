// import package
import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import lodash from "lodash";

// import context
import SocketContext from "../Context/SocketContext";

// import action
import {
    getPairList,
    setPairList,
    setTradePair,
    getMarketPrice,
    setMarketPrice,
} from "../../actions/spotTradeAction";
import {
    getAssetByCurrency,
    setUserFirstCurrency,
    setUserSecondCurrency,
} from "../../actions/walletAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toFixed } from "../../lib/roundOf";

const MarketPrice = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { tikerRoot } = useParams();
    const socketContext = useContext(SocketContext);
    const [imgname, setImgname] = useState();
    // state
    const [pairLists, setPairLists] = useState([]);
    const [search, setsearchValue] = useState("");


    // redux-state
    const tickerData = useSelector((state) => state.marketPrice);
    const pairData = useSelector((state) => state.tradePair);
    const pairListData = useSelector((state) => state.pairList);

    const accountData = useSelector((state) => state.account);

    // function
    const fetchAssetByCurrency = async (spotPairId, type) => {
        try {
            const { status, loading, error, result } = await getAssetByCurrency(
                spotPairId
            );
            if (status == "success") {
                if (type == "firstCurrency") {
                    setUserFirstCurrency(result, dispatch);
                } else if (type == "secondCurrency") {
                    setUserSecondCurrency(result, dispatch);
                }
            } else {
            }
        } catch (err) {}
    };
    
    const fetchPairList = async () => {
        try {
            const { status, loading, result } = await getPairList();
            console.log("marketetttttttttttttttttttttttttt",result)
            if (status == "success") {
                setPairList(result, dispatch);
                if (result && result.length > 0) {
                    if (isEmpty(tikerRoot)) {
                        let pair = `${result[0].firstCurrencySymbol}_${result[0].secondCurrencySymbol}`;
                        history.push("/spot/" + pair);
                        await fetchAssetByCurrency(
                            result[0].firstCurrencyId,
                            "firstCurrency"
                        );
                        await fetchAssetByCurrency(
                            result[0].secondCurrencyId,
                            "secondCurrency"
                        );
                        await setMarketPrice(result[0], dispatch);
                        await setTradePair(result[0], dispatch);
                        // getMarketPrice(result[0]._id, dispatch);
                    } else {
                        let currency = tikerRoot.split("_");
                        let pairDetail = result.find(
                            (el) =>
                                el.firstCurrencySymbol == currency[0] &&
                                el.secondCurrencySymbol == currency[1]
                        );
                        await fetchAssetByCurrency(
                            pairDetail.firstCurrencyId,
                            "firstCurrency"
                        );
                        await fetchAssetByCurrency(
                            pairDetail.secondCurrencyId,
                            "secondCurrency"
                        );
                        // getMarketPrice(pairDetail._id, dispatch);
                        await setMarketPrice(pairDetail, dispatch);
                        await setTradePair(pairDetail, dispatch);
                    }
                }
            } else {
            }
        } catch (err) {}
    };

    const handlePairChange = async (pairData) => {
        let pair = `${pairData.firstCurrencySymbol}_${pairData.secondCurrencySymbol}`;

        history.push("/spot/" + pair);
        await fetchAssetByCurrency(pairData.firstCurrencyId, "firstCurrency");
        await fetchAssetByCurrency(pairData.secondCurrencyId, "secondCurrency");
        await setMarketPrice(pairData, dispatch);
        await setTradePair(pairData, dispatch);
        socketContext.socket.off("marketPrice");
        socketContext.socket.off("orderBook");
        socketContext.socket.off("recentTrade");
    };

    useEffect(() => {
        if (pairListData && pairListData.length > 0) {
            setPairLists(pairListData);
        }
    }, [pairListData]);

    useEffect(() => {
        if (!isEmpty(pairData)) {
            // socket
            socketContext.socket.on("marketPrice", (result) => {
                if (result.pairId == pairData.pairId) {
                    setMarketPrice(result.data, dispatch);
                    let tempPairList = pairLists;
                    let pairIndex = tempPairList.findIndex(
                        (el) => el._id == result.pairId
                    );
                    if (pairIndex >= 0) {
                        tempPairList[pairIndex] = {
                            ...tempPairList[pairIndex],
                            ...{
                                markPrice: result.data.markPrice,
                                change: result.data.change,
                            },
                        };
                        setPairLists(tempPairList);
                    }
                }
            });
            setImgname(pairData.firstCurrencySymbol);
        }
    }, [pairData, accountData]);

     const searchpair = (e) => {
        setsearchValue(e.target.value)
        var searcharr = [];
        if (pairListData && pairListData.length > 0) {
            for (var i = 0; i < pairListData.length; i++) {
                var temppair =
                    pairListData[i].firstCurrencySymbol +
                    "/" +
                    pairListData[i].secondCurrencySymbol;
                if (temppair.indexOf(e.target.value.toUpperCase()) !== -1) {
                    searcharr.push(pairListData[i]);
                }
                if (i == pairListData.length - 1) {
                    setPairLists(searcharr)
                }
            }
        }

    };

    useEffect(() => {
        fetchPairList();
        return () => {
            socketContext.socket.off("marketPrice");
            socketContext.socket.off("orderBook");
            socketContext.socket.off("recentTrade");
        };
    }, []);

    return (
        <div className="header-overview">
            <div className="selectCoinType">
                {/* <img src={require("../../assets/images/cryptoicons/BTC.png")} alt="" className="img-fluid" /> */}
                {imgname == "" || imgname == undefined ? (
                    <span>
                        <img
                            src={require("../../assets/images/cryptoicons/BTC.png")}
                        ></img>
                    </span>
                ) : (
                    <span>
                        <img
                            src={require("../../assets/images/cryptoicons/" +
                                imgname +
                                ".png")}
                        ></img>
                    </span>
                )}
                {
                    imgname != "" ||
                        (imgname != undefined && (
                            <span>
                                <img
                                    src={require("../../assets/images/cryptoicons/BTC.png")}
                                ></img>
                            </span>
                        ))

                    // <span><img src={require("../assets/images/cryptoicons/"+imgname+".svg")}></img></span>
                }
                <div className="tradePairDrop">
                    <button
                        type="button"
                        className="selectPair dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {pairData && pairData.firstCurrencySymbol}/
                        {pairData && pairData.secondCurrencySymbol}
                    </button>
                    <div
                        className="dropdown-menu"
                        id="pairDrop"
                        aria-labelledby="dropdownMenuButton"
                    >
                         <div class="search_price_section">
                                        <input type="text" placeholder="search" onChange={searchpair}/>
                                        <button>Search</button>
                                    </div> 
                        <table class="table pairDropTable">
                            <thead>
                                <tr>
                                    <th>Price</th>
                                    <th>Latest Price</th>
                                    <th>change</th>
                                    {/* <th>24h Volume</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {pairLists &&
                                    pairLists.length > 0 &&
                                    pairLists.map((item, key) => {
                                        return (
                                            <tr
                                                key={key}
                                                onClick={() =>
                                                    handlePairChange(item)
                                                }
                                            >
                                                <td className="text-white">
                                                    {/* <i class="fas fa-star"></i> */}
                                                    {item.firstCurrencySymbol}
                                                    <span>
                                                        /
                                                        {
                                                            item.secondCurrencySymbol
                                                        }
                                                    </span>
                                                </td>
                                                <td className="balance_amt_detail">
                                                    <span className="price_increase">
                                                        {item.markPrice}
                                                    </span>
                                                    {/* <span>/$314.5</span> */}
                                                </td>
                                                <td>
                                                    <span className="span_menu_gerrn">
                                                        {item.change}
                                                    </span>
                                                </td>
                                                {/* <td className="buy_button_op">0</td> */}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="hoPriceConversion">
                    <h3 className="tradeGreenText">
                        {toFixed(
                            tickerData.markPrice,
                            pairData.secondFloatDigit
                        )}
                    </h3>
                </div>
            </div>
            <div className="headerOverviewGroup">
                <div className="headerOverviewStatus">
                    <h5>
                        <small>24h Change</small>
                        <span className="tradeRedText">
                            {toFixed(tickerData.changePrice, 2)} (
                            {toFixed(tickerData.change, 2)}%)
                        </span>
                    </h5>
                </div>
                <div className="headerOverviewStatus">
                    <h5 className="pinkText">
                        <small>24h High</small>
                        {toFixed(tickerData.high, pairData.secondFloatDigit)}
                    </h5>
                </div>
                <div className="headerOverviewStatus">
                    <h5>
                        <small>24h Low</small>
                        {toFixed(tickerData.low, pairData.secondFloatDigit)}
                    </h5>
                </div>
                <div className="headerOverviewStatus">
                    <h5>
                        <small>
                            24h Vol({pairData && pairData.firstCurrencySymbol})
                        </small>
                        {toFixed(
                            tickerData.firstVolume,
                            pairData.secondFloatDigit
                        )}
                    </h5>
                </div>

                <div className="headerOverviewStatus">
                    <h5>
                        <small>
                            24h Vol({pairData && pairData.secondCurrencySymbol})
                        </small>
                        {toFixed(
                            tickerData.secondVolume,
                            pairData.secondFloatDigit
                        )}
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default MarketPrice;
