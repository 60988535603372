// import package
import React from "react";

// import component
import ViewBankDetail from "./ViewBankDetail";
import EditBankDetail from "../UPIAccount/EditBankDetail";
import ListBankDetail from "../UPIAccount/ListBankDetail";

const BankAccount = () => {
    return (
        <div className="dashboard_box boxSpace">
            <ListBankDetail />
            <EditBankDetail />
            <ViewBankDetail />
        </div>
    );
};

export default BankAccount;