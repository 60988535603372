// import package
import React, { useState } from 'react';
import {
    Slider,
    Checkbox,
    MenuItem,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import action
import { orderPlace } from '../../actions/perpetualTradeAction';

// import lib
import isEmpty from '../../lib/isEmpty';
import { encryptObject } from '../../lib/cryptoJS'
import { toastAlert } from '../../lib/toastAlert';
import { toFixed } from '../../lib/roundOf';

const marks = [
    {
        value: 1,
        label: '1%',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

function valuetext(value) {
    return `${value}%`;
}

const initialFormValue = {
    'price': "",
    'quantity': '',
    'total': '',
    'leverage': 1,
    'marginImpact': '',
    'takeProfit': '',
    'stopLoss': '',
    'isProfitLoss': false
}

const LimitOrder = (props) => {
    const { t, i18n } = useTranslation();

    // props
    const { buyorsell } = props;

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [loader, setLoader] = useState();
    const { price, quantity, total, leverage, marginImpact, takeProfit, stopLoss, isProfitLoss } = formValue;

    // redux-state
    const tradePair = useSelector(state => state.tradePair);
    const marketPriceData = useSelector(state => state.marketPrice);
    const { isAuth } = useSelector(state => state.auth);
    const { firstCurrency, secondCurrency } = useSelector(state => state.tradeAsset);

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        if (!/^\d*\.?\d*$/.test(value)) {
            return
        }
        let formData = { ...formValue, ...{ [name]: value } }

        setFormValue(formData)
        calculateMarginImpact(formData.price, formData.quantity, formData.leverage, formData)
    }

    const calculateMarginImpact = (price, quantity, leverage, formData) => {
        if (!isEmpty(price) && !isEmpty(quantity) && !isEmpty(leverage) && !isEmpty(tradePair)) {
            price = parseFloat(price)
            quantity = parseFloat(quantity)
            leverage = parseFloat(leverage)
            let orderValue = (price * quantity)
            let requiredMargin = orderValue / leverage;
            let fee = orderValue * (tradePair.taker_fees / 100)
            let marginImpact = requiredMargin + fee;

            setFormValue({
                ...formData, ...{
                    'total': orderValue,
                    'leverage': leverage,
                    'marginImpact': marginImpact
                }
            })
        }
    }

    const handlePercentage = (e, percentage) => {
        calculateMarginImpact(price, quantity, percentage, formValue)
    }

    const handleProfitLossCheck = (e) => {
        e.preventDefault();
        const { name, checked } = e.target;
        setFormValue({ ...formValue, ...{ [name]: checked } })
    }

    const handleSubmit = async (buyorsell) => {
        try {
            // const validateError = await validation(formValue, orderType, buyorsell)
            // if (!isEmpty(validateError)) {
            //     toastAlert('error', validateError[Object.keys(validateError)[0]], 'spotOrder');
            //     return
            // }
            setLoader(true)
            let reqData = {
                price: price,
                quantity: quantity,
                buyorsell: buyorsell,
                orderType: 'limit',
                pairId: tradePair.pairId,
                leverage: leverage,
                'newdate': new Date()
            }

            let encryptToken = {
                token: encryptObject(reqData)
            }

            let { status, loading, message, error, result } = await orderPlace(encryptToken);
            setLoader(loading)
            if (status == 'success') {
                setFormValue(initialFormValue);

                // if (result.userAsset._id == firstCurrency._id) {
                //     setUserFirstCurrency(result.userAsset, dispatch)
                // } else if (result.userAsset._id == secondCurrency._id) {
                //     setUserSecondCurrency(result.userAsset, dispatch)
                // }

                toastAlert('success', message, 'spotOrder');
            } else {
                toastAlert('error', message, 'spotOrder');
            }
        }
        catch (err) { }
    }

    const [value, setValue] = React.useState('ioc');
    const handleCheckChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <div className="placeOrderBox contact_form settingsSelect">
            <div className="flexTitle">
                {
                    buyorsell == 'buy' && <h3><small>{t("BALANCE")}</small> {secondCurrency && secondCurrency.derivativeWallet} {tradePair && tradePair.secondCurrencySymbol}</h3>
                }

                {
                    buyorsell == 'sell' && <h3><small>{t("BALANCE")}</small> {firstCurrency && firstCurrency.derivativeWallet} {tradePair && tradePair.firstCurrencySymbol}</h3>
                }

                <a href="#" className="exchangeIcon"><i class="fas fa-exchange-alt"></i></a>
            </div>
            <div className="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="btnType1">{t("PRICE")}</span>
                    </div>
                    <input
                        type="text"
                        class="form-control text-right borderZero"
                        name="price"
                        value={price}
                        onChange={handleChange}
                    />
                    <div class="input-group-append">
                        <span class="btnType1">{tradePair && tradePair.secondCurrencySymbol}</span>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="btnType1">Size</span>
                    </div>
                    <input
                        type="text"
                        class="form-control text-right borderZero"
                        name="quantity"
                        value={quantity}
                        onChange={handleChange}
                    />
                    <div class="input-group-append">
                        <span class="btnType1">{tradePair && tradePair.firstCurrencySymbol}</span>
                    </div>
                </div>
            </div>
            <div className="form-group px-2 mb-0">
                <Slider
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-custom"
                    step={10}
                    valueLabelDisplay="auto"
                    marks={marks}
                    value={leverage}
                    onChange={handlePercentage}
                    min={1}
                    disabled={!isAuth}
                />
            </div>
            <div className="form-group">
                <div className="form-check mb-0">
                    <Checkbox
                        color="primary"
                        className="pl-0"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={handleProfitLossCheck}
                        name="isProfitLoss"
                        checked={isProfitLoss}
                    />
                    <label className="form-check-label pl-0" for="flexCheckDefault">
                        TP/SL
                    </label>
                </div>
            </div>

            {
                isProfitLoss && <div className="form-group">
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            name="takeProfit"
                            value={takeProfit}
                            placeholder="Taker Profit"
                            onChange={handleChange}
                        />
                        <div class="input-group-append">
                            {/* <Select value={10}>
                                <MenuItem value={10}>Mark</MenuItem>
                                <MenuItem value={20}>Limit</MenuItem>
                                <MenuItem value={30}>Stop</MenuItem>
                            </Select> */}
                        </div>
                    </div>
                </div>
            }

            {
                isProfitLoss && <div className="form-group">
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            name="stopLoss"
                            value={stopLoss}
                            placeholder="Stop Loss"
                            onChange={handleChange}
                        />
                        <div class="input-group-append">
                            {/* <Select value={10}>
                                <MenuItem value={10}>Mark</MenuItem>
                                <MenuItem value={20}>Limit</MenuItem>
                                <MenuItem value={30}>Stop</MenuItem>
                            </Select> */}
                        </div>
                    </div>
                </div>
            }


            <RadioGroup aria-label="type" name="type1" value={value} className="radioGroup" onChange={handleCheckChange}>
                <FormControlLabel className="orderRadio" value="tif" control={<Radio />} label="TIF" />
                <FormControlLabel className="orderRadio" value="gtc" control={<Radio />} label="GTC" />
                <FormControlLabel className="orderRadio" value="ioc" control={<Radio />} label="IOC" />
                <FormControlLabel className="orderRadio" value="fok" control={<Radio />} label="FOK" />
            </RadioGroup>
            <div className="ButtonFullWidth">
                {
                    isAuth && <button
                        className="btn BuyNavButton"
                        onClick={() => handleSubmit('buy')}
                    >
                        Long
                    </button>

                }

                {
                    isAuth && <button
                        className="btn SellNavButton"
                        onClick={() => handleSubmit('sell')}
                    >
                        Short
                    </button>
                }

            </div>
            <ul className="poList">
                {/* <li><label><small>Cost</small> 0.0000 USDT</label> <span><small>Cost</small> 0.0000 USDT</span></li>
                <li><label><small>Max</small> 0.0000 USDT</label> <span><small>Max</small> 0.0000 USDT</span></li> */}

                <li>
                    <label><small>Order Value</small> {total} {tradePair && tradePair.secondCurrencySymbol}</label>
                </li>
                <li>
                    <label><small>Margin impact</small> {marginImpact} {tradePair && tradePair.firstCurrencySymbol}</label>
                    {/* <span><small>Max</small> 0.0000 USDT</span> */}
                </li>
            </ul>
            {/* <div className="poContentFlex">
                <h2>Margin Ratio<span>0.00%</span></h2>
                <div className="pocfRight">
                    <p><small>Maintenance Margin</small> 0.0000 USDT</p>
                    <p><small>Margin Balance</small> 0.0000 USDT</p>
                </div>
            </div> */}
        </div>
    )
}

export default LimitOrder;