// import package
import React from 'react';

// import lib
import CanvasJSReact from './canvasjs.react';

// import ChartPlaceHolder from '../../images/ChartPlaceHolder.png';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("customColorSet1",
    [//colorSet Array
        "#f0881a",
        "#4661EE",
        "#6076ff",
        // "#1BCDD1",
        // "#8FAABB",
        // "#B08BEB",
        // "#3EA0DD",
        // "#F5A52A",
        // "#23BFAA",
        // "#FAA586",
        // "#EB8CC6",
    ]);

const  DoughnutChart = (props) => {
    
    const { dataPts } = props;
    console.log("Datapts---",dataPts);
    const options = {
        animationEnabled: true,
        backgroundColor: "transparent",
     //   colorSet: "customColorSet1",

        data: [{
            type: "doughnut",
            // showInLegend: true,
            indexLabel: "{label}: {y}",
            yValueFormatString: "#,###",
            dataPoints: dataPts,
            indexLabelFontColor: "#000",
            indexLabelLineColor: "#000",
            indexLabel: "{null}",
        }]
    }
console.log("options---",dataPts)
    return (

        <CanvasJSChart options={options} />

    );
}

export default DoughnutChart;