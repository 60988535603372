import React, { useEffect } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import P2PTradeHistory from "components/p2pTradeHistory/P2PTradeHistory";
import SpotTradeHistory from "components/spotTradeHistory/SpotTradeHistory";
import SpotOrderHistory from "components/spotOrderHistory/SpotOrderHistory";
import Checkbox from 'rc-checkbox';
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import {Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import LastLogin from '../components/Dashboard/LastLogin';

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return null;
}


export default function TradeHistory(props) {
const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
 const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { ...rest } = props;
   
  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
        <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages">
            <div className="container">
                <P2PTradeHistory/>
                <SpotTradeHistory/>
                <SpotOrderHistory/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
