// import lib
import isEmpty from '../../lib/isEmpty';

const validation = (value) => {
    let errors = {};

    if (isEmpty(value.secret)) {
        errors.secret= "Secrete code Required"
    }

    if (isEmpty(value.uri)) {
        errors.uri = "URI Required"
    }

    if (isEmpty(value.code)) {
        errors.code = "2FA Code Required"
    } else if (isNaN(value.code) || value.code.length > 6) {
        errors.code = "Invalid Code"
    }
    if(value.CheckValue==false){
        errors.CheckValue ="have you  copied 32 digits code"
    }

    return errors;
}

export default validation;