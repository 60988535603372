// import package
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// import lib
import { currencySymbol } from "../../lib/pairHelper";
import { toFixed } from "../../lib/roundOf";
import isEmpty from '../../lib/isEmpty';

// import component
import CanvasChart from "../CanvasChart";
import {getPriceCNV} from '../commonjs/priceCNV';

//import action
import { getPriceList } from "../../actions/spotTradeAction";
import { getDashBal } from "../../actions/dashboardAction";

const reqData = {
  firstCurrencySymbol: "BTC",
  secondCurrencySymmol: "USDT",
};
const initialFormValue = [
  {
    label: "currency",
    y: 100,
    color: "grey",
    toolTipContent: "Asset is zero balance",
  },
];
const BalanceDetail = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // state
  const [totalBals, setTotalBals] = useState(0); // Balance Loader
  const [estBal, setEstBal] = useState(0); // Estimated Balance
  const [loader, setLoader] = useState(true)
  const [balData, setBalData] = useState({})

  const [fiatTotal, setFiatTotal] = useState(0); // fiat Balance
  const [BTCTotal, setBTCTotal] = useState(0); // btc Balance


  const [totalP2PBals, setTotalP2PBals] = useState(0); // Balance Loader
  const [estP2PBal, setEstP2PBal] = useState(0);
  const [assetList, setAssetList] = useState([]);
  const [dataPts, setDataPts] = useState(initialFormValue);
  const [defaultChart, setDefaultChart] = useState(true);

  // redux
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector(state => state.priceConversion)
  const userSetting = useSelector(state => state.userSetting)
  const currencyData = useSelector(state => state.currency)

  // function
  const fetchBalance = async () => {
    try {
      const { status, loading, result } = await getDashBal();
      console.log("🚀 ~ file: BalanceDetail.js ~ line 60 ~ fetchBalance ~ result", result)
      if (status == "success") {
        let arr = [],
          isDefault = false;
          if (result && result.length > 0) {

            
            result.map((item) => {

              if  ( parseFloat(item.p2pbalance)+parseFloat(item.spotwallet) > 0) {
                arr.push({
                  label: item.currencySymbol,
                  y:parseFloat(  item.p2pbalance) +parseFloat(item.spotwallet),
                  color: item.colorCode,
                });
                 isDefault = false
              } 
              else if(!isDefault){
                isDefault = true;
              }
            });
            if (arr.length > 0) {
              setDataPts(arr);
            }
            setAssetList(result);
            setDefaultChart(isDefault);
          }
        // if (result && result.length > 0) {
        //   result.map((item) => {
        //     if (item.spotwallet > 0) {
        //       arr.push({
        //         label: item.currencySymbol,
        //         y: item.spotwallet,
        //         color: item.colorCode,
        //       });
        //     } else {
        //       isDefault = true;
        //     }
        //   });
        //   console.log("ararauyayayyyyyyyyyyy", arr);
        //   if (!isDefault) {
        //     setDataPts(arr);
        //   }
        //   setAssetList(result);
        //   setDefaultChart(isDefault);
        // }
      }
    } catch (err) {}
  };
  useEffect(() => {
    // if (walletData && walletData.length > 0) {
    //   if(priceConversion&&priceConversion.length>0){
    //     var total =0;
    //    var  btcPrice=0;

    //    walletData.map((item) => {
    //     console.log(item,'----------item')
    //     var baseSymbol=item && item.currency && item.currency.currencySymbol;
    //     var convertSymbol="INR";
    //     var walletBlance=parseFloat (item.spotwallet);
    //     if(item.currency.type=="fiat"){
    //       if(item.currency.currencySymbol==convertSymbol){
    //         total=walletBlance;
    //       }else{
    //           const {CNVPrice}=getPriceCNV(priceConversion, baseSymbol,convertSymbol,walletBlance)
    //           total=total+CNVPrice;
    //       }
    //     }else{
    //       const {CNVPrice,oneBtcPrice}=getPriceCNV(priceConversion, baseSymbol,convertSymbol,walletBlance)
    //       total=total+CNVPrice;
    //       btcPrice=oneBtcPrice
    //       console.log("totaltotaltotal",total,CNVPrice)
    //       console.log("totaltotaltotal",btcPrice)
    //     }
        

    //     if (item && item.currency && item.currency.currencySymbol == "BTC") {
    //       setTotalBals(item.spotwallet);
    //       setTotalP2PBals(item.p2pbalance);
    //     }
    //   });

    //   setFiatTotal(total)
    //   setBTCTotal(total/btcPrice)

    // }
    // }
    if (userSetting && priceConversion && priceConversion.length > 0 && walletData && walletData.length > 0 && currencyData && currencyData.length > 0) {
      let estSpotBal = 0, estP2pBal = 0;

      walletData.map(item => {
          let currency = currencyData.find(el => el.currencySymbol == item.currencySymbol && el.type == 'fiat')
          if (currency) {
              if (item.coin == "USD") {
                  estSpotBal = estSpotBal + item.spotwallet
                  estP2pBal = estP2pBal + item.p2pbalance
              } else {
                  let CNVPriceData = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == item.currencySymbol)
                  if (CNVPriceData) {
                      let CNVPrice = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == "USD")
                      if (CNVPrice) {
                          estSpotBal = estSpotBal + ((item.spotwallet / CNVPriceData.convertPrice) * CNVPrice.convertPrice)
                          estP2pBal = estP2pBal + ((item.p2pbalance / CNVPriceData.convertPrice) * CNVPrice.convertPrice)
                      }
                  }
              }
          } else {
              let CNVPriceData = priceConversion.find(el => el.baseSymbol == item.currencySymbol && el.convertSymbol == "USD")
              if (CNVPriceData) {
                  estSpotBal = estSpotBal + (item.spotwallet * CNVPriceData.convertPrice)
                  estP2pBal = estP2pBal + (item.p2pbalance * CNVPriceData.convertPrice)
              }
          }
      })
      let firPriceCNV = priceConversion.find(el => el.baseSymbol == reqData.firstCurrencySymbol && el.convertSymbol == "USD")

      if (firPriceCNV) {
          setBalData({
              estSpotBal,
              estP2pBal,
              spotBal: (estSpotBal / firPriceCNV.convertPrice),
              p2pBal: (estP2pBal / firPriceCNV.convertPrice),
          })
          setLoader(false)
      }
  }
    // getSpotPair();
    fetchBalance();
  }, [walletData,priceConversion, totalBals]);

  const getSpotPair = async () => {
    // this.P2pwallet();
    try {
      const { result, status, loading } = await getPriceList(dispatch);
      if (result) {
        result.length > 0 &&
          result.map((item, key) => {
    
            var total=0;
            if (
              item.baseSymbol == "BTC" &&
              item.convertSymbol == "INR"
            ) {
              var est_value = (item.convertPrice * totalBals).toFixed(2);
              var est_p2p_value = (item.convertPrice * totalP2PBals).toFixed(2);
              setEstBal(est_value);
              setEstP2PBal(est_p2p_value);
            }
          });
      }
    } catch (err) {}
  };
  console.log("datapts----", dataPts);
  return (
    <div className="dashboard_box">
      <h5 className="dash_subtitle">Balance Details</h5>
      <div className="balance_details_panel">
        {
          !loader && !isEmpty(balData) && 
          <div className="balance_details_left">
              <div className="mb-3">
                <h3>Total Account Balance</h3>
                  <h2>
                    {toFixed(balData.spotBal, 8)} <span>{reqData.firstCurrencySymbol}</span>
                  </h2>
              </div>
              <div>
                <h3>Estimated Value:</h3>
                <h4>{toFixed(balData.estSpotBal, 2)} $</h4>
              </div>
            </div>
        }
        <div className="balance_details_right">
          <div className="chartDash">
            <CanvasChart
              chartType={"doughnut"}
              dataPts={dataPts}
              defaultChart={defaultChart}
            />
            {/* <img src={require("../../assets/images/dashboardChart.jpg")} alt="" className="img-fluid" /> */}
          </div>
          <div className="chartLabel">
            <ul>
              {assetList &&
                assetList.length > 0 &&
                assetList.map((item, key) => {
                  return (
                    <li>
                      <label>
                        <i
                          className="fas fa-square-full"
                          style={{ color: item.colorCode }}
                        ></i>{" "}
                        {item.currencySymbol}{" "}
                      </label>
                      {/*  */}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      {
        !loader && !isEmpty(balData) && 
        <div className="table-responsive">
        <table className="table tabelDashBalance">
          <tbody>
            <tr>
              <td>Spot Balance</td>
              <td>{toFixed(balData.spotBal, 8)} {reqData.firstCurrencySymbol}</td>
              <td>${toFixed(balData.estSpotBal, 2)}</td>
              <td>
                <span>
                  {/* [ <a href="/wallet">Transfer</a> ] */}
                </span>{" "}
                <span>
                  [ <a href="/wallet">Deposit</a> ]
                </span>{" "}
                <span>
                  [ <a href="/wallet">Withdraw</a> ]
                </span>
                <span>
                  [ <a href="/wallet">Transfer</a> ]
                </span>
              </td>
            </tr>
            <tr>
              <td>P2P Balance </td>
              <td>{toFixed(balData.p2pBal, 8)} {reqData.firstCurrencySymbol}</td>
              <td>${toFixed(balData.estP2pBal, 2)}</td>
              <td>
                <span>
                  [ <a href="/wallet">Transfer</a> ]
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      }
    </div>
  );
};

export default BalanceDetail;
