// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};

  if (value.userId == "") {
    errors.userId = "REQUIRED";
  }

  if (value.currencyselected == "") {
    errors.currencyselected =
      "Please Select a Cyprocurreny would like to trade";
  }
  if (value.posttocurrency == "") {
    errors.posttocurrency = "Please Select a local currency";
  }
  if (!parseFloat(value.price)) {
    errors.price = "Please Enter the Valid price";
  }
  if (value.price <= 0) {
    errors.price = "Please Enter the Valid price";
  }
  if (!parseFloat(value.minlimit)) {
    errors.postminlimit = "Please Enter the Valid Min Limit";
  } else if (isNaN(value.minlimit) == true) {
    errors.postminlimit = "Please Enter the Min Limit number only";
  }
  if (!parseFloat(value.quantity)) {
    errors.quantity = "Please Enter the Valid Quantity ";
  } else if (isNaN(value.quantity) == true) {
    errors.quantity = "Please Enter the Quantity as number only";
  } else if (value.quantity <= 0) {
    errors.quantity = "Please Enter the Valid Quantity ";
  }
  if (value.minlimit != "" && value.maxlimit != "") {
    if (parseFloat(value.minlimit) >= parseFloat(value.maxlimit)) {
      errors.postminlimit = "Please Enter the correct Minimum Value";
    }
  }
  if (value.postprefcurrency == "") {
    errors.postprefcurrency = "Please Select Preferred Payments";
  }

  if (value.postprefcurrency && value.type == "Sell") {
    if (value.postprefcurrency.some((e) => e === "Bank") && !value.bankPrimary)
      errors.postprefcurrency = "No Bank Details Found";
    if (value.postprefcurrency.some((e) => e === "UPI") && !value.upiPrimary)
      errors.postprefcurrency = "No UPI Details Found";
    if (value.postprefcurrency.some((e) => e === "Gpay") && !value.qrPrimary)
      errors.postprefcurrency = "No Gpay Details Found";
  }

  if (value.postcheckboxaccept != true) {
    errors.postcheckboxaccept = "Please accept the terms and policy";
  }

  if (value.startDate == "") {
    errors.startDate = "Please Select date";
  } else {
    var date = new Date(value.startDate);
    if (date < new Date())
      errors.startDate = "Please Select date Greater than Current date";
  }

  return errors;
};

export default validation;
