// import package
import React, { useState, useEffect } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';

// import action
import { getLoginHistory } from '../../actions/dashboardAction';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';

const UserLoginHistory = () => {
    // state
    const [data, setData] = useState([])
    const [count, setcount] = useState(0);
    const [loader, setLoader] = useState(true);
    const [filter, setFilter] = useState({
        'page': 1,
        'limit': 5
    })
    // function
    const fetchLoginHistory = async (reqData) => {
        try {
            const { status, loading, result ,count} = await getLoginHistory(reqData);
            setLoader(loading)
            if (status == 'success') {
                setData(result);
                setcount(count);

            }
        } catch (err) { }
    }
    function handlePagination(index) {

        let filterData = { ...filter, ...{ 'page': index.page_number, 'limit': index.page_size } }
    
           
            setFilter(filterData);
            fetchLoginHistory(filterData);
        }
    useEffect(() => {
        fetchLoginHistory(filter)
    }, [])

    const columns = [
        {
            key: "createdDate",
            text: "Date",
            className: "Date",
            align: "left",
            sortable: true,
            width: 200,
            cell: (record) => {
                return (
                    <p>
                        {dateTimeFormat(record.createdDate, 'YYYY-MM-DD HH:mm')}
                    </p>
                )
            }

        },
        {
            key: "ipaddress",
            text: "IP ddress",
            className: "pairName",
            align: "left",
            sortable: true,
            width: 200,     
        },
        {
            key: "regionName",
            text: "Region Name",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "countryName",
            text: "Country Name",
            className: "Side",
            align: "left",
            sortable: true,
            width: 200,
        },
        {
            key: "broswername",
            text: "broswer Name",
            className: "Side",
            align: "left",
            sortable: true,
            width: 200,
        },

        
        {
            key: "status",
            text: "Status",
            className: "Price",
            align: "left",
            sortable: true,
            width: 200,
        },

    ];

    const config = {
        page_size: 5,
        length_menu: [10, 20, 50],
        filename: "Order",
        no_data_text: 'No Records found!',
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: true,
    };
    return (

        <div className="table-responsive">

            {/* <table className="table mb-0">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>IP Address</th>
                        <th>Location</th>
                        <th>Device</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loader && <div>Loading...</div>
                    }
                    {
                        !loader && data && data.length > 0 && data.map((item, key) => {
                            return (
                                <tr key={key}>
                                    <td>{dateTimeFormat(item.createdDate, 'YYYY-MM-DD HH:mm')}</td>
                                    <td>{item.ipaddress}</td>
                                    <td>{item.regionName}, {item.countryName}</td>
                                    <td>{item.broswername}, {item.os}</td>
                                    <td>{item.status}</td>
                                </tr>
                            )
                        })
                    }
                    {
                        !loader && data && data.length <= 0 && <div>
                            No record
                            </div>
                    }
                </tbody>
            </table> */}
            <ReactDatatable
                config={config}
                records={data}
                columns={columns}
                dynamic={true}
                total_record={count}
                onChange={handlePagination}
            />
        </div>
    )
}

export default UserLoginHistory;