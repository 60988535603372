// import package
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Slider } from "@material-ui/core";
import { Link, useParams, useHistory } from "react-router-dom";

// import component
import LimitOrder from "./LimitOrder";
import MarketOrder from "./MarketOrder";
import StopLimitOrder from "./StopLimitOrder";
import StopMarketOrder from "./StopMarketOrder";
import { toastAlert } from "../../lib/toastAlert";

const OrderPlace = () => {
    const history = useHistory();
    const [orderType, setOrderType] = useState();
    const [buyorsell, setBuyorsell] = useState();
    const [Limitclass, setLimitClass] = useState();
    const [Marketclass, setMarketClass] = useState();
    const [userId, setUserId] = useState("");

    //redux-state
    const instantspot = useSelector((state) => state.instantspot);
    const userKyc = useSelector((state) => state.userKyc);
    // state
    // if(instantspot == ''){
    //  // buy or sell
    //  setOrderType('limit')
    //  setBuyorsell('buy')
    // }
    // else{
    //     setOrderType('market')
    //     setBuyorsell(instantspot.buyorsell)

    // }

    useEffect(() => {
        if (instantspot && Object.keys(instantspot).length === 0) {
            // buy or sell
            setOrderType("limit");
            setBuyorsell("buy");
            setLimitClass("nav-item nav-link active py-2");
            setMarketClass("nav-item nav-link py-2");
        } else {
            // console.log(instantspot,"elseeeeeeeeeeeeeeeeeee");

            setOrderType("market");
            setBuyorsell(instantspot && instantspot.buyorsell);
            setMarketClass("nav-item nav-link active py-2");
            setLimitClass("nav-item nav-link py-2");
        }
    }, [instantspot, userKyc]);

    return (
        <div className="tradeTableLeftSide darkBox orderBook">
            <div className="tableHead">
                <h4>Place Order</h4>
            </div>
            <div className="ButtonFullWidth">
                <button
                    className="btn BuyNavButton"
                    onClick={() => setBuyorsell("buy")}
                >
                    Buy
                </button>
                <button
                    className="btn SellNavButton"
                    onClick={() => setBuyorsell("sell")}
                >
                    Sell
                </button>
            </div>
            <div className="tradeFulltabbedTable">
                <nav>
                    <div
                        className="nav nav-tabs nav-fill"
                        id="nav-tab1"
                        role="tablist"
                    >
                        <a
                            className={Limitclass}
                            id="nav-limit-tab"
                            data-toggle="tab"
                            href="#nav-limit"
                            role="tab"
                            aria-controls="nav-limit"
                            aria-selected="true"
                            onClick={() => setOrderType("limit")}
                        >
                            Limit
                        </a>
                        <a
                            className={Marketclass}
                            id="nav-market-tab"
                            data-toggle="tab"
                            href="#nav-market"
                            role="tab"
                            aria-controls="nav-market"
                            aria-selected="false"
                            onClick={() => setOrderType("market")}
                        >
                            Market
                        </a>
                        {/* <a className="nav-item nav-link py-2" id="nav-stopLimit-tab" data-toggle="tab" href="#nav-stopLimit" role="tab" aria-controls="nav-stopLimit" aria-selected="false" onClick={() => setOrderType('stop_limit')}>Stop-Limit</a>
                        <a className="nav-item nav-link py-2" id="nav-stopLimit-tab" data-toggle="tab" href="#nav-stopLimit" role="tab" aria-controls="nav-stopLimit" aria-selected="false" onClick={() => setOrderType('stop_market')}>Stop-Market</a> */}
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent1">
                    {orderType == "limit" && (
                        <LimitOrder buyorsell={buyorsell} />
                    )}

                    {orderType == "market" && (
                        <MarketOrder buyorsell={buyorsell} />
                    )}

                    {/* {
                        orderType == 'stop_limit' && <StopLimitOrder
                            buyorsell={buyorsell}
                        />
                    }

                    {
                        orderType == 'stop_market' && <StopMarketOrder
                            buyorsell={buyorsell}
                        />
                    } */}
                </div>
            </div>
        </div>
    );
};

export default OrderPlace;