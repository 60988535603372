// import config
import axios from "../config/axios";

// import constant
import {
    SET_CURRENCY,
    SET_LANGUAGE_OPTION,
    SET_TIME_ZONE,
    SET_THEME,
    SET_TRADE_THEME,
    SET_PRICE_CONVERSION,
} from "../constant";

// import lib
import { getTimeZone } from "../lib/moment";
// import { setTheme as setThemeLocal } from '../lib/localStorage'
import {
    setTheme as setThemeLocal,
    changeTradeTheme as changeTradeThemeLocal,
} from "../lib/localStorage";

export const getLanguage = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/getLanguage`,
        });
        dispatch(setLanguageOption(respData.data.result));
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const getSpotPair = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/spot/tradePair`,
        });
        // dispatch(setCurrencyOption(respData.data.result))
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};
export const addContactus = async (data) => {
    try {
      
        let respData = await axios({
            'method': 'post',
            'url': `/api/addContactus`,
            data
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
            message:respData.data.message
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const getCurrency = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/getCurrency`,
        });
        console.log("Currencyyy---", respData);
        dispatch(setCurrencyOption(respData.data.result));
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const getPriceConversion = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/priceConversion`,
        });
        dispatch({
            type: SET_PRICE_CONVERSION,
            data: respData.data.result,
        });
        return true;
    } catch (err) {
        return false;
    }
};

export const getCms = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getCms`,
            data
        });
        return {
            status: true,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message
        };
    }
};


export const getCmsall = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/getCmsall`,
            data
        });
        return {
            status: true,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message
        };
    }
};

export const setLanguageOption = (data) => {
    return {
        type: SET_LANGUAGE_OPTION,
        data,
    };
};

export const setCurrencyOption = (data) => {
    return {
        type: SET_CURRENCY,
        data,
    };
};

export const setTimeZone = async (dispatch) => {
    let data = getTimeZone();
    dispatch({
        type: SET_TIME_ZONE,
        data,
    });
};

export const setTheme = async (dispatch, theme) => {
    await setThemeLocal(theme);
    dispatch({
        type: SET_THEME,
        theme,
    });
};

export const setTradeTheme = async (dispatch, theme) => {
    await changeTradeThemeLocal(theme);
    dispatch({
        type: SET_TRADE_THEME,
        theme,
    });
    return true;
};

export const getFaq = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/getFaq`,
        });
        return {
            status: true,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: false
        };
    }
};
