import {
    SET_AUTHENTICATION,LOGOUT
} from "../constant";

const initialState = {
    isAuth: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return {
                ...state,
                ...action.authData
            };
            case LOGOUT:

                return {
                    isAuth: false,
                    ...action.data
                };
        default:
            return state;
    }
}

export default authReducer; 