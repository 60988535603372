
import React, { useState, useEffect, useContext } from 'react'

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import Slider from "react-slick";

import GridItem from "../Grid/GridItem.js";

// import context
import SocketContext from '../Context/SocketContext';
// import lib
import isLogin from '../../lib/isLogin'

// import action
import { getMarketTrend } from '../../actions/homeAction'
import { toFixed } from '../../lib/roundOf';
import keys from "../../config";
const url = keys.API_URL;
const useStyles = makeStyles(styles);

const HomeBanner = () => {

  const classes = useStyles();
  const socketContext = useContext(SocketContext);


  // state
  const [pairData, setPairData] = useState([]);
  const [loading, setLoading] = useState(false)

  // function
  const fetchMarketTrend = async () => {
    try {
      const { status, loading, result } = await getMarketTrend();

      if (status == 'success') {
        setPairData(result)
        setLoading(true)

      }
    } catch (err) { }
  }

  useEffect(() => {
    fetchMarketTrend();

    socketContext.socket.on('marketPrice', (result) => {

      setPairData((el) => {
        let pairList = [];
        el.map(item => {
          if (item._id == result.pairId) {
            pairList.push({
              ...item,
              ...{
                "markPrice": toFixed(result.data.markPrice, 2),
                "change": toFixed(result.data.change, 2),
                "volume": toFixed(result.data.firstVolume, 2)
              }
            })
          } else {
            pairList.push(item)
          }
        })


        return pairList
      })
    })

    return () => {
      socketContext.socket.off("marketPrice", pairData);
    }

  }, [])
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (



    <section className="section ticker_section">
      <div className="container" data-aos="fade-down">
        <GridContainer>
          <GridItem md={12}>
            <Slider {...settings}>
              {pairData &&
                pairData.length > 0 &&
                pairData.map((item, key) => {
                  console.log(item, 'iiiiiiiiii')
                  return (
                    <div>
                      <div className="ticker_panel">
                        <div className="ticker_panel_top">
                          <div className="d-flex align-items-center">
                            <img
                              src={`${url}/images/currency/${item.firstCurrencyName}`}
                              style={{ width: "30px" }}
                              alt=""

                            />
                            <h4>{item.firstCurrencySymbol}</h4>-<h4>{item.secondCurrencySymbol}</h4>

                          </div>
                          <h5 className="text-red">{parseFloat(item.change).toFixed(2)}%</h5>
                        </div>
                        <div className="ticker_panel_bottom">
                          <h2>${parseFloat(item.markPrice).toFixed(2)}</h2>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </GridItem>
        </GridContainer>
      </div>
    </section>)
}

export default HomeBanner;