// import lib
import isEmpty from '../lib/isEmpty';

let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|)$/;

export const validation = value => {
    let errors = {};
    console.log(value,'-------value')

    if (isEmpty(value.categoryId)) {
        errors.categoryId = "Required"
    }

    if (isEmpty(value.message)) {
        errors.message = "Required"
    }
    if (value.document && value.document.size) {
        if (value.document.size > 1000000) {
            errors.document = "Image Size is too Large"
        } else if (!imageFormat.test(value.document.name)) {
            errors.document = "Invalid file Format"
        }
    }

    return errors;
}

export const replyValidation = value => {
    let errors = {};

    if (isEmpty(value.message)) {
        errors.replymessage = "Required"
    }
    if (value.document && value.document.size) {
        if (value.document.size > 1000000) {
            errors.document = "Image Size is too Large"
        } else if (!imageFormat.test(value.document.name)) {
            errors.document = "Invalid file Format"
        }
    }
    return errors;
}

export default validation;