// import package
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import ReactHtmlParser from "react-html-parser";

// @material-ui/core components
import { Link, useParams, useHistory } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTooltip from "react-tooltip";
import Switch from "@material-ui/core/Switch";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import action
import { getKycDetail, setUserKyc } from "../../actions/userKyc";
import { getAssetData } from "../../actions/walletAction";
import { getCurrency } from "../../actions/commonAction";
import { getP2PPairList, postTrade } from "../../actions/p2pAction";

// import lib
import { toFixed } from "lib/roundOf";
import validation from "./validation";
import isEmpty from "lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { SettingsBackupRestoreOutlined } from "@material-ui/icons";

import config from "../../config";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  currencyselected: "USD",
  posttocurrency: "USDT",
  postprefcurrency: [],
  postminlimit: "",
  postmaxlimit: "",
  posttooptions: [],
  postcheckboxaccept: false,
  BuyorSell: "Buy",
  quantity: 0,
  price: 0,
  paymode: "",
};

export default function PostAd(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [balLoader, setBalLoader] = useState(true); // Balance Loader
  const [validateError, setValidateError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userBalance, setUserBalance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [fee, setFee] = useState(0);
  const [balancedetails, setBalancedetails] = useState([]);
  const [spotpairsdata, setSpotpairsdata] = useState([]);
  const [p2pcurrencylistunicc, setp2pcurrenncylistunic] = useState([]);
  const [curmarketprice, setCurmarketprice] = useState(0);
  const [posttradeprice, setPosttradeprice] = useState(0);
  const [userId, setUserId] = useState("");
  const [upiPrimary, setUpiPrimary] = useState("");
  const [bankPrimary, setBankPrimary] = useState("");
  const [qrPrimary, setQRPrimary] = useState("");
  const [gpay, setGpay] = useState("");
  var sorc = "";
  const [payment, setPayment] = useState("");
  const {
    currencyselected,
    posttocurrency,
    postprefcurrency,
    postminlimit,
    postmaxlimit,
    posttooptions,
    postcheckboxaccept,
    BuyorSell,
    quantity,
    price,
    paymode,
  } = formValue;

  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  // });

  // redux-state
  const userKyc = useSelector((state) => state.userKyc);
  const walletData = useSelector((state) => state.wallet);
  const currencyData = useSelector((state) => state.currency);
  const upiData = useSelector((state) => state.upiDetail);
  const bankData = useSelector((state) => state.bankDetail);
  const qrData = useSelector((state) => state.qrCodeDetail);

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  const { ...rest } = props;

  // Handle change for add the values
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (name == "quantity") {
      var totPrice = toFixed(value * price, 2);
      setPosttradeprice(totPrice);
    }

    let paymentDetails = [];
    if (name == "BuyorSell" && value == "Sell") {
      if (postprefcurrency.some((e) => e === "Bank") && bankPrimary) {
        let bankPayment = bankPrimary.bankName + " - " + bankPrimary.accountNo;
        paymentDetails.push({ name: "Bank", value: bankPayment });
      }
      if (postprefcurrency.some((e) => e === "Gpay") && qrPrimary) {
        sorc = `<img src=${
          config.API_URL + "/images/qr/" + qrPrimary.frontImage
        } height="100" width="100" />`;
        paymentDetails.push({ name: "Gpay", value: sorc });
      }
      if (postprefcurrency.some((e) => e === "UPI") && upiPrimary) {
        paymentDetails.push({ name: "UPI", value: upiPrimary });
      }
    }
    setPayment(paymentDetails);
    // remove errors
    setValidateError({ ...validateError, [name]: "" });
  };
  // Currency change
  const curencyChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);

    var pair = posttocurrency + value;
    if (name == "posttocurrency") {
      var pair = value + currencyselected;
      let firstCurData = walletData.find(
        (el) => el.currency.currencySymbol == value
      );
      setUserBalance(firstCurData);
    } else {
      var pair = posttocurrency + value;
    }

    spotpairsdata.map((item, i) => {
      if (item.tiker_root === pair) {
        setCurmarketprice(item.index_price);
        setFee(item.transactionfee);
        var totPrice = quantity * item.index_price;
        setPosttradeprice(totPrice);
      }
    });
  };

  const handleposttocurrency = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    var pair = currencyselected + value;
    var tempprice = 0;

    var curindex = spotpairsdata.findIndex((x) => x.tiker_root === pair);
    if (curindex != -1) {
      tempprice = spotpairsdata[curindex].index_price;
      setCurmarketprice(tempprice);
      setFee(spotpairsdata[curindex].transactionfee);
    }
    var totPrice = quantity * curmarketprice;
    setPosttradeprice(totPrice);
  };

  const handlepostprefcurrency = (e) => {
    const { name, value, checked } = e.target;
    let checkedValues = postprefcurrency;
    if (checked) checkedValues = checkedValues.concat(value);
    else checkedValues = postprefcurrency.filter((e) => {return e !== value});
    let formData = { ...formValue, ...{ [name]: checkedValues.sort() } };
    setFormValue(formData);

    let paymentDetails = [];
    if(BuyorSell=="Sell") {
      if (checkedValues.some((e) => e==="Bank") && bankPrimary) {
        let bankPayment = bankPrimary.bankName + " - " + bankPrimary.accountNo;
        paymentDetails.push({name:"Bank",value:bankPayment});
      }
      if (checkedValues.some((e) => e==="Gpay") && qrPrimary) {
        sorc = `<img src=${config.API_URL + "/images/gpay/" + qrPrimary.frontImage} height="100" width="100" />`;
        paymentDetails.push({name:"Gpay",value:sorc});
      }
      if (checkedValues.some((e) => e==="UPI") && upiPrimary) {
        paymentDetails.push({name:"UPI",value:upiPrimary});
      }
    }

    setPayment(paymentDetails);
    // remove errors
    setValidateError({ ...validateError, [name]: "" });
  };

  const onChangeCheckbox = (e) => {
    let formData = { ...formValue, ...{ [e.target.id]: e.target.checked } };
    setFormValue(formData);
    // remove errors
    setValidateError({ ...validateError, [e.target.id]: "" });
  };

  // const postSubmit = (e)=>{
  const postSubmit = async (e) => {
    e.preventDefault();

    try {
      let reqData = {
        userId,
        fromcurrency: posttocurrency,
        tocurrency: currencyselected,
        type: BuyorSell,
        minlimit: postminlimit,
        maxlimit: posttradeprice,
        quantity,
        postcheckboxaccept,
        postprefcurrency,
        startDate,
        price,
        bankPrimary,
        upiPrimary,
        qrPrimary,
      };

      const validateError = await validation(reqData);
      if (!isEmpty(validateError)) {
        setValidateError(validateError);
      } else {
        setValidateError("");
        window.$("#confirm_post").modal("show");
      }
    } catch (err) {
      console.log("errrrr", err);
    }
  };

  const confirmPost = async (e) => {
    e.preventDefault();
    let reqData = {
      userId: userId,
      fromcurrency: posttocurrency,
      tocurrency: currencyselected,
      type: BuyorSell,
      minlimit: postminlimit,
      maxlimit: posttradeprice,
      quantity: quantity,
      price: price,
      postcheckboxaccept: postcheckboxaccept,
      postprefcurrency: postprefcurrency,
      startDate: startDate,
    };
    let { status, loading, message, error } = await postTrade(
      reqData,
      dispatch
    );
    if (status == true) {
      setFormValue(initialFormValue);
      window.$("#confirm_post").modal("hide");
      toastAlert("success", message, "login");
      history.push("/myads");
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  // Initial values getting function
  const fetchData = async () => {
    try {
      const { status, loading } = await getKycDetail(dispatch);
    } catch (err) {}
  };

  const assetBalance = async () => {
    try {
      const { result, status, loading } = await getAssetData(dispatch);
    } catch (err) {}
  };
  const currencyDetails = async () => {
    try {
      const { result, status, loading } = await getCurrency(dispatch);
    } catch (err) {}
  };
  const calculatePrice = async (val) => {
    var tradeprice = val * quantity;
    setPosttradeprice(tradeprice);
  };
  const p2pSpotPair = async () => {
    try {
      const { result, status, loading } = await getP2PPairList(dispatch);

      if (result) {
        setSpotpairsdata(result);

        var names_array_new = result.reduceRight(function (r, a) {
          r.some(function (b) {
            return a.first_currency === b.first_currency;
          }) || r.push(a);
          return r;
        }, []);
        setp2pcurrenncylistunic(names_array_new);

        var pair = posttocurrency + currencyselected;
        var indexonchcange = spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        {
          result.length > 0 &&
            result.map((item, i) => {
              if (item.tiker_root === pair) {
                setCurmarketprice(item.index_price);
                setFee(item.transactionfee);
              }
            });
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    // getKycDetail(dispatch)
    if (!isEmpty(userKyc)) {
      fetchData();
      assetBalance();
      currencyDetails();
      p2pSpotPair();
    }
    
  }, []);

  useEffect(() => {
    if (userKyc && userKyc) {
      setUserId(userKyc.userId);
      if (
        (userKyc.addressProof &&
          userKyc.addressProof.status != "" &&
          userKyc.addressProof.status != "approved") ||
        (userKyc.idProof &&
          userKyc.idProof.status != "" &&
          userKyc.idProof.status != "approved")
      ) {
        toastAlert("success", "Please submit your kyc...");
        history.push("/kyc");
      }
    }

    if (walletData && walletData.length > 0) {
      let firstCurData = walletData.find(
        (el) => el.currency.currencySymbol == posttocurrency
      );
      if (firstCurData) {
        setUserBalance(firstCurData);
        setBalLoader(false);
      }
    }

    if (upiData && upiData.result && upiData.result.length > 0) {
      upiData.result.map((item, key) => {
        if (item.isPrimary == true) {
          setUpiPrimary(item.upiId);
        }
      });
    }
    if (qrData && qrData.result) {
      qrData.result.map((item, key) => {
        if (item.isPrimary == true) {
          setQRPrimary(item);
        }
      });
    }
    if (bankData && bankData.result) {
      bankData.result.map((item, key) => {
        if (item.isPrimary == true) {
          setBankPrimary(item);
        }
      });
    }

    if(currencyData && currencyData.length > 0){
      let currencyCheck = currencyData.find((el) => (el.currencySymbol == currencyselected) )
      console.log(currencyCheck,"currencyCheck")
      
      if(currencyCheck == undefined){
       let dataCurrency = currencyData.find((el) => el.type == 'fiat')
       if(dataCurrency != undefined){
        let formData = { ...formValue, ...{ 'currencyselected': dataCurrency.currencySymbol } };
        setFormValue(formData);
       }
        
      }

    }
  }, [userKyc, walletData, upiData, bankData, qrData, currencyData]);

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

console.log(userBalance,"userBalanceuserBalance")

  return (
    <div className="dashboardMain">
      <div className="dashboardRight afterLoginHeader">
        <Header
          className="header"
          color="transparent"
          routes={dashboardRoutes}
          brand={
            <img
              src={require("../../assets/images/logo.png")}
              alt="logo"
              className="img-fluid"
            />
          }
          rightLinks={<HeaderLinksAfterlogin />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "dark",
          }}
          {...rest}
        />
        <div className="dashboardContent userPages peer2peer_section">
          <div className="container">
            <GridContainer>
              <GridItem xs={12} sm={12} md={5} lg={5}>
                <h3 className="dash_title">
                  Peer-to-Peer{" "}
                  <span>
                    <i class="fas fa-chevron-right arrow_key_titlw"></i>{" "}
                  </span>{" "}
                  <span className="color_gol-sd">Post My Ad</span>
                </h3>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="dashboard_box t2patid">
                  <div className="end_content mar---butm">
                    {" "}
                    <p className="color_whit_7 with_22_2  inputabowlabel">
                      <span className="spna_ifd">Wallet:</span>
                      {balLoader && <i className="fas fa-spinner fa-spin"></i>}
                      {!balLoader && (
                        <label className="label_color_78">
                          {parseFloat(toFixed(userBalance && userBalance.p2pbalance, 8))}{" "}
                          {posttocurrency}
                        </label>
                      )}
                      <a className="unde_lin_qwe" href="/p2pwallet">
                        All
                      </a>
                    </p>
                  </div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <div className="contact_form mb-0 w100_form_p2p pa125sd">
                        {/* <form className="contact_form mb-0 w100_form_p2p pa125sd"> */}
                        <div className="settingsSelect clas_Newselect clas_Newselect124">
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group">
                                <label className="label_color_78">
                                  I want to{" "}
                                </label>
                                <Select
                                  value={10}
                                  name="BuyorSell"
                                  value={BuyorSell}
                                  onChange={handleChange}
                                >
                                  <MenuItem value="Buy">Buy</MenuItem>
                                  <MenuItem value="Sell">Sell</MenuItem>
                                </Select>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group">
                                <label className="label_color_78">
                                  Crypto Currency
                                </label>
                                <Select
                                  name="posttocurrency"
                                  value={posttocurrency}
                                  onChange={curencyChange}
                                >
                                  {p2pcurrencylistunicc &&
                                    p2pcurrencylistunicc.length > 0 &&
                                    p2pcurrencylistunicc.map((item, key) => {
                                      return (
                                        <MenuItem value={item.first_currency}>
                                          {item.first_currency}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group">
                                <label className="label_color_78">
                                  For Fiat Currency
                                </label>
                                <Select
                                  name="currencyselected"
                                  value={currencyselected}
                                  onChange={curencyChange}
                                >
                                  {currencyData &&
                                    currencyData.length > 0 &&
                                    currencyData.map((item, key) => {
                                      console.log(item,"item")
                                      if (item.type == "fiat") {
                                        return (
                                          <MenuItem value={item.currencySymbol}>
                                            {item.currencySymbol}
                                          </MenuItem>
                                        );
                                      }
                                    })}
                                </Select>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="mt-151_15"
                            >
                              <div className="form-group">
                                <div className="w-100">
                                  <label className="label_color_78">
                                    Quantity
                                  </label>
                                  <div class="input-group ">
                                    <input
                                      type="text"
                                      name="quantity"
                                      class="form-control"
                                      value={quantity}
                                      onChange={handleChange}
                                      onInput={validPositive}
                                    />
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        className="inpor_inside_buttons"
                                      >
                                        {posttocurrency}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {validateError.quantity && (
                                  <p className="error-message">
                                    {validateError.quantity}
                                  </p>
                                )}
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="paddin_gri_po mt-151_15"
                            >
                              {/* <div className="form-group d-flex_p2p d-flex_p2p_23">
                                <label className="label_color_78 label_widthsdf">Price</label>
                                <div className="flot_fixe_ins width_input">
                                <input type="text" className="form-control" value={posttradeprice} readOnly={true}/>
                                <div class="input-group-append">
                                <button type="button" className="inpor_inside_buttons">{currencyselected}</button>
                              </div>
                                </div>
                                
                              </div> */}
                              {/* <div className="form-group d-flex_p2p d-flex_p2p_23">
                                <label className="label_color_78">Price</label>
                                <div class="input-group ">
                               
                                <input type="number" name="Price" class="form-control" value={posttradeprice}/>
                              <div class="input-group-append">
                                <button type="button" className="inpor_inside_buttons">{currencyselected}</button>
                              </div>
                              {validateError.quantity && <p className="error-message">{(validateError.quantity)}</p>} 
                            </div>
                                
                              </div> */}
                              <div className="form-group">
                                <div className="w-100">
                                  <label className="label_color_78">
                                    Per {posttocurrency} Price
                                  </label>
                                  <div class="input-group ">
                                    <input
                                      type="text"
                                      name="price"
                                      class="form-control"
                                      value={price}
                                      onBlur={(e) =>
                                        calculatePrice(e.target.value)
                                      }
                                      onChange={handleChange}
                                      onInput={validPositive}
                                    />
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        className="inpor_inside_buttons"
                                      >
                                        {currencyselected}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {validateError.price && (
                                  <p className="error-message">
                                    {validateError.price}
                                  </p>
                                )}
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={8}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group">
                                <label className="label_color_78 ">
                                  per {posttocurrency}
                                </label>
                                <div className="bitcoin_value_box">
                                  <h3>
                                    <span>Current Market Price </span>1{" "}
                                    {posttocurrency} = {curmarketprice}{" "}
                                    {currencyselected}
                                  </h3>
                                </div>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={7}
                              className="mt-151_15"
                            >
                              <div className="form-group row">
                                <div className="col-sm-6 col-12">
                                  <label className="label_color_78">
                                    Price Limit From{" "}
                                  </label>
                                  <div class="input-group ">
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="postminlimit"
                                      value={postminlimit}
                                      onChange={handleChange}
                                      onInput={validPositive}
                                    />
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        className="inpor_inside_buttons"
                                      >
                                        {currencyselected}
                                      </button>
                                    </div>
                                  </div>
                                  {validateError.postminlimit && (
                                  <p className="error-message">
                                    {validateError.postminlimit}
                                  </p>
                                )}
                                </div>
                                <div className="col-sm-6 col-12">
                                <label className="label_color_78">To</label>
                                <div class="input-group ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="postmaxlimit"
                                    value={posttradeprice}
                                    readOnly
                                  />
                                  <div class="input-group-append">
                                    <button
                                      type="button"
                                      className="inpor_inside_buttons"
                                    >
                                      {currencyselected}
                                    </button>
                                  </div>
                                </div>
                                {validateError.postmaxlimit && (
                                  <p className="error-message">
                                    {validateError.postmaxlimit}
                                  </p>
                                )}
                              </div>
                              </div>
                             
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={5}
                              className="mt-151_15"
                            >
                              <div className="form-group w100_form_p2p date-width">
                                <label
                                  className="primary_label"
                                  htmlFor="minimumbid"
                                >
                                  Show My Post Till
                                </label>
                                <div className="wdth--1001">
                                  <DatePicker
                                    className="form-control primary_inp"
                                    selected={startDate}
                                    onChange={(date) => {
                                      setStartDate(date);
                                      setValidateError({
                                        ...validateError,
                                        ["startDate"]: "",
                                      });
                                    }}
                                    selectsStart
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    showTimeInput
                                  ></DatePicker>
                                  <i class="far fa-calendar-alt"></i>
                                </div>
                              </div>
                              {validateError.startDate && (
                                <p className="error-message">
                                  {validateError.startDate}
                                </p>
                              )}
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={12}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group d-flex_p2p d-flex_p2p_23 whithAutft select_width justify-content-start">
                                <label className="label_color_78">
                                  Preferred Payments
                                </label>
                                <input
                                  type="checkbox"
                                  value="Bank"
                                  id="bank"
                                  name="postprefcurrency"
                                  onChange={handlepostprefcurrency}
                                  checked={postprefcurrency.some((e) => e==="Bank")}
                                />
                                <label htmlFor="bank">
                                  Bank
                                </label>
                                <input
                                  type="checkbox"
                                  value="Gpay"
                                  id="gpay"
                                  name="postprefcurrency"
                                  onChange={handlepostprefcurrency}
                                  checked={postprefcurrency.some((e) => e==="Gpay")}
                                />
                                <label htmlFor="gpay">
                                  Gpay
                                </label>
                                <input
                                  type="checkbox"
                                  value="UPI"
                                  id="upi"
                                  name="postprefcurrency"
                                  onChange={handlepostprefcurrency}
                                  checked={postprefcurrency.some((e) => e==="UPI")}
                                />
                                <label htmlFor="upi">
                                  UPI
                                </label>
                              </div>
                              {
                                payment && payment.length > 0 && payment.map((detail,key)=> {
                                  return (<div key={key}>{`${detail.name} : `}{ReactHtmlParser(detail.value)}</div>);
                                })
                              }

                              {validateError.postprefcurrency && (
                                <p className="error-message">
                                  {validateError.postprefcurrency}
                                </p>
                              )}
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className="paddin_gri_po mt-151_15"
                            >
                              <p className="ckksdyt_box justify-content-start">
                                <input
                                  type="checkbox"
                                  id="postcheckboxaccept"
                                  onChange={onChangeCheckbox}
                                  value={postcheckboxaccept}
                                />
                                I agree to Escrow my crypto funds and accept the{" "}
                                <span>
                                  <a href="/terms">terms</a>
                                </span>{" "}
                                and{" "}
                                <span>
                                  <a href="/privacy">policy</a>
                                </span>
                              </p>
                              {validateError.postcheckboxaccept && (
                                <p className="error-message">
                                  {validateError.postcheckboxaccept}
                                </p>
                              )}

                              <span className="ckksdyt_box">
                                Transaction Fee for Seller : {fee} %
                              </span>
                              <button
                                onClick={postSubmit}
                                className="btn btn-primary text-uppercase py-2 display_block_mars"
                              >
                                Submit Post
                              </button>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </div>

                      {/* </form> */}
                    </GridItem>
                  </GridContainer>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="confirm_post"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirm_post"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="confirm_body ">
                <h2>Confirm My Post</h2>
                <img
                  src={require("../../assets/images/postconfirm.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="model_detail_text">
                  <div className="detail_text_model">
                    <span className="righttext">Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{BuyorSell}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Cryptocurrency:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{posttocurrency}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Price:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {price} {currencyselected}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Quantity: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {quantity} {posttocurrency}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Limit:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {postminlimit} {currencyselected} - {posttradeprice}{" "}
                      {currencyselected}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Payment Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{postprefcurrency.join(", ")}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Post Show Till:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      <Moment format="DD-MM-YYYY hh:mm">{startDate}</Moment>
                    </span>
                  </div>
                  {/* <div className="detail_text_model">
                                  <span className="righttext">Post ID:</span>
                                </div>
                                <div className="detail_text_model">
                                  <span className="colo_fff">#123456</span>
                                </div> */}
                </div>
                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button onClick={confirmPost} className="button1">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
