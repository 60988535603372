import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import {Select } from '@material-ui/core';
import { useHistory } from "react-router-dom";

import {getNotificationHistory,cancelMyRequest,setNotificationHistory} from '../../actions/dashboardAction';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import ReactDatatable from '@ashvin27/react-datatable';
import CancelOrder from 'components/SportTrade/CancelOrder';
import moment from 'moment';
import { dateTimeFormat } from '../../lib/dateTimeHelper';
import { toastAlert } from "../../lib/toastAlert";
import { Alert } from "react-bootstrap";
var dateFormat      = require('dateformat');
// Default export is a4 paper, portrait, using millimeters for units


const dashboardRoutes = [];


function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return null;
}
export default function NotificationHistory(props) {
    const history = useHistory();

    const dispatch=useDispatch();
    const[userId,setuserId]=useState();
   // const[transtype,setTranstype]=useState();
    const[currency,setCurrency]=useState();
    const[transData,setTransData]=useState([]);
    const [rocord,setRecord]=useState([]);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });
    const doc = new jsPDF();

    
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
  
    const authDetails = useSelector(state => state.auth);
    const currencyData = useSelector(state => state.currency);
    const { ...rest } = props;
    // state
  //  const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);

    // function
    const fetchNotificationHistory = async () => {
        try {
            // let reqData={
            //     userId:user
            // }
            const { status, loading, result } = await getNotificationHistory();

            var arr=[];
            setLoader(loading);
            if (status == 'success') {
                result.map((item,key)=>{
                    // if(item.type=="General")
                arr.push({
                    created_date:item.created_date,
                    description:item.description,
                    category:item.category
                })
                })
                setRecord(arr)

                
            }
        } catch (err) { }
    }
    const changereadcount = async () => {
        try {
            // let reqData={
            //     userId:user
            // }
            const { status, loading, result } = await setNotificationHistory();
            setLoader(loading);
         //   setData(result);
            if (status == 'success') {
                setTransData(result);
            }
        } catch (err) { }
    }
    useEffect(() => {
      
        fetchNotificationHistory()
        changereadcount()
    }, [])

     const configdata = {
      page_size: 10,
      length_menu: [ 10, 20, 50 ],
      filename: "Users",
      no_data_text: 'No user found!',
      language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
              first: "First",
              previous: "Previous",
              next: "Next",
              last: "Last"
          }
      },
      show_length_menu: false,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
};
  

    const cryptoHistoryColumns = [
        {
          text: 'Date',
          key: 'created_date',
          className:'Date',
          sortable: false,
          width:'200px',
          cell:record=>{
              return(
                <div data-tag="allowRowEvents">{moment(record.created_date).format('DD-MM-YYYY hh:mm')}</div>
                 
              );
          }
        },
        {
          text: 'Category',
          className: 'Trade Type',
          key: 'category',
          sortable: false,
          width:'150px',
          cell:record=>{
             
                return(
                    <div data-tag="allowRowEvents">{record.category}</div>
                     
                  );
           }
        },
       

          {
            text: 'Description',
            className: 'Description',
            key: 'description',
            sortable: false,
            width:'150px'
          },
             
      ];
   
  return (
    <>
        
        <div className=" stakingHistoryTable boxSpace box_bg">
       
            <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
            <h3 class="dash_title mb-3 notigy_color">All Notifications</h3>           
            </div>
            <ReactDatatable
                className="historyCryptoTable tableBorder"
                records={rocord}
                columns={cryptoHistoryColumns}
                config={configdata}
               // onPageChange={pageChange()}
              />
           {/* <DataTable className="historyCryptoTable" columns={cryptoHistoryColumns}data={transData} noHeader /> */}
        </div>
    </>
  );
}
