// import package
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";

// @material-ui/core components
import { useHistory } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// import action
import {
  getMyRecentaddData,
  updateTrade,
  cancelMyadAction,
} from "../../actions/p2pAction";

// import lib
import validation from "./validation";
import { toFixed } from "lib/roundOf";
import { toastAlert } from "../../lib/toastAlert";

const dashboardRoutes = [];

const initialFormValue = {
  currencyselected: "BTC",
  posttocurrency: "USD",
  postprefcurrency: [],
  postminlimit: "",
  postmaxlimit: "",
  posttooptions: [],
  postcheckboxaccept: false,
  BuyorSell: "Buy",
  quantity: 0,
  price: 0,
  postId: "",
  secondamount: "",
};

export default function MyAds(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [userBalance, setUserBalance] = useState(0);
  const [posttradeprice, setPosttradeprice] = useState(0);
  const [userId, setUserId] = useState("");
  const [myaddDetails, setMyaddDetails] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(10);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [upiPrimary, setUpiPrimary] = useState("");
  const [bankPrimary, setBankPrimary] = useState("");
  const [gpayPrimary, setGPayPrimary] = useState("");

  const {
    currencyselected,
    posttocurrency,
    postprefcurrency,
    postminlimit,
    postmaxlimit,
    posttooptions,
    postcheckboxaccept,
    BuyorSell,
    quantity,
    price,
    postId,
  } = formValue;

  const userKyc = useSelector((state) => state.userKyc);
  const walletData = useSelector((state) => state.wallet);
  const authDetails = useSelector((state) => state.auth);
  const upiData = useSelector((state) => state.upiDetail);
  const bankData = useSelector((state) => state.bankDetail);
  const gpayData = useSelector((state) => state.qrCodeDetail);

  const configdata = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    key_column: "_id",
    show_length_menu: false,
    show_filter: true,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
  };

  const { ...rest } = props;

  const cryptoHistoryColumns = [
    {
      text: "Date",
      key: "orderDate",
      className: "orderDate",
      sortable: false,
      cell: (record) => {
        return moment(record.orderDate).format("DD-MM-YYYY");
      },
    },
    {
      text: "Ad Type",
      className: "Ad Type",
      key: "BuyorSell",
      sortable: false,
      padding: 5
    },
    {
      text: "Trade Pair",
      className: "Trade Pair",
      key: "pair",
      sortable: false,
      padding: 5
    },
    {
      text: "Limit",
      className: "Limit",
      key: "maxlimit",
      sortable: false,
      padding: 5,
      cell: (record) => {
        return `${record.minlimit} - ${record.maxlimit}`;
      },
    },

    {
      text: "Quantity",
      className: "Quantity",
      key: "quantity",
      sortable: false,
      padding: 5,
      cell: (record) => {
        return parseFloat(toFixed(record.quantity, 8));
      },
    },

    {
      text: "Payment Type",
      className: "Payment Type",
      key: "transferMode",
      sortable: false,
      padding: 5,
      cell: (record) => {
        return record.transferMode.join(', ');
      },
    },
    {
      text: "Status",
      className: "Status",
      key: "status",
      sortable: false,
      padding: 5,
      cell: (record) => {
        console.log("🚀 ~ file: MyAds.js ~ line 175 ~ MyAds ~ record", record)
        if (record.status == "0") {
          return (
            <div>
              <button
                className=" button_take_control mr-3"
                onClick={() => updateMyad(record)}
              >
                Edit Ad
              </button>
              <button
                className=" button_take_control"
                onClick={() => cancelMyad(record)}
              >
                Cancel Ad
              </button>
            </div>
          );
        }
        if (record.status == "2") {
          return <span className="textStatusGreen">Trade In Process</span>;
        }
        if (record.status == "1") {
          return <span className="textStatusGreen">Completed</span>;
        }
        if (record.timeClose == '1') {
          return <span className="textWithdrawRed">Time Exceeded </span>;
        }
        if (record.status == "3") {
          return <span className="textWithdrawRed">Cancelled </span>;
        }
        if (record.status == "5") {
          return <span className="textWithdrawRed">Closed</span>;
        }

      },
    },
  ];

  const getMyads = async (user) => {
    try {
      let data = {
        search,
        curUser: user,
        limit: pageSize,
        offset: offset,
      };
      setLoader(true);
      let { status, result, totalCount } = await getMyRecentaddData(data);
      setLoader(false);
      if (status == "success") {
        setTotalCount(totalCount);
        setMyaddDetails(result);
      }
    } catch (err) { }
  };

  const filter = (data) => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number - 1) * pageSize;
    setMyaddDetails([]);
    setOffset(offset);
    setSearch(filter);
  };

  const updateMyad = async (e) => {
    window.$("#edit_post").modal("show");
    if (e && e != undefined) {
      var totprice = e.maxlimit / e.quantity;
      let formData = {
        ...formValue,
        ...{
          postprefcurrency: e.transferMode,
          postminlimit: e.minlimit,
          postmaxlimit: e.maxlimit,
          quantity: e.quantity,
          price: totprice,
          postId: e._id,
          currencyselected: e.firstCurrency,
          BuyorSell: e.BuyorSell,
        },
      };
      setPosttradeprice(e.maxlimit);
      setFormValue(formData);
    }
  };

  const cancelMyad = async (e) => {
    window.$("#cancel_post").modal("show");
    if (e && e != undefined) {
      let formData = {
        ...formValue,
        ...{
          postprefcurrency: e.transferMode,
          postminlimit: e.minlimit,
          postmaxlimit: e.maxlimit,
          price: e.price,
          postId: e._id,
          currencyselected: e.firstCurrency,
          BuyorSell: e.BuyorSell,
        },
      };
      setFormValue(formData);
    }
  };

  useEffect(() => {
    if (userKyc && userKyc) {
      if ((userKyc.addressProof && userKyc.addressProof.status != "" && userKyc.addressProof.status != "approved") || (userKyc.idProof && userKyc.idProof.status != "" && userKyc.idProof.status != "approved")) {
        toastAlert("success", "Please complete your kyc...");
        history.push("/kyc");
      }
    }

    if (walletData && walletData.length > 0) {
      var index = walletData.findIndex(
        (x) => x.currencySymbol === currencyselected
      );
      if (index != -1) {
        setUserBalance(walletData[index].p2pbalance);
      }
    }

    if (bankData && bankData.result) {
      bankData.result.map((item, key) => {
        if (item.isPrimary == true) {
          setBankPrimary(item);
        }
      });
    }
    if (upiData && upiData.result && upiData.result.length > 0) {
      upiData.result.map((item, key) => {
        if (item.isPrimary == true) {
          setUpiPrimary(item.upiId);
        }
      });
    }
    if (gpayData && gpayData.result) {
      gpayData.result.map((item, key) => {
        if (item.isPrimary == true) {
          setGPayPrimary(item);
        }
      });
    }
  }, [userKyc, walletData, bankData, upiData, gpayData]);

  useEffect(() => {
    if (authDetails && authDetails.userId) {
      setUserId(authDetails.userId);
      getMyads(authDetails.userId);
    }
  }, [authDetails, offset, search]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (name == "quantity") {
      var curprice = toFixed(price * value, 2);
      setPosttradeprice(curprice);
    }
    if (name == "price") {
      var curprice = toFixed(quantity * value, 2);
      setPosttradeprice(curprice);
      var error = { ...validateError, ...{ ["postprice"]: "" } };
    } else {
      var error = { ...validateError, ...{ [name]: "" } };
    }
    setValidateError(error);
  };

  const handlepostprefcurrency = (e) => {
    const { name, value, checked } = e.target;
    let checkedValues = postprefcurrency;
    if (checked) checkedValues = checkedValues.concat(value);
    else checkedValues = postprefcurrency.filter((e) => { return e !== value });
    let formData = { ...formValue, ...{ [name]: checkedValues.sort() } };
    setFormValue(formData);
    // remove errors
    setValidateError({ ...validateError, [name]: "" });
  };

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const updatePost = async (e) => {
    e.preventDefault();
    let reqData = {
      type: BuyorSell,
      postId,
      userId,
      price,
      quantity,
      minlimit: postminlimit,
      maxlimit: posttradeprice,
      postprefcurrency,
      bankPrimary,
      upiPrimary,
      gpayPrimary,
    };

    let { status, loading, message, error } = await updateTrade(
      reqData,
      dispatch
    );

    if (status == "success") {
      window.$("#edit_post").modal("hide");
      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      getMyads(authDetails.userId);
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  const cancelPost = async (e) => {
    e.preventDefault();
    let reqData = {
      postId: postId,
      userId: userId,
      price: price,
      minlimit: postminlimit,
      maxlimit: postmaxlimit,
      postprefcurrency: postprefcurrency,
    };

    let { status, loading, message, error } = await cancelMyadAction(
      reqData,
      dispatch
    );
    if (status == "success") {
      window.$("#cancel_post").modal("hide");
      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      getMyads(authDetails.userId);
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  return (
    <div className="dashboard_container page_wrap">
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header
            className="header"
            color=""
            routes={dashboardRoutes}
            brand={
              <img
                src={require("../../assets/images/logo.png")}
                alt="logo"
                className="img-fluid"
              />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest}
          />
          <div className="dashboardContent userPages peer2peer_section ">
            <div className="container pt-5">
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">
                    Peer-to-Peer{" "}
                    <span>
                      <i className="fas fa-chevron-right arrow_key_titlw"></i>{" "}
                    </span>{" "}
                    <span className="color_gol-sd">My Ad Details</span>
                  </h3>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="dashboard_box t2patid">
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <div className="table-responsive">
                        <ReactDatatable
                          className="historyCryptoTable w-100"
                          records={myaddDetails}
                          columns={cryptoHistoryColumns}
                          config={configdata}
                          dynamic={true}
                          onChange={filter}
                          total_record={totalCount}
                        />
                      </div>
                    </GridItem>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}
        <div
          className="modal fade buy_sellaction confirm_sellaction modalzindex"
          id="edit_post"
          tabindex="-1"
          role="dialog"
          aria-labelledby="edit_post"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="confirm_body ">
                  <h2>Edit My Post</h2>
                  <img
                    src={require("../../assets/images/postconfirm.png")}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="model_detail_text">
                    <div className="detail_text_model">
                      <span className="righttext">Type:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">{BuyorSell}</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Cryptocurrency:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">{currencyselected}</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Quantity:</span>
                    </div>
                    <div className="detail_text_model">
                      <input
                        type="number"
                        className="form-control"
                        name="quantity"
                        value={quantity}
                        onChange={handleChange}
                        onInput={validPositive}
                      />
                      {validateError.quantity && (
                        <p className="error-message">{validateError.quantity}</p>
                      )}
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Price:</span>
                    </div>
                    <div className="detail_text_model">
                      <input
                        type="number"
                        className="form-control"
                        name="price"
                        value={price}
                        onChange={handleChange}
                        onInput={validPositive}
                      />
                      {validateError.postprice && (
                        <p className="error-message">{validateError.postprice}</p>
                      )}
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Price Limit From: </span>
                    </div>
                    <div className="detail_text_model">
                      <input
                        type="number"
                        className="form-control"
                        name="postminlimit"
                        value={postminlimit}
                        onChange={handleChange}
                        onInput={validPositive}
                      />
                      {validateError.postminlimit && (
                        <p className="error-message">
                          {validateError.postminlimit}
                        </p>
                      )}
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Price Limit To: </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">
                        <input
                          type="number"
                          className="form-control"
                          name="postmaxlimit"
                          value={posttradeprice}
                          onChange={handleChange}
                          disabled
                        />
                      </span>
                      {validateError.postmaxlimit && (
                        <p className="error-message">
                          {validateError.postmaxlimit}
                        </p>
                      )}
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Preferred Payments: </span>
                    </div>
                    <div className="detail_text_model select_box">
                      <input
                        type="checkbox"
                        value="Bank"
                        id="bank"
                        name="postprefcurrency"
                        onChange={handlepostprefcurrency}
                        checked={postprefcurrency.some((e) => e === "Bank")}
                      />
                      <label htmlFor="bank" className="padin_s_checknox">
                        Bank
                      </label>
                      <input
                        type="checkbox"
                        value="Gpay"
                        id="gpay"
                        name="postprefcurrency"
                        onChange={handlepostprefcurrency}
                        checked={postprefcurrency.some((e) => e === "Gpay")}
                      />
                      <label htmlFor="gpay" className="padin_s_checknox">
                        Gpay
                      </label>
                      <input
                        type="checkbox"
                        value="UPI"
                        id="upi"
                        name="postprefcurrency"
                        onChange={handlepostprefcurrency}
                        checked={postprefcurrency.some((e) => e === "UPI")}
                      />
                      <label htmlFor="upi" className="padin_s_checknox">
                        UPI
                      </label>
                      {validateError.postprefcurrency && (
                        <p className="error-message">
                          {validateError.postprefcurrency}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="new_confirm_pay_button mat_205">
                    <button className="button1 button2" data-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      className="button1 butn_new_w pr-2 pl-2"
                      onClick={updatePost}
                    >
                      Submit Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade buy_sellaction confirm_sellaction modalzindex"
          id="cancel_post"
          tabindex="-1"
          role="dialog"
          aria-labelledby="cancel_post"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="confirm_body ">
                  <h2>Cancel My Post</h2>
                  <img
                    src={require("../../assets/images/postconfirm.png")}
                    alt=""
                    className="img-fluid"
                  />
                  <div className="model_detail_text">
                    <div className="detail_text_model">
                      <span className="righttext">Type:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">{BuyorSell}</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Cryptocurrency:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">{currencyselected}</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Price:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">{price}</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Price Limit From: </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">{postminlimit}</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Price Limit To: </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">{postmaxlimit} </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Preferred Payments: </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">{postprefcurrency.join(', ')} </span>
                    </div>
                  </div>

                  <h4>Are you sure want to cancel the Ad?</h4>

                  <div className="new_confirm_pay_button mat_205">
                    <button className="button1 button2" data-dismiss="modal">
                      No{" "}
                    </button>
                    <button className="button1" onClick={cancelPost}>
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
