let key = {};
if (process.env.REACT_APP_MODE === "production") {

    console.log("Set Production Config")
    const API_URL = 'https://api.tradax.pro';

    key = {
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        Recaptchakey: "6LdfEEIcAAAAAJSzSbMQlDS4foTXt6QI-_g2s8pe", 
        FRONT_URL: "https://tradax.pro",
        ADMIN_URL: "https://tradax.pro/admin",
        API_URL: `${API_URL}`,
        CHART_URL: `${API_URL}`,
        SOCKET_URL: `${API_URL}`,
        TRADE_URL: 'https://java.deepliquidity.exchange/api/trade',
        getGeoInfo: "https://ipapi.co/json/",
        socialMedia: {
            facebook: {
                appId: "1034988646970193"
            },
            linkedIn: {
                clientId: '78szlpfkw7ee7s',
                redirectUrl: 'https://99893158a13c.ngrok.io/signup',
                oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
                scope: 'r_liteprofile%20r_emailaddress',
                state: '123456'
            }
        },

    };

} else {
    console.log("Set Development Config");

    const API_URL = 'http://localhost'; //http://192.168.29.82  http://localhost
    key = {
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        Recaptchakey: "6LdpeoQUAAAAAHwFEDfpcA-W5-leSH8548lZWWeb", //local
        FRONT_URL: 'http://localhost:3000',
        ADMIN_URL: 'http://localhost:3000/admin',
        API_URL: `${API_URL}:2056`,
        CHART_URL: `${API_URL}:2056`,
        SOCKET_URL: `${API_URL}:2056`,
        TRADE_URL: 'http://172.104.23.182:8081/api/trade', // 'http://192.168.29.181:8081/api/trade',
        
        getGeoInfo: "https://ipapi.co/json/",
        socialMedia: {
            facebook: {
                appId: "1034988646970193"
            },
            linkedIn: {
                clientId: '78szlpfkw7ee7s',
                redirectUrl: 'https://99893158a13c.ngrok.io/signup',
                oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
                scope: 'r_liteprofile%20r_emailaddress',
                state: '123456'
            }
        },

    };
}

export default key;
