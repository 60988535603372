// import package
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link, useParams, useHistory } from "react-router-dom";

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import History from "./History";
import P2phistory from "./P2phistory";
import Notification from "./Notification";
import UserDetail from "./UserDetail";
import LastLogin from "./LastLogin";
import BalanceDetail from "./BalanceDetail";

// import lib
import validation from "./validation";
import isEmpty from "lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

// import action
import { getUserDetail } from "../../actions/users";
import { getAssetData } from "../../actions/walletAction";
import { getCurrency } from "../../actions/commonAction";
import {
  getMyaddData,
  getP2PPairList,
  updateTrade,
  cancelMyadAction,
} from "../../actions/p2pAction";

const initialFormValue = {
  currencyselected: "BTC",
  posttocurrency: "USD",
  postprefcurrency: [],
  postminlimit: "",
  postmaxlimit: "",
  posttooptions: [],
  postcheckboxaccept: false,
  BuyorSell: "Buy",
  quantity: 0,
  price: "",
  postId: "",
};

export default function Dashboard(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [userId, setUserId] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [spotpairsdata, setSpotpairsdata] = useState([]);
  const [curmarketprice, setCurmarketprice] = useState(0);
  const [posttradeprice, setPosttradeprice] = useState(0);
  const [myaddDetails, setMyaddDetails] = useState([]);
  const [currentPost, setCurrentPost] = useState({});
  const [validateError, setValidateError] = useState({});

  const {
    currencyselected,
    posttocurrency,
    postprefcurrency,
    postminlimit,
    postmaxlimit,
    posttooptions,
    postcheckboxaccept,
    BuyorSell,
    quantity,
    price,
    postId,
  } = formValue;
  // redux-state
  const accountData = useSelector((state) => state.account);

  const authDetails = useSelector((state) => state.auth);
  // const accountData = useSelector(state => state.account);
  // const { userId, firstName, lastName, email, emailStatus, phoneStatus, phonenumber, type, createAt, bankDetail } = accountData;

  // Handle change for eidt the values
  const handleChange = (e) => {
    e.preventDefault();
    console.log("e.targete.target", e.target);
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };
  // Initial values getting function
  const fetchData = async () => {
    try {
      const { result, loading } = await getUserDetail();
      setUserDetails(result);
    } catch (err) {}
  };

  const assetBalance = async () => {
    try {
      const { result, status, loading } = await getAssetData(dispatch);
    } catch (err) {}
  };
  const currencyDetails = async () => {
    try {
      const { result, status, loading } = await getCurrency(dispatch);
    } catch (err) {}
  };
  const p2pSpotPair = async () => {
    try {
      const { result, status, loading } = await getP2PPairList(dispatch);

      if (result) {
        setSpotpairsdata(result);
        var tempdata = result;
        var pair = currencyselected + posttocurrency;
        var indexonchcange = spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        if (indexonchcange != -1) {
          var onchbaseprice = spotpairsdata[indexonchcange].index_price;
          setCurmarketprice(onchbaseprice);
        }
      }
    } catch (err) {}
  };

  const getMyads = async (user) => {
    try {
      let reqData = {
        curUser: user,
      };
      // return false;
      let { result, loading, message, error } = await getMyaddData(reqData);
      setMyaddDetails(result);
      // const { result, status, loading } = await getMyaddData(userId)
    } catch (err) {}
  };

  useEffect(() => {
    // if (!isEmpty(userKyc)) {
    fetchData();
    assetBalance();
    currencyDetails();
    p2pSpotPair();
    // }
  }, []);
  useEffect(() => {
    if (authDetails.userId) {
      // var user=authDetails.userId;
      setUserId(authDetails.userId);
      getMyads(authDetails.userId);
    }
    if (accountData.userId) {
      // var user=authDetails.userId;
    }
  }, [authDetails, accountData]);

  const handlepostprefcurrency = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const updateMyad = async (e) => {
    window.$("#edit_post").modal("show");
    if (e && e != undefined) {
      // setCurrentPost(e)
      let formData = {
        ...formValue,
        ...{
          postprefcurrency: e.transferMode,
          postminlimit: e.minlimit,
          postmaxlimit: e.maxlimit,
          price: e.price,
          postId: e._id,
          currencyselected: e.firstCurrency,
          BuyorSell: e.BuyorSell,
        },
      };
      setFormValue(formData);
    }
  };

  const cancelMypostad = async (e) => {
    //console.log("ssssssss----",e);
    // e.preventDefault();
    let reqData = {
      userId: userId,
      adid: e._id,
    };
    let { status, loading, message, error } = await cancelMyadAction(
      reqData,
      dispatch
    );
    if (status == "success") {
      // window.$('#edit_post').modal('hide');

      // setFormValue(initialFormValue)
      toastAlert("success", message, "login");
      history.push("/dashboard");
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  const updatePost = async (e) => {
    e.preventDefault();
    let reqData = {
      postId: postId,
      userId: userId,
      price: price,
      minlimit: postminlimit,
      maxlimit: postmaxlimit,
      postprefcurrency: postprefcurrency,
    };
    let { status, loading, message, error } = await updateTrade(
      reqData,
      dispatch
    );
    if (status == "success") {
      window.$("#edit_post").modal("hide");

      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      history.push("/dashboard");
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  return (
    <div className="dashboardContent userPages">
      <div className="container">
        <GridContainer>
          <GridItem xs={12} sm={12} md={5} lg={5}>
            <h3 className="dash_title">Dashboard</h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={7} lg={7}>
            <LastLogin />
            {/* <ul className="profile_dash">
                            <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li>
                            <li>13-05-2021  15:15, Chrome, 182.254.127  - <span>Last login</span></li>
                        </ul> */}
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8} lg={8}>
            <BalanceDetail />
          </GridItem>
          <GridItem xs={12} sm={12} md={4} lg={4}>
            <UserDetail />
            {/* <div className="dashboard_box">
                    <h5 className="dash_subtitle">User Details</h5>
                    <div className="dashUserDetails boxSpace">
                      <div className="dudLeft">
                        <img src={require("../../assets/images/dashUserPic.png")} alt="" className="img-fluid" />
                        <a href="#"><i class="fas fa-pencil-alt"></i></a>
                      </div>
                      <div className="dudRight">
                        <ul>
                          <li>
                            <label>UserID <span><i class="fas fa-check-circle enableGreen"></i> <span className="verified_green">KYC Verified</span>  on 13-05-2021</span></label>
                            <p>38260645</p>
                          </li>
                          <li>
                            <label>Email <span><i class="fas fa-check-circle enableGreen"></i><span className="verified_green">Verified </span> on 12-05-2021</span></label>
                            <p>email...@...mail.com <a data-tip data-for='clickmeUserDetails' data-event='click' className="ml-2"><i class="fas fa-info-circle"></i></a>
                              <ReactTooltip id='clickmeUserDetails' place='right' effect='solid' clickable={true}>For email change we need to confirm<br />both current email address with a code<br />and current phone number with a code</ReactTooltip></p>
                          </li>
                          <li>
                            <label>Phone <span><i class="fas fa-times-circle disabledRed"></i> <span className="not_verifed">Not Verified</span>[ <a href="#">Verify Now</a> ]</span></label>
                            <p>xxxxxxxxx</p>
                          </li>
                          <li>
                            <label>Bank <span><i class="fas fa-check-circle enableGreen"></i><span className="verified_green">Verified </span> on 12-05-2021</span></label>
                            <p>ICICI Bank, New Delhi, India</p>
                            <p>Account no. 6004.....2547</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
          </GridItem>
        </GridContainer>
        <History />
        <P2phistory />
        <div className="dashboard_box dashSecurityTable">
          <h5 class="dash_subtitle">Security</h5>
          <div class="table-responsive boxSpace">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    2 Factor Authentication{" "}
                    <a
                      data-tip
                      data-for="clickme"
                      data-event="click"
                      className="ml-2"
                    >
                      <i class="fas fa-info-circle"></i>
                    </a>
                    <ReactTooltip
                      id="clickme"
                      place="right"
                      effect="solid"
                      clickable={true}
                    >
                      In order to withdraw funds,
                      <br />
                      please enable 2 Factor Authentication
                    </ReactTooltip>
                  </td>
                  {accountData.google2Fa &&
                  accountData.google2Fa.secret == "" &&
                  accountData.google2Fa.uri == "" ? (
                    <>
                      <td className="text-right">
                        <i class="fas fa-times-circle disabledRed"></i> Disabled
                        [ <a href="/settings">Enable Now</a> ]
                      </td>
                      <td>You can enable 2FA for enhanced account security</td>
                    </>
                  ) : (
                    <>
                      <td className="text-right">
                        <i class="fas fa-check-circle enableGreen"></i> Enabled
                        [ <a href="/settings">Disable Now</a> ]
                      </td>
                      <td>You can disable 2FA for enhanced account security</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>Login Password</td>
                  <td className="text-right">
                    [ <a href="/settings"> Update</a> ]
                  </td>
                  <td>Change your login password</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Notification />
      </div>

      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="edit_post"
        tabindex="-1"
        role="dialog"
        aria-labelledby="edit_post"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="confirm_body ">
                <h2>Confirm My Post</h2>
                <img
                  src={require("../../assets/images/postconfirm.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="model_detail_text">
                  <div className="detail_text_model">
                    <span className="righttext">Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{BuyorSell}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Cryptocurrency:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{currencyselected}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Price:</span>
                  </div>
                  <div className="detail_text_model">
                    <input
                      type="number"
                      class="form-control"
                      name="price"
                      value={price}
                      onChange={handleChange}
                    />
                    {validateError.price && (
                      <p className="error-message">{validateError.price}</p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Price Limit From: </span>
                  </div>
                  <div className="detail_text_model">
                    <input
                      type="number"
                      class="form-control"
                      name="postminlimit"
                      value={postminlimit}
                      onChange={handleChange}
                    />
                    {validateError.postminlimit && (
                      <p className="error-message">
                        {validateError.postminlimit}
                      </p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Price Limit To: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      <input
                        type="number"
                        class="form-control"
                        name="postmaxlimit"
                        value={postmaxlimit}
                        onChange={handleChange}
                      />
                    </span>
                    {validateError.postmaxlimit && (
                      <p className="error-message">
                        {validateError.postmaxlimit}
                      </p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Preferred Payments: </span>
                  </div>
                  <div className="detail_text_model">
                    <Select
                      name="postprefcurrency"
                      value={postprefcurrency}
                      onChange={handlepostprefcurrency}
                    >
                      <MenuItem value="Bank">Bank</MenuItem>
                      <MenuItem value="UPI">UPI</MenuItem>
                      <MenuItem value="Gpay">Gpay</MenuItem>
                    </Select>
                    {validateError.postprefcurrency && (
                      <p className="error-message">
                        {validateError.postprefcurrency}
                      </p>
                    )}
                  </div>
                </div>
                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button onClick={updatePost} className="button1">
                    Submit Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default Dashboard;