import React, { useEffect, useState } from "react";
// @material-ui/core components
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import LastLogin from "../components/Dashboard/LastLogin";
// import SupportTickets  from "../components/supportTicket/supportTicket";
import Checkbox from "rc-checkbox";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import GridContainer from "components/Grid/GridContainer.js";
import {
  createNewTicket,
  getSupportCategory,
  getTicketList,
  getTicketData,
  replyMessage,
  closeTicket,
} from "actions/supportTicket";
import GridItem from "components/Grid/GridItem.js";
// import Switch from "@material-ui/core/Switch";
// import { Scrollbars } from "react-custom-scrollbars";
// import DataTable from "react-data-table-component";
// import MenuItem from "@material-ui/core/MenuItem";
import { Select ,MenuItem} from "@material-ui/core";
// import Slider from "@material-ui/core/Slider";
import { isEmpty } from "../lib/isEmpty";
import { toastAlert } from "../lib/toastAlert";
import { validation, replyValidation } from "../validation/support";
import { dateTimeFormat } from "../lib/dateTimeHelper";

//import config
import config from '../lib/config'

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  categoryId: '',
  categorySubject: "",
  message: "",
  'document': '',
};

export default function SupportTicket(props) {
  const { ...rest } = props;
  const { t, i18n } = useTranslation();
  const [categoryList, setCategoryList] = useState([]);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState();
  const [validateError, setValidateError] = useState({});
  const [ticketData, setTicketData] = useState([]);
  const [reply, setreply] = useState([]);
  const authDetails = useSelector((state) => state.auth);
  console.log("authDetails----------", authDetails);
  //const { categoryId, message } = formValue;
  const { categorySubject, message, document, categoryId } = formValue;
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError({})
    // if (!isEmpty(validateError)) {
    //     setValidateError({})
    // }
  };

  useEffect(() => {
    // setValidateError(replyValidation(formValue))
    if (authDetails.userId) {
      getTicketDetails();
      fetchCategory();
      console.log("User Id:----", authDetails.userId);
    }
  }, [authDetails]);

  const getTicketDetails = async () => {
    const { result } = await getTicketList();
    setTicketData(result);
  };

  const fetchCategory = async () => {
    try {
      const { status, loading, error, result } = await getSupportCategory();
      if (status == "success") {
        setCategoryList(result);
      }
    } catch (err) { }
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    let formData = { ...formValue, ...{ [name]: files[0] } }
    setFormValue(formData)

  }

  const handleFormSubmit = async (e) => {

    e.preventDefault();

    let reqData = {
      categoryId,
      categorySubject,
      message,
      messageBy: "User",
      document
    };
    const validationError = validation(reqData);
    
    if (Object.keys(validationError).length != 0) {
      setValidateError(validationError);
      return false;
    }

    setLoader(true);
    try {

      const formData = new FormData();

      formData.append("categoryId", categoryId)
      formData.append("message", message)
      formData.append("messageBy", reqData.messageBy)
      formData.append("document", document)



      const { status, loading, error, result } = await createNewTicket(formData);

      setLoader(loading);
      if (status == "success") {
        setFormValue(initialFormValue);
        setValidateError("");
        toastAlert("success", result.messages, "createTicket");
        getTicketDetails();
        fetchCategory();
      } else {
        setValidateError(error);
        toastAlert("error", error.messages, "createTicket");
      }
    } catch (err) { }
  };



  const userReplySubmit = async (ticketId, adminId, categoryName) => {
    let reqData = {
      message,
      receiverId: adminId,
      ticketId: ticketId,
      messageBy: "User",
      document
    };
    const validationError = replyValidation(reqData);

    if (Object.keys(validationError).length != 0) {
      setValidateError(validationError);
      return false;
    }
    try {
      const formData = new FormData();

      formData.append("receiverId", adminId)
      formData.append("message", message)
      formData.append("messageBy", reqData.messageBy)
      formData.append("ticketId", ticketId)
      formData.append("document", document)

      const { status, loading, error, result } = await replyMessage(formData);

      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", "message send successfully", "userReply");
        // selectAndFillterCategory(categoryName)
        getTicketDetails();
      }
    } catch (err) { }
  };

  const TicketClosing = async (ticketId) => {
    let reqData = {
      ticketId: ticketId,
    };
    try {
      const { status, loading, error, messages, result } = await closeTicket(
        reqData
      );
      if (status == "success") {
        toastAlert("success", messages, "supportTicket");
        getTicketDetails();
      }
    } catch (err) { }
  };

  const selectAndFillterCategory = async (categorySubject) => {
    var arr = [];
    const { result } = await getTicketData();
    console.log("Resssssss----", result);
    result.map((item, key) => {
      //  console.log("Item---",item)
      if (item.categorySubject == categorySubject) {
        arr.push(item);
      }
    });

    setTicketData(arr);
  };
  console.log(ticketData,'--------------categoryListcategoryList')
  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header
            className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={
              <img
                src={require("../assets/images/logo.png")}
                alt="logo"
                className="img-fluid"
              />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest}
          />
          <div className="dashboardContent userPages">
            {/* <SupportTickets /> */}
            <div className="container">
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">Create Support Ticket</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={7} lg={7}>
                  <LastLogin />
                </GridItem>
              </GridContainer>
              <div className="dashboard_box boxSpace">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7} lg={8}>
                    <div className="contact_form settingsSelect mb-0">
                      {/* <form   > */}
                      <div className="form-group">
                        <label>Ticket For</label>
                        {/* <input
                          type="text"
                          className="form-control"
                          name="categorySubject"
                          value={categorySubject}
                          onChange={handleChange}
                        />
                        {validateError.categorySubject && (
                          <p className="error-message">
                            {validateError.categorySubject}
                          </p>
                        )} */}
                         <Select 
                            name={'categoryId'}
                            onChange={handleChange}
                            value={categoryId}>
                            {
                              categoryList && categoryList.length > 0 && categoryList.map((item, key) => {
                                console.log(item,'----------item')
                                return (
                                  <MenuItem key={key} value={item._id}>{item.categoryName}</MenuItem>
                                )
                              })
                            }

                          </Select>
                          {
                                validateError.categoryId && <p className="error-message">{validateError.categoryId}</p>
                            } 
                      </div>
                      <div className="form-group">
                        <label>Message to Support Team</label>
                        <textarea
                          className="form-control"
                          rows="4"
                          name={"message"}
                          onChange={handleChange}
                          value={message}
                        ></textarea>
                        {validateError.message && (
                          <p className="error-message">
                            {validateError.message}
                          </p>
                        )}
                      </div>
                      {/* <GridItem xs={12} sm={12} md={7} lg={8}> */}
                        <div className="form-group">
                          {/* <label>{t("FRONT_SIDE")}</label> */}
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              aria-describedby="inputGroupFileAddon01"
                              name="document"
                              onChange={handleFile}
                            />
                            <label className="custom-file-label">
                              {t("MAX_1MB_IMG")}
                            </label>
                          </div>
                          {
                            document != "" &&
                            <span style={{ "color": "green" }} >
                              {
                                document.name
                              }
                            </span>
                          }
                          {
                            validateError.document && <p className="error-message">{t(validateError.document)}</p>
                          }
                        </div>
                      {/* </GridItem> */}
                      <div className="form-group">
                        <button
                          type="button"
                          class="btn btn-primary text-uppercase py-2"
                          onClick={handleFormSubmit}
                        >
                          Create Ticket
                        </button>
                      </div>
                      {/* </form> */}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5} lg={4}>
                    <div className="supportTicketList">
                      <h5 class="dash_subtitle">More About Support</h5>
                      {
                        categoryList && categoryList.length > 0 && categoryList.map((item, key) => {
                             return (
                                <ul>
                                    <li><a href="#">{key + 1}. {item.categoryName}</a></li>                                    
                                </ul>
                             )
                        })
                    }
                      {/* {
                        categoryList && categoryList.length>0? categoryList.map((data, i) => {
                          repeatedCategoryName= "";
                          
                          return (
                            <ul>
                              <li onClick={() => selectAndFillterCategory(data.categorySubject)} ><a href="#">{i + 1},{data.categorySubject}</a></li>

                            </ul>

                          )
                          
                          var repeatedCategoryName= data.categorySubject;
                        }):"No Subjects found"
                      } */}

                      {/* <p>
                        Tell us what went wrong, or what you need help with, and
                        we'll do our best to resolve the issue.Curious about the
                        next steps, or the best way to fill out a support
                        ticket?
                        <a href="/faq"> Find out here.</a>
                      </p> */}
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              {ticketData && ticketData.length > 0 ? (
                <div class="row align-items-center">
                  <div class="col-lg-12">
                    <h3 class="dash_title mb-3">Support Details</h3>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="supporTicketAccordian wow fadeIn">
                <div id="accordion">
                  {ticketData &&
                    ticketData.map((data, i) => {
                      return (
                        <div className="card">
                          <div
                            className="card-header wow flipInX"
                            id="headingOne"
                          >
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link"
                                data-toggle="collapse"
                                data-target={"#collapseOne" + i}
                                aria-expanded="true"
                                aria-controls={"#collapseOne" + i}
                              >
                                <span className="stHeadText subjectWidth">
                                  <small>Subject</small>
                                  {data.categoryInfo && data.categoryInfo.categoryName}
                                </span>
                                <span className="stHeadText ticketIdWidth">
                                  <small>Ticket ID</small>
                                  {data._id}
                                </span>
                                <span className="stHeadText statusWidth">
                                  <small>Status</small>
                                  <span className="yellowText">
                                    {data.status}
                                  </span>
                                </span>
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </h5>
                          </div>

                          <div
                            id={"collapseOne" + i}
                            className="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <p className="metaChatDetails">
                                {dateTimeFormat(
                                  data.createdAt,
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </p>

                              <div className="row">
                                <div className="col-md-12">
                                  {/* this map for get chaing   between user and admin*/}
                                  {data.reply &&
                                    data.reply.map((reply, i) => {
                                      let ext = reply.document.split('.').pop();

                                      return (
                                        <ul className="ticketComments">
                                          {reply.messageBy == "User" && (
                                            <>
                                              <li>
                                                <div className="ticketUserDetails">
                                                  <div className="userImg">
                                                    <img
                                                      src={require("../assets/images/supportUserImg.jpg")}
                                                      alt=""
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <p>User</p>
                                                </div>
                                                <div className="ticketDetails">
                                                  <p>Hello Support Team,</p>
                                                  <p>{reply.message}</p>
                                                  {
                                                    reply.document != "" ?
                                                      <a href={config.API_URL + `/images/support/` + reply.document} target="_blank"> View file</a>
                                                      :
                                                      ""

                                                  }
                                                  {/* {reply &&    (reply.document!='')&& ext=="pdf"?
                                                          <a href={config.API_URL+`/images/support/`+reply.document} target="_blank"> View Pdf file</a>
                                                             :
                                                            <a href={config.API_URL+`/images/support/`+reply.document} target="_blank"> <img width="50" height={"50"} src={config.API_URL+'/images/support/'+reply.document} ></img></a>
                                                      } */}
                                                </div>

                                              </li>
                                            </>
                                          )}
                                          {/* this condition  for admin or user get caht message  */}

                                          {reply.messageBy == "Admin" && (
                                            <>
                                              <li>
                                                <div className="ticketUserDetails">
                                                  <div className="userImg">
                                                    <img
                                                      src={require("../assets/images/supportAdminLogo.jpg")}
                                                      alt=""
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <p>Support Team</p>
                                                </div>
                                                <div className="ticketDetails">
                                                  <p className="metaChatDetails">
                                                    Support Team reply on{" "}
                                                    {dateTimeFormat(
                                                      reply.createdAt,
                                                      "YYYY-MM-DD HH:mm"
                                                    )}
                                                  </p>
                                                  <p>{reply.message}</p>
                                                  <p>
                                                    Thanks,
                                                    <br />
                                                    Support Team
                                                  </p>
                                                </div>
                                              </li>
                                            </>
                                          )}
                                        </ul>
                                      );
                                    })}
                                  <div className="messageTypeBox contact_form">
                                    <div className="row">
                                      {data.status != "closed" && (
                                        <>
                                          <div className="col-md-12">
                                            <div className="form-group">
                                              <label className="d-block">
                                                Reply to Support Team
                                              </label>
                                              <textarea
                                                rows="2"
                                                className="form-control"
                                                onChange={handleChange}
                                                name={"message"}
                                                value={message}
                                              ></textarea>
                                              {validateError.replymessage && (
                                                <p className="error-message">
                                                  {validateError.replymessage}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <div className="form-group">
                                              {/* <label>{t("FRONT_SIDE")}</label> */}
                                              <div className="custom-file">
                                                <input
                                                  type="file"
                                                  className="custom-file-input"
                                                  aria-describedby="inputGroupFileAddon01"
                                                  name="document"
                                                  onChange={handleFile}
                                                />
                                                <label className="custom-file-label">
                                                  {t("MAX_1MB_IMG")}
                                                </label>
                                              </div>
                                              {
                                                document != "" &&
                                                <span style={{ "color": "green" }} >
                                                  {
                                                    document.name
                                                  }
                                                </span>
                                              }
                                              {
                                                validateError.document && <p className="error-message">{t(validateError.document)}</p>
                                              }
                                            </div>
                                          </GridItem>
                                          <div className="col-md-12">
                                            <p className="submitChat">
                                              <button
                                                type="button"
                                                class="btn btn-outline text-uppercase py-2"
                                                onClick={() =>
                                                  userReplySubmit(
                                                    data._id,
                                                    data.adminId,
                                                    data.categoryName
                                                  )
                                                }
                                              >
                                                Reply
                                              </button>
                                              <a
                                                href="#"
                                                className="ml-0 ml-sm-3"
                                                onClick={() =>
                                                  TicketClosing(data._id)
                                                }
                                              >
                                                Satisfied, Close this ticket
                                              </a>
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}