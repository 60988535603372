import React, { useEffect, useState } from "react";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// action
import { getFaq } from "actions/commonAction";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function SupportTicket(props) {
  const { ...rest } = props;

  const [loader, setLoader] = useState(true);
  const [faqList, setFaqList] = useState([]);

  const getList = async () => {
    try {
      let { status, result } = await getFaq();
      setLoader(false);
      if (status) {
        setFaqList(result);
      }
    } catch (err) { }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header
            className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={
              <img
                src={require("../assets/images/logo.png")}
                alt="logo"
                className="img-fluid"
              />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest}
          />
          <div className="dashboardContent userPages">
            <div className="container">
              <GridContainer>
                <GridItem md={12} className="text-center">
                  <h2 className="main_title">FAQ</h2>
                </GridItem>
              </GridContainer>

              <div className="supporTicketAccordian faq_accodion wow fadeIn">
                <GridContainer>
                  {loader && (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <div className="text-center">
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    </GridItem>
                  )}
                  {!loader &&
                    faqList.length > 0 &&
                    faqList.map((list, i) => {
                      return (
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <div id="accordion" className="faq_section_new">
                            <h2>{list.categoryName}</h2>
                            {list.faq.map((faq, j) => {
                              return (
                                <div className="card faq_cars">
                                  <div
                                    className="card-header wow flipInX"
                                    id={`heading${i}${j}`}
                                  >
                                    <h5 className="mb-0">
                                      <button
                                        className={`btn btn-link ${i === 0 && j === 0 ? "" : "collapsed"
                                          }`}
                                        data-toggle="collapse"
                                        data-target={`#collapse${i}${j}`}
                                        aria-expanded={
                                          i === 0 && j === 0 ? "true" : "false"
                                        }
                                        aria-controls={`collapse${i}${j}`}
                                      >
                                        <span className="stHeadText subjectWidth">
                                          {`${j + 1}. ${faq.question}`}{" "}
                                        </span>

                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </h5>
                                  </div>

                                  <div
                                    id={`collapse${i}${j}`}
                                    className={`collapse ${i === 0 && j === 0 ? "show" : ""
                                      }`}
                                    aria-labelledby={`heading${i}${j}`}
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      <p></p>
                                      {faq.answer}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </GridItem>
                      );
                    })}
                  {!loader && faqList.length === 0 && (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <div className="text-center">
                        <h4>No FAQ Found</h4>
                      </div>
                    </GridItem>
                  )}
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
