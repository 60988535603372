// import package
import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
    Slider,
    Checkbox,
    MenuItem,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel
} from '@material-ui/core';

// import component
import LimitOrder from './LimitOrder';

const marks = [
    {
        value: 0,
        label: '1%',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

function valuetext(value) {
    return `${value}%`;
}

const OrderPlace = () => {

    // state
    const [orderType, setOrderType] = useState('limit')
    const [buyorsell, setBuyorsell] = useState('buy') // buy or sell

    const [value, setValue] = React.useState('ioc');
    const handleCheckChange = (event) => {
        setValue(event.target.value);
    };


    return (
        <div className="tradeTableLeftSide darkBox orderBook">
            <div className="tableHead">
                <h4>Place Order</h4>
            </div>

            <div className="derivativeCalC">
                <div className="calCBtnGroup">
                    <button className="btn greyBtn py-2 px-4">Cross</button>
                    <button className="btn greyBtn py-2 px-4">20X</button>
                </div>
                <div className="calcIcon"><a href="#"><i class="fas fa-calculator"></i></a></div>
            </div>
            <div className="ButtonFullWidth">
                <button className="btn placeOrderButton">Open</button>
                <button className="btn placeOrderButton">Close</button>
            </div>
            <div className="tradeFulltabbedTable">
                <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab1" role="tablist">
                        <a className="nav-item nav-link active py-2" id="nav-limit-tab" data-toggle="tab" href="#nav-limit" role="tab" aria-controls="nav-limit" aria-selected="true">Limit</a>
                        <a className="nav-item nav-link py-2" id="nav-market-tab" data-toggle="tab" href="#nav-market" role="tab" aria-controls="nav-market" aria-selected="false">Market</a>
                        <a className="nav-item nav-link py-2" id="nav-stopLimit-tab" data-toggle="tab" href="#nav-stopLimit" role="tab" aria-controls="nav-stopLimit" aria-selected="false">Stop-Limit</a>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent1">
                    <div className="tab-pane fade show active" id="nav-limit" role="tabpanel" aria-labelledby="nav-limit-tab">
                        <Scrollbars style={{ width: "100%", height: 620 }}>
                            {
                                orderType == 'limit' && <LimitOrder
                                    buyorsell={buyorsell}
                                />
                            }

                        </Scrollbars>
                    </div>
                    <div className="tab-pane fade" id="nav-market" role="tabpanel" aria-labelledby="nav-market-tab">
                        <div className="placeOrderBox contact_form">
                            <h3><small>Balance</small> 1,500.2575 USDT</h3>
                            <div className="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="btnType1">Price</span>
                                    </div>
                                    <input type="text" class="form-control text-right borderZero" value="55114.52" />
                                    <div class="input-group-append">
                                        <span class="btnType1">USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="btnType1">Amount</span>
                                    </div>
                                    <input type="text" class="form-control text-right borderZero" value="0.00001234" />
                                    <div class="input-group-append">
                                        <span class="btnType1">BTC</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group px-2 mb-0">
                                <Slider
                                    defaultValue={50}
                                    getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider-custom"
                                    step={10}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                />
                            </div>
                            <div className="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="btnType1">Total</span>
                                    </div>
                                    <input type="text" class="form-control text-right borderZero" value="115.50 " />
                                    <div class="input-group-append">
                                        <span class="btnType1">USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn BuyNavButton btn-block">Buy BTC</button>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-stopLimit" role="tabpanel" aria-labelledby="nav-stopLimit-tab">
                        <div className="placeOrderBox contact_form">
                            <h3><small>Balance</small> 1,500.2575 USDT</h3>
                            <div className="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="btnType1">Price</span>
                                    </div>
                                    <input type="text" class="form-control text-right borderZero" value="55114.52" />
                                    <div class="input-group-append">
                                        <span class="btnType1">USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="btnType1">Amount</span>
                                    </div>
                                    <input type="text" class="form-control text-right borderZero" value="0.00001234" />
                                    <div class="input-group-append">
                                        <span class="btnType1">BTC</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group px-2">
                                <Slider
                                    defaultValue={50}
                                    getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider-custom"
                                    step={10}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                />
                            </div>
                            <div className="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="btnType1">Total</span>
                                    </div>
                                    <input type="text" class="form-control text-right borderZero" value="115.50 " />
                                    <div class="input-group-append">
                                        <span class="btnType1">USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn BuyNavButton btn-block">Buy BTC</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderPlace;