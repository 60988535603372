// import package
import React, { useState, useEffect } from "react";
import { Slider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";

// import action
import { orderPlace } from "../../actions/spotTradeAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { encryptObject } from "../../lib/cryptoJS";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed } from "../../lib/roundOf";
import validation from "./validation";

const marks = [
  {
    value: 0,
    label: "1%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

function valuetext(value) {
  return `${value}%`;
}

const initialFormValue = {
  price: "",
  quantity: "",
  total: "",
};

const MarketOrder = (props) => {
  const { buyorsell } = props;
  const history = useHistory();
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState();
  const [buysell, setBuySell] = useState();

  const { price, quantity, total } = formValue;

  // redux-state
  const tradePair = useSelector((state) => state.tradePair);
  const accountData = useSelector((state) => state.account);
  const instantSpots = useSelector((state) => state.instantspot);
  const { firstCurrency, secondCurrency } = useSelector(
    (state) => state.tradeAsset
  );
  const marketPriceData = useSelector((state) => state.marketPrice);
  const { isAuth } = useSelector((state) => state.auth);
  const instantspot = useSelector((state) => state.instantspot);

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (tradePair.botstatus == "wazirx") {
      if (name == "price") {
        if (IsValid(value, tradePair.secondFloatDigit)) {
          return;
        }
      }
      if (name == "quantity") {
        if (IsValid(value, tradePair.firstFloatDigit)) {
          return;
        }
      }
    }

    let formData = { ...formValue, ...{ [name]: value } };

    if (!isEmpty(formData.price) && !isEmpty(formData.quantity)) {
      let totalPrice = formData.price * formData.quantity;
      formData = { ...formData, ...{ ["total"]: totalPrice.toFixed(8) } };
    }
    setFormValue(formData);
  };

  function IsValid(value, degit) {
    var split = value.split(".");
    console.log("aaaaaaaaaaaaaaaaaaaaa", degit);
    if (split && split[1] && split[1] != "")
      if (split[1].length > degit) {
        return true;
      }
    // else if (!(split[0] == '' || split[0] == '0')) {
    //     return false;
    // }

    return false;
  }

  const handlePercentage = (e, percentage) => {
    let priceValue = 0,
      formData = {};
    if (!isEmpty(price) && !isNaN(price)) {
      priceValue = price;
    } else if (
      !isEmpty(marketPriceData.markPrice) &&
      !isNaN(marketPriceData.markPrice)
    ) {
      priceValue = marketPriceData.markPrice;
      formData["price"] = marketPriceData.markPrice;
    }

    if (buyorsell == "buy") {
      let userBalance =
        secondCurrency && secondCurrency.spotwallet > 0
          ? secondCurrency.spotwallet
          : 0;
      formData["total"] = toFixed(
        userBalance * (percentage / 100),
        tradePair.secondFloatDigit
      );
      formData["quantity"] = toFixed(
        formData["total"] / priceValue,
        tradePair.firstFloatDigit
      );
    } else if (buyorsell == "sell") {
      let userBalance =
        firstCurrency && firstCurrency.spotwallet > 0
          ? firstCurrency.spotwallet
          : 0;
      formData["quantity"] = toFixed(
        userBalance * (percentage / 100),
        tradePair.firstFloatDigit
      );
      formData["total"] = toFixed(
        formData["quantity"] * priceValue,
        tradePair.secondFloatDigit
      );
    }
    setFormValue({ formValue, ...formData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (tradePair.secondCurrencySymbol == "INR") {
        if (
          accountData.IDstatus == "Verified" &&
          accountData.Addresstatus == "Verified"
        ) {
          let reqData = {
            quantity: quantity,
            buyorsell: buyorsell,
            orderType: "market",
            spotPairId: tradePair.pairId,
            newdate: new Date(),
          };

          const validateError = await validation(reqData);
          if (!isEmpty(validateError)) {
            toastAlert(
              "error",
              validateError[Object.keys(validateError)[0]],
              "marketOrder"
            );
            return;
          }
          setLoader(true);

          let encryptToken = {
            token: encryptObject(reqData),
          };

          let { status, loading, message, error, result } = await orderPlace(
            encryptToken
          );
          setLoader(loading);
          if (status == "success") {
            setFormValue(initialFormValue);
            toastAlert("success", message, "marketOrder");
          } else {
            toastAlert("error", message, "marketOrder");
          }
        } else {
          toastAlert("success", "KYC Process is Pending", "spotOrder");
          history.push("/kyc");
        }
      } else {
        let reqData = {
          quantity: quantity,
          buyorsell: buyorsell,
          orderType: "market",
          spotPairId: tradePair.pairId,
          newdate: new Date(),
        };

        const validateError = await validation(reqData);
        if (!isEmpty(validateError)) {
          toastAlert(
            "error",
            validateError[Object.keys(validateError)[0]],
            "marketOrder"
          );
          return;
        }
        setLoader(true);

        let encryptToken = {
          token: encryptObject(reqData),
        };

        let { status, loading, message, error, result } = await orderPlace(
          encryptToken
        );
        setLoader(loading);
        if (status == "success") {
          setFormValue(initialFormValue);
          toastAlert("success", message, "marketOrder");
        } else {
          toastAlert("error", message, "marketOrder");
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    setFormValue({
      ...formValue,
      ...{
        price: marketPriceData.markPrice,
      },
    });
  }, [marketPriceData]);
  return (
    <div className="placeOrderBox contact_form">
      {buyorsell == "buy" && (
        <h3>
          <small>
            Balance:{" "}
            <span>
              {secondCurrency &&
                secondCurrency.spotwallet &&
                secondCurrency.spotwallet.toFixed(tradePair.secondFloatDigit)}{" "}
              {tradePair && tradePair.secondCurrencySymbol}
            </span>
          </small>{" "}
        </h3>
      )}
      {buyorsell == "sell" && (
        <h3>
          <small>
            Balance:{" "}
            <span>
              {firstCurrency &&
                firstCurrency.spotwallet &&
                firstCurrency.spotwallet.toFixed(tradePair.firstFloatDigit)}{" "}
              {tradePair && tradePair.firstCurrencySymbol}
            </span>
          </small>{" "}
        </h3>
      )}
      <div className="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="btnType1">Amount</span>
          </div>
          <input
            type="text"
            class="form-control text-right borderZero"
            name="quantity"
            value={quantity}
            onChange={handleChange}
          />
          <div class="input-group-append">
            <span class="btnType1">
              {tradePair && tradePair.firstCurrencySymbol}
            </span>
          </div>
        </div>
      </div>
      {/* <div className="form-group px-3">
                <Slider
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-custom"
                    step={10}
                    valueLabelDisplay="auto"
                    marks={marks}
                    defaultValue={0}
                    onChange={handlePercentage}
                    disabled={!isAuth}
                />
            </div> */}
      <div className="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="btnType1">Total</span>
          </div>
          <input
            type="text"
            class="form-control text-right borderZero"
            value={total}
          />
          <div class="input-group-append">
            <span class="btnType1">
              {tradePair && tradePair.secondCurrencySymbol}
            </span>
          </div>
        </div>
      </div>
      {isAuth && buyorsell == "buy" && (
        <div className="form-group">
          <button className="btn BuyNavButton btn-block" onClick={handleSubmit}>
            Buy {tradePair && tradePair.firstCurrencySymbol}
          </button>
        </div>
      )}

      {isAuth && buyorsell == "sell" && (
        <div className="form-group">
          <button
            className="btn SellNavButton btn-block"
            onClick={handleSubmit}
          >
            Sell {tradePair && tradePair.firstCurrencySymbol}
          </button>
        </div>
      )}
    </div>
  );
};

export default MarketOrder;
