/*eslint-disable*/
import React from "react";
import { Link,useHistory } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// import action
import { logout } from '../../actions/users';

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const HeaderLinks = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch=useDispatch();



  // redux-state
  const { isAuth } = useSelector(state => state.auth);
  // console.log("isAuthisAuthisAuth",isAuth);

  return (
    <div className="home_page_menu">
      <List className={classes.list + " menu_main_navbar"}>
      <ListItem className={classes.listItem}>
          <Link to="/spot" color="transparent" className="nav-link">Spot</Link>
        </ListItem>
        {/* <ListItem className={classes.listItem}>
          <Link to="/register" color="transparent" className="nav-link">Exchange</Link>
        </ListItem> */}
        <ListItem className={classes.listItem}>
          <Link to="/p2p" color="transparent" className="nav-link">P2P</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/faq" color="transparent" className="nav-link">FAQ</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/blog" color="transparent" className="nav-link">Blog</Link>
        </ListItem>
        {
          isAuth==false &&
          <>
            <ListItem className={classes.listItem}>
              <Link to="/login" color="transparent" className="nav-link home_menu_btn">Login</Link>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Link to="/register" color="transparent" className="nav-link home_menu_btn">Sign Up</Link>
            </ListItem>
          </>
        }
        {
          isAuth==true &&
          <ListItem className={classes.listItem}>
              <Link to="#" color="transparent" className="nav-link" onClick={() => logout(history,dispatch)}>Logout</Link>
            </ListItem>
        }
        
      </List>
    </div>
  );
}

export default HeaderLinks;