// import package
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import component
import FiatWithdraw from './FiatWithdraw';
import FiatDeposit from './FiatDeposit';
import CoinDeposit from './CoinDeposit';
import CoinWithdraw from './CoinWithdraw';
import config from '../../config';
// import action
import { getAssetData } from '../../actions/walletAction'
import { getUserDeposit } from '../../actions/walletAction'

import { toFixedDown } from '../../lib/roundOf'

const UserWalletList = () => {
    const { t, i18n } = useTranslation();

    // redux-state
    const walletData = useSelector(state => state.wallet);

    // state
    const [depositKey, setDepositKey] = useState(-1)
    const [withdrawKey, setWithdrawKey] = useState(-1)
    const [assetData, setAsstData] = useState({})
    const priceConversion = useSelector(state => state.priceConversion)

    // function
    // const fetchAsset = async () => {
    //     try {
    //         const { status, loading, result } = await getAssetData();
    //         if (status == 'success') {
    //             setWalletData(result)
    //         }
    //     } catch (err) { }
    // }

    const capitalize = (s) => {
        if (typeof s !== "string") return "";
        return s.toUpperCase()
    };


    const getDeposit = async () => {
        const { status } = await getUserDeposit();
    }
    useEffect(() => {
        getDeposit()
    }, [])

    return (
        <>
            {

                walletData && walletData.length > 0 && walletData.map((item, key) => {
                    console.log(walletData, "walletData")
                    console.log("Item-----", item)
                    let priceData = priceConversion.find((el) => (el.baseSymbol == item.currency.currencySymbol) && (el.convertSymbol != item.currency.currencySymbol))
                    console.log(priceData, 'priceData')
                    var finalPrice = priceData && priceData.convertPrice * item.spotwallet
                    console.log(finalPrice, 'finalPrice')

                    return (

                        <>
                            <div className="dashboard_box mb-2">
                                <div className="walletCard">
                                    <div className="walletCardLeft">
                                        <div className="currencyName">
                                            <img src={item.currency.currencyimage} alt="" className="img-fluid" />
                                            {item.currency.currencyName}
                                        </div>
                                        <div className="currencyPrice">


                                            {["fiat"].includes(item.currency.type) &&
                                                <>{toFixedDown(item.spotwallet, 6)}
                                                    <small>{item.currencySymbol}</small>
                                                </>}

                                            {
                                                ["crypto"].includes(item.currency.type) &&
                                                <>
                                                    {toFixedDown(item.spotwallet, 6)}
                                                    <small>{item.currencySymbol}</small>{" "}
                                                    {<>${finalPrice}</>}
                                                </>
                                            }
                                            {
                                                ["token"].includes(item.currency.type) &&
                                                <>
                                                    {toFixedDown(item.spotwallet, 6)}
                                                    <small>{item.currencySymbol}</small>{" "}
                                                    {capitalize(item.currency.tokenType)}{" "}
                                                    {<>${finalPrice}</>}

                                                </>
                                            }

                                        </div>
                                    </div>
                                    {item.currency.status == "active" &&
                                        <div className="walletCardRight">
                                            <div className="textLinkGroup">

                                                [
                                                <Link href="#"
                                                    onClick={() => {
                                                        setDepositKey(key == depositKey ? -1 : key)
                                                        setAsstData(item)
                                                    }}
                                                >
                                                    Deposit
                                                </Link>
                                                ]

                                                [
                                                <Link href="#"
                                                    onClick={() => {
                                                        setWithdrawKey(key == withdrawKey ? -1 : key)
                                                        setAsstData(item)
                                                    }}
                                                >
                                                    Withdraw
                                                </Link>
                                                ]

                                                {/* `/spot/${item.firstCurrencySymbol}_${item.secondCurrencySymbol}` */}
                                                {
                                                    ['crypto', 'token'].includes(item.currency.type) && <>
                                                        {' '}[ <Link to={'/spot/' + item.currency.currencySymbol + '_USD'} >Trade</Link> ]
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {item.currency.status == "deactive" &&
                                        <div className="walletCardRight">
                                            <div className="textLinkGroup" class="deactiveDeposit">

                                                [
                                                <>Deposit
                                                </>
                                                ]

                                                [
                                                <>
                                                    Withdraw
                                                </>
                                                ]

                                                {/* `/spot/${item.firstCurrencySymbol}_${item.secondCurrencySymbol}` */}
                                                {
                                                    ['crypto', 'token'].includes(item.currency.type) && <>
                                                        {' '}[ <Link to={'/spot/' + item.currency.currencySymbol + '_USD'} >Trade</Link> ]
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <Collapse in={key == depositKey}>
                                <div className="contact_form settingsSelect mb-0 card_for_dropdown">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinDeposit
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatDeposit
                                            assetData={item}
                                        />
                                    }

                                </div>
                            </Collapse>

                            <Collapse in={key == withdrawKey}>
                                <div className="contact_form settingsSelect mb-0 card_for_dropdown">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinWithdraw
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatWithdraw
                                            assetData={item}
                                        />
                                    }
                                </div>


                            </Collapse>
                        </>
                    )
                })
            }
            {/* {
                walletData && walletData.length > 0 && walletData.map((item, key) => {
                    return (
                        <>
                            <div className="dashboard_box mb-2">
                                <div className="walletCard">
                                    <div className="walletCardLeft">
                                        <div className="currencyName">
                                            <img src={item.currency.currencyimage} alt="" className="img-fluid" />
                                            {item.currency.currencyName}
                                        </div>
                                        <div className="currencyPrice">
                                            {item.spotwallet}{" "}
                                            <small>{item.currency.currencySymbol}</small>
                                        </div>
                                    </div>
                                    <div className="walletCardRight">
                                        <div className="textLinkGroup">
                                            [
                                            <Link href="#"
                                                onClick={() => {
                                                    setDepositKey(key == depositKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Deposit
                                            </Link>
                                        ]
                                        [
                                            <Link href="#"
                                                onClick={() => {
                                                    setWithdrawKey(key == withdrawKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Withdraw
                                            </Link>
                                        ]

                                        {
                                                ['crypto', 'token'].includes(item.currency.type) && <>
                                                    {' '}[ <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Trade</a> ]
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Collapse in={key == depositKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinDeposit
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatDeposit
                                            assetData={item}
                                        />
                                    }

                                </div>
                            </Collapse>

                            <Collapse in={key == withdrawKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinWithdraw
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatWithdraw
                                            assetData={item}
                                        />
                                    }
                                </div>


                            </Collapse>
                        </>
                    )
                })
            } */}
        </>
    )
}

export default UserWalletList;