// import package
import React, { useState, useEffect } from 'react';
import clsx from 'classnames';
import { useHistory } from "react-router-dom";
import ReactDatatable from '@ashvin27/react-datatable';


// import action
import { getRecentTransaction,cancelMyRequest } from '../../actions/dashboardAction';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';
import { transactionStatus } from '../../lib/displayStatus';
import { toastAlert } from "../../lib/toastAlert";

const RecentTransaction = () => {
    // state
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true);
    const [count, setcount] = useState(0);
    const [filter, setFilter] = useState({
        'page': 1,
        'limit': 5
    })
    const history = useHistory();

    // funtion

    const cancelRequest = async (e) => {
        console.log("data-----",e)
        let reqData={
            id:e
        }
      let { status, loading, message, error } = await cancelMyRequest(reqData);
      if (status == 'success') {
         
          toastAlert('success', message, 'login');
         history.push("/dashboard")
      } else {
          if (error) 
          toastAlert('error', message, 'login');
      }
  };
    const fetchTransaction = async () => {
        try {
            const { status, loading, result } = await getRecentTransaction();
            setLoader(loading)
            if (status == 'success') {
                setData(result)
            }
        } catch (err) { }
    }

    useEffect(() => {

        fetchTransaction(filter);
    }, [])

   function handlePagination(index) {

    let filterData = { ...filter, ...{ 'page': index.page_number, 'limit': index.page_size } }

       
        setFilter(filterData);
        fetchTransaction(filterData);
    }
    const columns = [
        {
            key: "createdAt",
            text: "Date",
            className: "Date",
            align: "left",
            sortable: true,
            width: 200,
            cell:(record)=>{
                return(
                    <p>
                        {dateTimeFormat(record.createdAt, 'YYYY-MM-DD HH:mm')}
                    </p>
                )
            }
           
        },
        {
            key: "paymentType",
            text: "Payment Type",
            className: "pairName",
            align: "left",
            sortable: true,
            width: 200,
            cell:(record)=>{
                return(
                    <p className={clsx({ "textDepositGreen": ['fiat_deposit','coin_transfer', 'coin_deposit'].includes(record.paymentType), "textWithdrawRed": ['fiat_withdraw', 'coin_withdraw'].includes(record.paymentType) })}>
                    {transactionStatus(record.paymentType)}
                </p>
              
                )
            }
        },
        {
            key: "currencySymbol",
            text: "Currency",
            className: "name",
            align: "left",
            sortable: true,
        },     
        {
            key: "amount",
            text: "Amount",
            className: "Side",
            align: "left",
            sortable: true,
            width: 200,
        },
      
        {
            key: "txid",
            text: "Tranaction Ref ..",
            className: "Price",
            align: "left",
            sortable: true,
            width: 200,
            cell:(record)=>{
                return(
                    <p>
                        {['fiat_deposit', 'fiat_withdraw',"coin_transfer"].includes(record.paymentType) && record._id}
                         {['coin_deposit', 'coin_withdraw'].includes(record.paymentType) && record.txid}
                    </p>
                )

            }
        }, 
        {
            key: "status",
            text: "Action",
            className: "Price",
            align: "left",
            sortable: true,
            width: 200,
        },

       
        
    ];

    const config = {
        page_size: 5,
        length_menu: [10, 20, 50],
        filename: "Order",
        no_data_text: 'No Records found!',
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: true,
    };
    return (
        <div className="table-responsive">
            {/* <table className="table mb-0">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Fiat/Crypto</th>
                        <th>Amount</th>
                        <th>Transaction Ref.</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loader && <div>Loading...</div>
                    }
                    {
                        !loader && data && data.length > 0 && data.map((item, key) => {
                            return (
                                <tr key={key}>
                                    <td>{dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm')}</td>
                                    <td className={clsx({ "textDepositGreen": ['fiat_deposit', 'coin_deposit'].includes(item.paymentType), "textWithdrawRed": ['fiat_withdraw', 'coin_withdraw'].includes(item.paymentType) })}>
                                        {transactionStatus(item.paymentType)}
                                    </td>
                                    <td>{item.currencySymbol}</td>
                                    <td>{item.amount}</td>
                                    <td>
                                        {['fiat_deposit', 'fiat_withdraw'].includes(item.paymentType) && item._id}
                                        {['coin_deposit', 'coin_withdraw'].includes(item.paymentType) && item.txid}
                                    </td>
                                    <td className="textStatusOrange">{item.status}</td>
                                    <td>
                                        {
                                            item.status == "cancelled" && <p>Cancelled</p>
                                        }

                                        {
                                            item.status == "rejected" && <p>Rejected</p>
                                        }
                                          {
                                            item.status == "completed" && <p>Completed</p>
                                        }
                                        {
                                            (item.status == "new" || item.status=="pending" )&& <>
                                             <button  className="btn btn-primary text-uppercase py-2" onClick={() => cancelRequest(item._id)}>Cancel</button>
                                         </>
                                        }
                                        </td>
                                </tr>
                            )
                        })
                    }
                    {
                        !loader && data && data.length <= 0 && <td colspan="6" align="center">
                            No Record
                            </td>
                    }
                </tbody>
            </table> */}

            <ReactDatatable
                                config={config}
                                records={data}
                                columns={columns}
                                dynamic={true}
                                total_record={count}
                                onChange={ handlePagination}
                            />
        </div>
    )
}

export default RecentTransaction;