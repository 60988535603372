import axios from '../config/axios'
import config from '../lib/config';
import { removeAuthToken, setAuthToken } from '../lib/localStorage'


export const createNewTicket = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/newTicket`,
            data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getSupportCategory = async () => {
    try {
      
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/supportCategory`,
         
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getTicketData = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/getTicket`,
        });

        console.log("supportttttData",respData)
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getTicketList = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/newTicket`,
            
        });

        console.log("supportttttData",respData)
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const replyMessage = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/ticketMessage`,
            data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const closeTicket = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/api/ticketMessage`,
            data
        });
        return {
            status: "success",
            loading: false,
            messages: respData.data.messages,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}