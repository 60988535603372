// import constant
import {
    SET_INSTANT_SPOT
} from '../constant';

const initialValue = {}

const instantSpot = (state = initialValue, action) => {
    switch (action.type) {

        case SET_INSTANT_SPOT:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}

export default instantSpot;