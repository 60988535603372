// import package
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import action
import { viewUserProfile } from '../../actions/users';

// import lib
import isEmpty from 'lib/isEmpty';

const ViewDetail = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    // props
    const { handleEditForm } = props;

    // redux-state
    const accountData = useSelector(state => state.account);
    const { firstName, lastName, email, blockNo, address, state, city, postalCode, country ,phonenumber,phoneCode} = accountData;

    // function
    const fetchData = async () => {
        try {
            const { status, loading } = await viewUserProfile(dispatch)
        } catch (err) { }
    }

    useEffect(() => {
        if (!isEmpty(accountData)) {
            fetchData()
        }
    }, [])

    return (
        <div className="profileDetailView">
            <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <h4 className="dash_subtitle flexTitle butt_posa_proifle">{t("PERSONAL_DETAILS")}</h4>
                    <ul>
                        <li>
                            <label>{t("FULL_NAME")}</label>
                            <span>{firstName}{' '}{lastName}</span>
                        </li>
                        <li>
                            <label>{t("EMAIL_PLACEHOLDER")}</label>
                            <span>{email}</span>
                        </li>
                        
                        <li>
                            
                            <label>Phone Number</label>
                            <span>+{phoneCode} {""} {phonenumber}</span>
                        </li>
                    </ul>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <h4 className="dash_subtitle flexTitle butt_posa_proifle">{t("RESIDENTIAL_ADDRESS")}</h4>
                    <ul>
                        <li>
                            <label>{t("ADDRESS")}</label>
                            <span>{address}</span>
                        </li>
                        <li>
                            <label>{t("STATE_PROVISION")}</label>
                            <span>{state}</span>
                        </li>
                        <li>
                            <label>{t("CITY")} & {t("POSTAL_CODE")}</label>
                            <span>{city}{' '}{postalCode}</span>
                        </li>
                        <li>
                            <label>{t("COUNTRY")}</label>
                            <span>{country}</span>
                        </li>
                    </ul>
                </GridItem>
            </GridContainer>
            <div className="form-group">
                <button
                    className="btn btn-primary text-uppercase py-2"
                    onClick={() => {
                        handleEditForm(accountData)
                    }}
                >
                    {t("EDIT_PROFILE")}
                </button>
            </div>
        </div>
    )
}

export default ViewDetail;