import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux';

// import action
// import { setThemeData } from '../../actions/trade';

// import lib
import isLogin from '../../lib/isLogin';
import { getTheme } from '../../lib/localStorage';

const ConditionRoute = ({ component: Component, auth, type, ...rest }) => {
    const dispatch = useDispatch();

    return (
        <Route
            {...rest}
            render={props => {
                // if (props.location.pathname == '/trade') {
                //     // setThemeData(getTheme(), dispatch)
                //     // if (getTheme() == 'light') {
                //     //     document.body.classList.add('themeLight');
                //     // } else {
                //     //     if (document.body.classList != "") {
                //     //         document.body.classList.remove('themeLight');
                //     //     }
                //     // }
                // } else {
                //     if (document.body.classList != "") {
                //         document.body.classList.remove('themeLight');
                //     }
                // }

                if (type == 'auth' && isLogin() == true) {
                    return <Redirect to="/spot" />
                } else if (type == 'private' && isLogin() != true) {
                    return <Redirect to="/login" />
                }

                return <Component {...props} />
            }}
        />
    )

};

export default ConditionRoute;