import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import {Select } from '@material-ui/core';
import { getChatDetails, getMyTransactions } from '../../actions/p2pAction';
import { getCurrency } from '../../actions/commonAction';
import {getMyP2PHistory} from '../../actions/transactionhistoryAction';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment';
import TextField from "@material-ui/core/TextField";

//var dateFormat      = require('dateformat');
// Default export is a4 paper, portrait, using millimeters for units


const dashboardRoutes = [];


function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return null;
}
export default function P2PTradeHistory(props) {

    const dispatch=useDispatch();
    const[userId,setuserId]=useState();
    const[transtype,setTranstype]=useState('all');
    const[currency,setCurrency]=useState('all');
    const[transData,setTransData]=useState([]);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });
    const doc = new jsPDF();

    
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const authDetails = useSelector(state => state.auth);
    const currencyData = useSelector(state => state.currency);
    const { ...rest } = props;
    const getcurrencyDetails = async () => {
        try {
            const { result, status, loading } = await getCurrency(dispatch)
          } catch (err) { }
          
      }
      useEffect(() => {
        getcurrencyDetails();
       }, [])


      useEffect(() => {
        if(authDetails.userId){
            setuserId(authDetails.userId);
            getP2PHistory();
       // getTransactionDetails(authDetails.userId)
        }
        if(currencyData && currencyData){
            console.log("currencyDatacurrencyDatacurrencyData",currencyData);
        }
       }, [authDetails,currencyData])
   
       
       const getSelected = async (e) => {
          setTranstype(e);
          let reqData = {
            curUser:authDetails.userId,
            transactiontype:e,
            currencytype:currency
          }
        const { result, status, loading } = await getMyP2PHistory(reqData);
        if(result){
            setTransData(result)
        }
       }
       const handleposttocurrency = async (e) => {
        let reqData = {
            curUser:authDetails.userId,
            transactiontype:transtype,
            currencytype:e
          }
          setCurrency(e);
        const { result, status, loading } = await getMyP2PHistory(reqData);
        if(result){
            setTransData(result)
        }
     }
     const configdata = {
      page_size: 10,
      length_menu: [ 10, 20, 50 ],
      filename: "Users",
      no_data_text: 'No user found!',
      language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
              first: "First",
              previous: "Previous",
              next: "Next",
              last: "Last"
          }
      },
      show_length_menu: false,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
};
     const savePDF = async () => {
     //  alert("Welcome");
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
  
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
  
  
      doc.setFontSize(15);
     
      const title = "P2P Transaction History";
      const headers = [["Date","Transaction Id", "Trade Pair","Trade Type","Fiat Price","Crypto Quantity"]];
  
      const data = transData.map(elt=> [moment(elt.created_date).format('DD-MM-YYYY hh:mm'),elt._id,elt.firstCurrency+"-"+elt.secondCurrency,elt.BuyorSell?"Buy" && "Buy":"Sell",elt.fiat_amount,elt.crypto_amount.toFixed(4)]);
      let content = {
        startY: 50,
        head: headers,
        body: data
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      let finalY = doc.lastAutoTable.finalY+20; // The y position on the page
    //   doc.text(50, finalY, "0- Open,1-")
      doc.save("p2p-trade history.pdf")
    //  alert("Document Saved")
    }
  
         
       const getP2PHistory = async () => {
        try {
            let reqData = {
                curUser:authDetails.userId,
              }
            const { result, status, loading } = await getMyP2PHistory(reqData);
            var fiatCurrency=[]
            if (result) {
              console.log("ResultP2P---",result)
                result.length > 0 && result.map((item, i) => {
              
                   // if(item.currencyId.type=="fiat"){
                        fiatCurrency.push(item);
                  //  }
                })
                setTransData(fiatCurrency);

              } 
          } catch (err) { 
              console.log("Transactions- err--------",err)
          }
          
      }

      // Crypto History Table

      const cryptoHistoryColumns = [
        {
          text: 'Date',
          key: 'orderDate',
          className:'Date',
          sortable: false,
          cell:record=>{
              return(
                <div data-tag="allowRowEvents">{moment(record.orderDate).format('DD-MM-YYYY hh:mm')}</div>
                 
              );
          }
        },
        {
          text: 'Transaction Id',
          key: '_id',
          className:'Transaction',
          sortable: false,
        },
        {
          text: 'Trade Pair',
          key: 'pair',
          className:'Trade Pair',
          sortable: false,
          cell:record=>{
            return(
              <div data-tag="allowRowEvents">{record.firstCurrency}-{record.secondCurrency}</div>
               
            );
              
          }
         
        },
        {
          text: 'Trade Type',
          className: 'Trade Type',
          key: 'BuyorSell',
          sortable: false,
          cell:record=>{
            if(record.BuyorSell=='Buy'){
              var type="Buy";
              return(
                <div className="textStatusGreen" data-tag="allowRowEvents">{type}</div>
                 
              );
          } else{
            var type="Sell";
            return(
              <div className="textWithdrawRed" data-tag="allowRowEvents">{type}</div>
               
            );
        }
               
           }
        },
        {
          text: 'Fiat Price',
          className: 'Price',
          key: 'fiat_amount',
          sortable: false,
        
        },
        {
          text: 'Crypto Quantity',
          className: 'Quantity',
          key: 'crypto_amount',
          sortable: false,
         
          cell:record=>{
           
            return(
                <div data-tag="allowRowEvents">{(record.crypto_amount).toFixed(4)}</div>
            );
        }
       
        },
        {
          text: 'Trade Fee',
          className: 'Price',
          key: 'trade_fee',
          sortable: false,
          cell:record=>{
           
            return(
                <div data-tag="allowRowEvents">{(record.trade_fee).toFixed(4)}</div>
            );
        }
        
        },
        {
          text: 'Status',
          className: 'Status',
          key: 'status',
          sortable: false,
          cell:record=>{
            var type="";
            if(record.status==0){
               type="Open";
               return(
                <div className="textStatusGreen" data-tag="allowRowEvents">{type}</div>
                 
              );
            } else if(record.status==1){
           type="Paid";
           return(
            <div className="textStatusGreen" data-tag="allowRowEvents">{type}</div>
             
          );
          } else if(record.status==2){
          type="Cancelled";
          return(
            <div className="textWithdrawRed" data-tag="allowRowEvents">{type}</div>
             
          );
        }else if(record.status==3){
          type="Disputed";
          return(
            <div className="textWithdrawRed" data-tag="allowRowEvents">{type}</div>
             
          );
      }
      else if(record.status==4){
       type="Completed";
       return(
        <div className="textStatusGreen" data-tag="allowRowEvents">{type}</div>
         
      );
    }

    else if(record.status==5){
     type="Closed";
     return(
      <div className="textWithdrawRed" data-tag="allowRowEvents">{type}</div>
       
    );
  }
              
         }
        
        },
      ];
    
  return (
    <>
        <div class="row align-items-center">
            <div class="col-lg-12">
                <h3 class="dash_title mb-3">P2P Trade History</h3>
            </div>
        </div>
        <div className="dashboard_box stakingHistoryTable boxSpace">
            <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
                <div className="newsSelectGroup">
                    <label>Filter by</label>

                    <Select name="transtype" value={transtype}
                        onChange={(e)=>
                          getSelected(e.target.value)}
                        >
                          <MenuItem value="all">All Trades</MenuItem>
                          <MenuItem value="Buy">Buy</MenuItem>
                        <MenuItem value="Sell">Sell</MenuItem>
                        {/* {
                            currencyData && currencyData.length > 0 && currencyData.map((item, key) => {
                              //  if (item.type == 'fiat') {
                                    return (
                                        <MenuItem value={item.currencySymbol}>{item.currencySymbol}</MenuItem>
                                    )
                                }
                            })
                        } */}
                    </Select>
                    {/* <TextField
                    select  value="Select Order Type" 
                     SelectProps={{
                      renderValue: (value) => value
                    }}
                    value={transtype}
                    onChange={(e)=>
                  
                      getSelected(e.target.value)}
                 
                        >
                          {/* <MenuItem value="all">All Trades</MenuItem> */}
                        {/* <MenuItem value="Buy">Buy</MenuItem>
                        <MenuItem value="Sell">Sell</MenuItem>
                        </TextField> */}
                    {/* <Select onChange={(e)=>
                        getSelected(e.target.value)}>
                        <MenuItem value="all">All Transactions</MenuItem>
                        <MenuItem value="Buy">Buy</MenuItem>
                        <MenuItem value="Sell">Sell</MenuItem>
                       
                    </Select> */}
                    &nbsp;&nbsp;
                    <div>
                    <Select name="currency" value={currency}
                        onChange={(e)=>
                        handleposttocurrency(e.target.value)}
                        >
                          <MenuItem value="all">All Currency Types</MenuItem>
                        {
                            currencyData && currencyData.length > 0 && currencyData.map((item, key) => {
                                if (item.type == 'fiat') {
                                    return (
                                        <MenuItem value={item.currencySymbol}>{item.currencySymbol}</MenuItem>
                                    )
                                }
                            })
                        }
                    </Select>
                    </div>
                    {/* <div className="tableSearchBox">
                        <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search by Date / Trans.Ref / Bank" />
                        <div class="input-group-append">
                            <span class="btnType1"><i class="fas fa-search"></i></span>
                        </div>
                        </div>
                    </div> */}
                </div>
                <div className="newsSelectGroup">
                    <button className="btn btn-outline text-uppercase py-1 m-0" onClick={savePDF}>Download PDF</button>
                </div>
            </div>
           <div className="table-responsive">
           <ReactDatatable
                className="historyCryptoTable w-100"
                records={transData}
                columns={cryptoHistoryColumns}
                config={configdata}
               // onPageChange={pageChange()}
              />
           </div>
           {/* <DataTable className="historyCryptoTable" columns={cryptoHistoryColumns}data={transData} noHeader /> */}
        </div>
    </>
  );
}
