// import package
import React, { useState, useEffect } from "react";
import { Slider } from "@material-ui/core";
import { useSelector } from "react-redux";

// import action
import { orderPlace } from "../../actions/spotTradeAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { encryptObject } from "../../lib/cryptoJS";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed } from "../../lib/roundOf";
import { Link, useParams, useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];
let initialPrice = "";

function valuetext(value) {
  return `${value}%`;
}

const initialFormValue = {
  price: "",
  quantity: "",
  total: "",
};

const LimitOrder = (props) => {
  // props
  const { buyorsell } = props;
  const history = useHistory();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState();
  const [validateError, setValidateError] = useState({});
  const [userBalance, setUserBalance] = useState(0);

  const { price, quantity, total } = formValue;

  // redux-state
  const tradePair = useSelector((state) => state.tradePair);
  const marketPriceData = useSelector((state) => state.marketPrice);
  const accountData = useSelector((state) => state.account);
  const { isAuth } = useSelector((state) => state.auth);
  const { firstCurrency, secondCurrency } = useSelector(
    (state) => state.tradeAsset
  );
  const walletData = useSelector((state) => state.wallet);
  const orderBookDetail = useSelector((state) => state.orderBookDetail);
  const tickerData = useSelector((state) => state.marketPrice);
  const instantSpot2 = useSelector((state) => state.instantSpot);

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }
    if (tradePair.botstatus == "bitfinex") {
      if(name == 'price' &&  !/^[0-9]*$/.test(value)){
        return
      }
    }
    if (tradePair.botstatus == "wazirx") {
      if (name == "price") {
        if (IsValid(value, tradePair.secondFloatDigit)) {
          return;
        }
      }
      if (name == "quantity") {
        if (IsValid(value, tradePair.firstFloatDigit)) {
          return;
        }
      }
    }

    let formData = { ...formValue, ...{ [name]: value } };

    if (!isEmpty(formData.price) && !isEmpty(formData.quantity)) {
      let totalPrice = formData.price * formData.quantity;
      formData = { ...formData, ...{ ["total"]: totalPrice.toFixed(8) } };
    }
    setFormValue(formData);
  };

  function IsValid(value, degit) {
    var split = value.split(".");
    if (split && split[1] && split[1] != "")
      if (split[1].length > degit) {
        return true;
      }
    // else if (!(split[0] == '' || split[0] == '0')) {
    //     return false;
    // }

    return false;
  }

  const handlePercentage = (e, percentage) => {
    let priceValue = 0,
      formData = {};
    if (!isEmpty(price) && !isNaN(price)) {
      priceValue = price;
    } else if (
      !isEmpty(marketPriceData.markPrice) &&
      !isNaN(marketPriceData.markPrice)
    ) {
      priceValue = marketPriceData.markPrice;
      formData["price"] = marketPriceData.markPrice;
    }

    if (buyorsell == "buy") {
      let userBalance =
        secondCurrency && secondCurrency.spotwallet > 0
          ? secondCurrency.spotwallet
          : 0;
      formData["total"] = toFixed(
        userBalance * (percentage / 100),
        tradePair.secondFloatDigit
      );
      formData["quantity"] = toFixed(
        formData["total"] / priceValue,
        tradePair.firstFloatDigit
      );
    } else if (buyorsell == "sell") {
      let userBalance =
        firstCurrency && firstCurrency.spotwallet > 0
          ? firstCurrency.spotwallet
          : 0;
      formData["quantity"] = toFixed(
        userBalance * (percentage / 100),
        tradePair.firstFloatDigit
      );
      formData["total"] = toFixed(
        formData["quantity"] * priceValue,
        tradePair.secondFloatDigit
      );
    }
    setFormValue({ ...formValue, ...formData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const validateError = await validation(formValue, orderType, buyorsell)
      // if (!isEmpty(validateError)) {
      //     toastAlert('error', validateError[Object.keys(validateError)[0]], 'spotOrder');
      //     return
      // }
      setLoader(true);
      if (tradePair.secondCurrencySymbol == "INR") {
        if (
          accountData.IDstatus == "Verified" &&
          accountData.Addresstatus == "Verified"
        ) {
          let reqData = {
            price: price,
            quantity: quantity,
            buyorsell: buyorsell,
            orderType: "limit",
            spotPairId: tradePair.pairId,
            markPrice: tickerData.markPrice,
            newdate: new Date(),
          };

          let encryptToken = {
            token: encryptObject(reqData),
          };

          let { status, loading, message, errors, result } = await orderPlace(
            encryptToken
          );
          console.log("errorerrorerror", errors);
          setLoader(loading);
          if (status == "success") {
            setFormValue(initialFormValue);
            setValidateError("");

            // if (result.userAsset._id == firstCurrency._id) {
            //     setUserFirstCurrency(result.userAsset, dispatch)
            // } else if (result.userAsset._id == secondCurrency._id) {
            //     setUserSecondCurrency(result.userAsset, dispatch)
            // }

            toastAlert("success", message, "spotOrder");
          } else {
            toastAlert("error", message, "spotOrder");
            setValidateError(errors);
          }
        } else {
          toastAlert("success", "KYC Process is Pending", "spotOrder");
          history.push("/kyc");
        }
      } else {
        let reqData = {
          price: price,
          quantity: quantity,
          buyorsell: buyorsell,
          orderType: "limit",
          spotPairId: tradePair.pairId,
          markPrice: tickerData.markPrice,
          newdate: new Date(),
        };

        let encryptToken = {
          token: encryptObject(reqData),
        };

        let { status, loading, message, errors, result } = await orderPlace(
          encryptToken
        );
        console.log("errorerrorerror", errors);
        setLoader(loading);
        if (status == "success") {
          setFormValue(initialFormValue);
          setValidateError("");

          // if (result.userAsset._id == firstCurrency._id) {
          //     setUserFirstCurrency(result.userAsset, dispatch)
          // } else if (result.userAsset._id == secondCurrency._id) {
          //     setUserSecondCurrency(result.userAsset, dispatch)
          // }

          toastAlert("success", message, "spotOrder");
        } else {
          toastAlert("error", message, "spotOrder");
          setValidateError(errors);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    let formData = formValue;

    if (isEmpty(initialPrice) && !isEmpty(marketPriceData)) {
      initialPrice = marketPriceData.markPrice;
      formData = { ...formData, ...{ price: marketPriceData.markPrice } };
      setFormValue(formData);
    }
  }, [marketPriceData]);

  useEffect(() => {
    if (orderBookDetail && !isEmpty(orderBookDetail.price)) {
      let totalPrice = total;
      if (!isEmpty(quantity)) {
        totalPrice = quantity * orderBookDetail.price;
      }
      setFormValue({
        ...formValue,
        ...{ price: orderBookDetail.price, total: totalPrice },
      });
    }
    if (walletData && walletData.length > 0) {
      //  setBalancedetails(walletData)
      var tempdata = walletData;
      var index = tempdata.findIndex((x) => x.currencySymbol === "USDT");
      if (index != -1) {
        setUserBalance(tempdata[index].balance);
      }
    }
    if (accountData) {
    }
  }, [orderBookDetail, walletData, accountData, tradePair]);
  // console.log("account---",accountData)

  return (
    <div className="placeOrderBox contact_form">
      {buyorsell == "buy" && (
        <h3>
          <small>
            Balance:{" "}
            {secondCurrency &&
              secondCurrency.spotwallet &&
              secondCurrency.spotwallet.toFixed(tradePair.secondFloatDigit)}{" "}
            {tradePair && tradePair.secondCurrencySymbol}
          </small>{" "}
        </h3>
      )}
      {buyorsell == "sell" && (
        <h3>
          <small>
            Balance:{" "}
            {firstCurrency &&
              firstCurrency.spotwallet &&
              firstCurrency.spotwallet.toFixed(tradePair.firstFloatDigit)}{" "}
            {tradePair && tradePair.firstCurrencySymbol}
          </small>{" "}
        </h3>
      )}
      <div className="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="btnType1">Price</span>
          </div>
          <input
            type="text"
            className="form-control text-right borderZero"
            name="price"
            value={price}
            onChange={handleChange}
          />
          <div class="input-group-append">
            <span class="btnType1">
              {tradePair && tradePair.secondCurrencySymbol}
            </span>
          </div>
        </div>
        {validateError && validateError.price && (
          <span style={{ color: "red" }}>{validateError.price}</span>
        )}
      </div>
      <div className="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="btnType1">Amount</span>
          </div>
          <input
            type="text"
            className="form-control text-right borderZero"
            name="quantity"
            value={quantity}
            onChange={handleChange}
          />
          <div class="input-group-append">
            <span class="btnType1">
              {tradePair && tradePair.firstCurrencySymbol}
            </span>
          </div>
        </div>
        {validateError && validateError.quantity && (
          <span style={{ color: "red" }}>{validateError.quantity}</span>
        )}
      </div>
      {/* <div className="form-group px-2">
                <Slider
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-custom"
                    step={10}
                    valueLabelDisplay="auto"
                    marks={marks}
                    defaultValue={0}
                    onChange={handlePercentage}
                    disabled={!isAuth}
                />
            </div> */}
      <div className="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="btnType1">Total</span>
          </div>
          <input
            type="text"
            class="form-control text-right borderZero"
            value={total}
          />
          <div class="input-group-append">
            <span class="btnType1">
              {tradePair && tradePair.secondCurrencySymbol}
            </span>
          </div>
        </div>
      </div>
      {isAuth && buyorsell == "buy" && (
        <div className="form-group">
          <button className="btn BuyNavButton btn-block" onClick={handleSubmit}>
            Buy {tradePair && tradePair.firstCurrencySymbol}
          </button>
        </div>
      )}

      {isAuth && buyorsell == "sell" && (
        <div className="form-group">
          <button
            className="btn SellNavButton btn-block"
            onClick={handleSubmit}
          >
            Sell {tradePair && tradePair.firstCurrencySymbol}
          </button>
        </div>
      )}
    </div>
  );
};

export default LimitOrder;
