// import package
import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import clsx from "classnames";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";

// import context
import SocketContext from "../Context/SocketContext";

// import action
import { getOrderBook, orderBookDetail } from "../../actions/spotTradeAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toFixed } from "../../lib/roundOf";

const OrderBook = () => {
  const socketContext = useContext(SocketContext);
  const [orderBookType, setOrderBookType] = useState("all");
  const [showTotal, setShowTotal] = useState(true);

  // state
  const [orderBook, setOrderBook] = useState({
    buyOrder: [],
    sellOrder: [],
  });

  const { buyOrder, sellOrder } = orderBook;

  // redux-state
  const tradePair = useSelector((state) => state.tradePair);
  const tickerData = useSelector((state) => state.marketPrice);
  const dispatch = useDispatch();

  // function
  const fetchOrderBook = async (pairId) => {
    try {
      const { status, loading, result } = await getOrderBook(pairId);
      if (status == "success") {
        setOrderBook(result);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (
      !isEmpty(tradePair) &&
      (tradePair.botstatus == "off" || tradePair.botstatus == "Off")
    ) {
      fetchOrderBook(tradePair.pairId);
    }

    // socket
    socketContext.socket.on("orderBook", (result) => {
      console.log(result.pairId == tradePair.pairId,'---------67')
      console.log(result.pairId,'---------68')
      console.log(tradePair.pairId,'---------69')
      if (result.pairId == tradePair.pairId) {
        setOrderBook({
          buyOrder: result.buyOrder,
          sellOrder: result.sellOrder,
        });
      }
    });
  }, [tradePair]);

  const handleOrderBook = (type) => {
    setOrderBookType(type);
  };

  let sellCount = 0,
    buyCount = 10;
  if (orderBookType == "sell") {
    buyCount = 0;
  } else if (orderBookType == "buy") {
    sellCount = 10;
  } else if (orderBookType == "all") {
    buyCount = 10;
    sellCount = 0;
  }

  return (
    <div className="tradeTableLeftSide darkBox orderBook">
      <div className="tableHead">
        <h4>Order Book</h4>
        <div className="inputGroup">
          {/* <button className="btn"><img src={require("../../assets/images/btn-green-dot.png")} alt="" className="img-fluid" /></button>
                    <button className="btn"><img src={require("../../assets/images/btn-pink-dot.png")} alt="" className="img-fluid" /></button>
                    <button className="btn"><img src={require("../../assets/images/btn-green-pink-dot.png")} alt="" className="img-fluid" /></button> */}
          <div className="inputGroup">
            <button
              className="btn ml-0"
              onClick={() => {
                handleOrderBook("buy");
              }}
            >
              <img
                src={require("../../assets/images/btn-green-dot.png")}
                alt=""
                className="img-fluid"
              />
            </button>
            <button
              className="btn ml-0"
              onClick={() => {
                handleOrderBook("sell");
              }}
            >
              <img
                src={require("../../assets/images/btn-pink-dot.png")}
                alt=""
                className="img-fluid"
              />
            </button>
            <button
              className="btn ml-0"
              onClick={() => {
                handleOrderBook("all");
              }}
            >
              <img
                src={require("../../assets/images/btn-green-pink-dot.png")}
                alt=""
                className="img-fluid"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="tradeTableTitle row mx-auto">
        <span className="col-4">
          Price({tradePair && tradePair.secondCurrencySymbol})
        </span>
        <span className="col-4">
          Amount({tradePair && tradePair.firstCurrencySymbol})
        </span>
        <span className="col-4">Total</span>
      </div>
      <Scrollbars style={{ width: "100%", height: 375 }}>
        <div className="tradeTableBody customScroll">
          {sellOrder &&
            sellOrder.length > 0 &&
            sellOrder.slice(sellCount, 10).map((item, key) => {
              return (
                <div
                  className={clsx(
                    "tradeTableBodyRow",
                    { odd: key % 2 == 1 },
                    { even: key % 2 == 0 },
                    "row mx-auto"
                  )}
                  data-placement="right"
                  data-html="true"
                >
                  {/* <span className="col-4 pinkText">{toFixed(item._id, tradePair.secondFloatDigit)}</span>
                                <span className="col-4">{toFixed(item.quantity, tradePair.firstFloatDigit)}</span>
                                <span className="col-4">{toFixed(item.total, tradePair.firstFloatDigit)}</span> */}
                  <span
                    className={clsx(
                      { "col-4": showTotal },
                      { "col-6": !showTotal },
                      "pinkText"
                    )}
                    onClick={() => {
                      orderBookDetail(dispatch, {
                        price: toFixed(item._id, tradePair.secondFloatDigit),
                      });
                    }}
                  >
                    {toFixed(item._id, tradePair.secondFloatDigit)}
                  </span>
                  <span
                    className={clsx(
                      { "col-4": showTotal },
                      { "col-6": !showTotal },
                      "text-right"
                    )}
                    onClick={() => {
                      orderBookDetail(dispatch, {
                        price: toFixed(item._id, tradePair.secondFloatDigit),
                      });
                    }}
                  >
                    {toFixed(item.quantity, tradePair.firstFloatDigit)}
                  </span>
                  {showTotal && (
                    <span className={"col-4 text-right"}>
                      {toFixed(item.total, tradePair.firstFloatDigit)}
                    </span>
                  )}
                </div>
              );
            })}

          <div
            className="tradeTableBodyRow even highLight row mx-auto"
            data-placement="right"
            data-html="true"
          >
            <span className="col-6 pinkText">
              <i className="fas fa-caret-down"></i> {tickerData.markPrice}
            </span>
          </div>

          {buyOrder &&
            buyOrder.length > 0 &&
            buyOrder.slice(0, buyCount).map((item, key) => {
              return (
                <div
                  className={clsx(
                    "tradeTableBodyRow",
                    { odd: key % 2 == 1 },
                    { even: key % 2 == 0 },
                    "row mx-auto"
                  )}
                  data-placement="right"
                  data-html="true"
                >
                  {/* <span className="col-4 greenText">{toFixed(item._id, tradePair.secondFloatDigit)}</span>
                                <span className="col-4">{toFixed(item.quantity, tradePair.firstFloatDigit)}</span>
                                <span className="col-4">{toFixed(item.total, tradePair.firstFloatDigit)}</span> */}
                  <span
                    className={clsx(
                      { "col-4": showTotal },
                      { "col-6": !showTotal },
                      "greenText"
                    )}
                    onClick={() => {
                      orderBookDetail(dispatch, {
                        price: toFixed(item._id, tradePair.secondFloatDigit),
                      });
                    }}
                  >
                    {toFixed(item._id, tradePair.secondFloatDigit)}
                  </span>
                  <span
                    className={clsx(
                      { "col-4": showTotal },
                      { "col-6": !showTotal },
                      "text-right"
                    )}
                    onClick={() => {
                      orderBookDetail(dispatch, {
                        price: toFixed(item._id, tradePair.secondFloatDigit),
                      });
                    }}
                  >
                    {toFixed(item.quantity, tradePair.firstFloatDigit)}
                  </span>
                  {showTotal && (
                    <span className={"col-4 text-right"}>
                      {toFixed(item.total, tradePair.firstFloatDigit)}
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      </Scrollbars>
    </div>
  );
};

export default OrderBook;
