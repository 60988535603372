// import package
import React, { useEffect } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

// import component
import ConditionRoute from "./components/Route/ConditionRoute";
import i18n from "./components/i18next/i18n";
import HelperRoute from "./components/Route/HelperRoute";
import Notification from "./pages/notificationlist";
import TradeNotification from "./pages/tradenotificationlist";
import ContactUsPage from "./pages/ContactUsPage";

// import Context
import SocketContext from "./components/Context/SocketContext";

// pages for this product
import Home from "pages/home.js";
import LoginPage from "./pages/LoginPage";
import Register from "pages/register.js";
import Forgot from "pages/forgot-password";
import EmailVerification from "./pages/EmailVerification";
import ProfilePage from "./pages/profile";
import SettingPage from "./pages/settings";
import Kyc from "./pages/kyc";
import Fee from "./pages/fee";
import DashboardPage from "./pages/DashboardPage";
import Wallet from "./pages/wallet";
import P2pwallet from "./pages/P2pwallet";
import Staking from "./pages/staking";
import Spot from "./pages/spot";
import Derivative from "./pages/derivative";

import History from "./pages/history";
import TradeHistory from "pages/tradeHistory";

import SupportTicket from "./pages/support-ticket";

import P2P from "./pages/peertopeer";
import PostMyAd from "./pages/postmyad";
import MyAds from "./components/MyAds";

import Chat from "./pages/p2pchat";
import about from "./pages/about";
import BlogDescription from "pages/blog-description";
import BlogCategory from "pages/blog-category";
import Blog from "pages/blog";
import Faq from "pages/faq";
import Cms from "pages/cms";

import ResetPasswordPage from "./pages/ResetPasswordPage";
import ChartAPP from "./components/SportTrade/Chart_App";

// import action
import { decodeJwt } from "./actions/jsonWebToken";
import { getBankDetail } from "./actions/users";

// import config
import { socket } from "./config/socketConnectivity";

// import lib
import store from "./store";
import isLogin from "./lib/isLogin";
import { getAuthToken } from "./lib/localStorage";

const App = () => {
  const { isAuth } = store.getState().auth;

  useEffect(() => {
    if (isAuth != true && isLogin()) {
      decodeJwt(getAuthToken(), store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename="/">
          <SocketContext.Provider value={{ socket }}>
            <ToastContainer />
            <HelperRoute />
            <Switch>
              <ConditionRoute
                exact
                path="/spot/:tikerRoot?"
                component={Spot}
                type={"public"}
              />
              <ConditionRoute exact path="/" component={Home} type={"public"} />
              <ConditionRoute
                exact
                path="/home"
                component={Home}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/Staking"
                component={Staking}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/history"
                component={History}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/trade-history"
                component={TradeHistory}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/support-ticket"
                component={SupportTicket}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/login"
                component={LoginPage}
                type={"auth"}
              />
              <ConditionRoute
                exact
                path="/reset-password/:authToken"
                component={ResetPasswordPage}
                type={"auth"}
              />
              <ConditionRoute
                exact
                path="/register"
                component={Register}
                type={"auth"}
              />
              <ConditionRoute
                exact
                path="/forgot-password"
                component={Forgot}
                type={"auth"}
              />

              <ConditionRoute
                exact
                path="/p2p"
                component={P2P}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/postmyad"
                component={PostMyAd}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/myads"
                component={MyAds}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/chat/:id"
                component={Chat}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/about"
                component={about}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/faq"
                component={Faq}
                type={"public"}
              />
              <ConditionRoute
                exact 
                path='/contact' 
                component={ContactUsPage} 
                type={"public"} 
              />
              <ConditionRoute
                exact
                path="/fees"
                component={Fee}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/terms"
                component={Cms}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/privacy"
                component={Cms}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/blog"
                component={Blog}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/blog-category/:slug"
                component={BlogCategory}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/blog-description/:slug"
                component={BlogDescription}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/notification"
                component={Notification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/tradenotification"
                component={TradeNotification}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/chartApp/:pairs"
                component={ChartAPP}
                type={"public"}
              />

              {/* PRIVATE */}

              <ConditionRoute
                exact
                path="/derivative/:tikerRoot?"
                component={Derivative}
                type={"public"}
              />

              {/* PRIVATE */}
              <ConditionRoute
                exact
                path="/profile"
                component={ProfilePage}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/settings"
                component={SettingPage}
                type={"private"}
              />

              <ConditionRoute
                exact
                path="/dashboard"
                component={DashboardPage}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/wallet"
                component={Wallet}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/P2pwallet"
                component={P2pwallet}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/kyc"
                component={Kyc}
                type={"private"}
              />

              {/* PUBLIC */}
              <ConditionRoute
                exact
                path="/email-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/withdraw-fiat-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/withdraw-coin-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              {/* PUBLIC */}

              {/* <Route exact path="/*" component={Home}>
                    <ToastContainer />
                    <Switch>
                        <ConditionRoute exact path='/derivative' component={Derivative} type={"public"} />
                        <ConditionRoute exact path='/spot' component={Spot} type={"public"} />
                        <ConditionRoute exact path='/' component={Home} type={"public"} />
                        <ConditionRoute exact path='/home' component={Home} type={"public"} />
                        <ConditionRoute exact path='/Staking' component={Staking} type={"public"} />
                        <ConditionRoute exact path='/history' component={History} type={"public"} />
                        <ConditionRoute exact path='/support-ticket' component={SupportTicket} type={"public"} />
                        <ConditionRoute exact path='/login' component={LoginPage} type={"auth"} />
                        <ConditionRoute exact path='/register' component={Register} type={"auth"} />
                        <ConditionRoute exact path='/forgot-password' component={ForgotPassword} type={"auth"} />

                        {/* PRIVATE */}

              {/* PRIVATE */}
              <ConditionRoute
                exact
                path="/profile"
                component={ProfilePage}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/settings"
                component={SettingPage}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/dashboard"
                component={DashboardPage}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/wallet"
                component={Wallet}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/kyc"
                component={Kyc}
                type={"public"}
              />

              {/* PUBLIC */}
              <ConditionRoute
                exact
                path="/email-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/withdraw-fiat-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/withdraw-coin-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              {/* PUBLIC */}

              {/* <Route exact path="/*" component={Home}>
                    <Redirect to="/home" />
                </Route> */}
            </Switch>
          </SocketContext.Provider>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  );
};

export default App;
